<template>
  <div class="wrapper">
    <div class="upload-area">
      <div class="confirm-latest-issue">
        <p>Do you want to uplaod latest issue?</p>
        <div class="cancelBtn">
          <button @click="whetherlatestIssue" v-if="isLatestIssueToUpload">
            Cancel
          </button>
        </div>
        <div class="confirm-btns">
          <button @click="whetherlatestIssue">
            {{ isLatestIssueToUpload ? "cancel" : "yes" }}
          </button>
          <!-- <button>No</button> -->
        </div>
      </div>

      <div class="issue-name" v-if="changeLastLatestIssueLabel">
        <label>Previous Issue Label</label>
        <input
          type="text"
          v-model="prevLatestName"
          id="issueLabel"
          placeholder="Required"
        />
      </div>
      <div class="issue-name">
        <label>Issue Name</label>
        <input type="text" v-model="issueName" id="issueLabel" />
      </div>
      <div class="cover-photo">
        <label>Cover Photo</label>
        <input
          type="file"
          id="file-uploader"
          accept="image/gif, image/jpeg, image/png"
          @click="getFileURL('file-uploader', true)"
        />
        <div class="selected-image" v-if="selectedImage.length !== 0">
          <div v-for="(v, i) in selectedImage" :key="i" class="uploadedImg">
            <img :src="v" />
          </div>
        </div>
      </div>

      <div class="upload-pdf">
        <label>Uplaod PDF</label>
        <input
          type="file"
          id="pdf-loader"
          accept="application/pdf"
          @click="getFileURL('pdf-loader', false)"
        />
        <div class="selected-pdf" v-if="selectedPDF.length !== 0">
          <div v-for="(v, i) in selectedPDF" :key="i">
            <iframe :src="v"></iframe>
          </div>
        </div>
      </div>
      <div class="upload-btn">
        <button @click="upload(changeLastLatestIssueLabel)">
          Initiate Uplaod
        </button>
      </div>
    </div>
  </div>
  <!-- //need to use the suspense feature of vue  -->
  <div class="loading" v-if="isUploading">
    <p>Please wait....</p>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useToast } from "vue-toastification";
import { uploadFiles } from "@/stores/file-upload";
import { allUploadedFilesStorageRefCollection } from "@/stores/uploaded-files-storageRef-collection";
import { unncessaryFileUrlCollection } from "@/stores/unnecessary-file-upload-tracker";
import { sampritiIssueStore } from "@/stores/upload-new-issue.js";
import { onMounted } from "@vue/runtime-core";

export default {
  name: "upload-new-issue",

  setup() {
    //v-model for previous issue label
    const prevLatestName = ref("");

    //fiileStorageRefCollection instance
    const flstrgRefCollection = allUploadedFilesStorageRefCollection();

    //unnecessarily uploaded file tracker store
    const unnecessaryUpload = unncessaryFileUrlCollection();

    //is uploading
    const isUploading = ref(false);

    //file uplaod instace
    const uploadFilesToFirebase = uploadFiles();

    //store instance
    const issueStore = sampritiIssueStore();

    //toast instance
    const toast = useToast();

    //issue name
    const issueName = ref("");

    //selected image of the issue
    const selectedImage = ref([]);
    //selected pdf of the issue
    const selectedPDF = ref([]);

    function upload(isPrevlatestExist) {
      if (isPrevlatestExist) {
        if (prevLatestName.value.length !== 0) {
          isUploading.value = true;
          issueStore.renameExistingIssue(prevLatestName.value).then(() => {
            uploadNewIssue();
          });
          prevLatestName.value = "";
        } else {
          toast.warning("Previous issue label required!");
          return;
        }
      } else {
        uploadNewIssue();
      }
    }

    //uploadNewIssue
    function uploadNewIssue() {
      if (
        issueName.value.length === 0 ||
        selectedImage.value.length === 0 ||
        selectedPDF.value.length === 0
      ) {
        toast.warning("Fillup all fields before uploading");
        return;
      }

      isUploading.value = true;
      var item = {
        title: issueName.value,
        issueImage: selectedImage.value,
        issuePDF: selectedPDF.value,
      };
      issueStore.createNewIssue(item).then(() => {
        isUploading.value = false;
      });
      selectedImage.value = "";
      issueName.value = "";
      selectedPDF.value = "";
      isLatestIssueToUpload.value = false;
      unnecessaryUpload.resetCollection();
    }

    //get the selected image url for preview
    function getFileURL(inputId, isImage) {
      const uploadBtn = document.getElementById(inputId);
      uploadBtn.addEventListener("change", function () {
        if (uploadBtn.value) {
          const files = uploadBtn.files;
          isUploading.value = true;
          if (isImage) {
            selectedImage.value = [];
          } else {
            selectedPDF.value = [];
          }

          for (let index = 0; index < files.length; index++) {
            if (files[index].type.match("image")) {
              uploadFilesToFirebase.uploadSingleImageAndGetDownloadUrl(
                files[index]
              );
              selectedImage.value = uploadFilesToFirebase.uploadedSingleImage;
            }

            if (files[index].type.match("application/pdf")) {
              uploadFilesToFirebase.uploadPdfAndGetDownloadUrl(files[index]);
              selectedPDF.value = uploadFilesToFirebase.uploadedPdf;
            }

            setTimeout(() => {
              isUploading.value = false;

              if (isImage) {
                uploadFilesToFirebase.uploadedSingleImage;
                unnecessaryUpload.addNewFileUrl(
                  uploadFilesToFirebase.uploadedSingleImage[0]
                );
              } else {
                uploadFilesToFirebase.uploadedPdf;
                unnecessaryUpload.addNewFileUrl(
                  uploadFilesToFirebase.uploadedPdf[0]
                );
              }
            }, 9000);

            continue;
          }
        }
      });
    }

    //whether to upload latest issue or not
    const isLatestIssueToUpload = ref(false);

    //need to chnage the label of the last issue
    const changeLastLatestIssueLabel = ref(false);

    function whetherlatestIssue() {
      //first check whether latest issue alraedy exists,if exists we need to update its label to the previous volume label

      isLatestIssueToUpload.value = !isLatestIssueToUpload.value;

      if (issueStore.latestIssue.length > 0 && isLatestIssueToUpload.value) {
        changeLastLatestIssueLabel.value = true;
      } else {
        changeLastLatestIssueLabel.value = !true;
      }

      var getInputField = document.getElementById("issueLabel");

      if (isLatestIssueToUpload.value) {
        issueName.value = "Sampriti Latest Issue";
        getInputField.readOnly = true;
      } else {
        issueName.value = "";
        getInputField.readOnly = false;
      }
    }

    onMounted(() => {
      unnecessaryUpload.fileUrlCollection.forEach((v) => {
        if (v !== null) {
          var strf = flstrgRefCollection.findFileStorageRef(v);
          if (strf !== null) {
            flstrgRefCollection.deleteFileFromFirebase(strf).then(() => {
              unnecessaryUpload.deleteUrl(v);
            });
          }
        }
      });
    });

    return {
      isLatestIssueToUpload,
      selectedImage,
      uploadNewIssue,
      getFileURL,
      selectedPDF,
      issueName,
      isUploading,
      whetherlatestIssue,
      changeLastLatestIssueLabel,
      prevLatestName,
      upload,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin makeFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@mixin labelStyle {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.1px;
  padding-bottom: 15px;
  text-align: start;
}

.wrapper {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(#4671ea, #ac34e7);
  padding: 30px 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  .upload-area {
    height: 85%;
    width: 41%;
    margin: 30px auto;

    @media (max-width: 678px) {
      width: 95%;
    }

    .confirm-latest-issue {
      // background: red;
      p {
        font-size: 15px;
        font-weight: 600;
      }
      .cancelBtn {
        text-align: right;
        button {
          border: none;
          outline: none;
          font-size: 11px;
          padding: 1px 25px;
          border-radius: 6px;
          background: red;
          &:hover {
            font-size: 11.1px;
            color: white;
          }
        }
      }
      .confirm-btns {
        // text-align: center;
        margin: 20px auto;

        button {
          padding: 1px 25px;
          border: none;
          outline: none;
          font-size: 12px;
          margin: auto 20px;
        }
      }
    }

    // background-color: yellow;
    .issue-name {
      height: 100px;
      width: 100%;
      @include makeFlex();

      label {
        @include labelStyle();
      }

      input {
        width: 200px;
        height: 30px;
        border-radius: 15px;
        border: none;
        outline: none;
        padding: 2px 9px;
        font-size: 12px;
      }
    }

    .cover-photo {
      @include makeFlex();

      label {
        @include labelStyle();
      }

      input {
        font-size: 13px;
        font-weight: 600;
      }

      .selected-image {
        height: 200px;
        width: 95%;
        margin: 25px 0px;

        .uploadedImg {
          img {
            max-height: 200px;
            height: auto;
            max-width: 70%;
            width: auto;
            margin: 4px auto;
          }
        }
      }
    }

    .upload-pdf {
      @include makeFlex();
      margin-top: 15px;

      label {
        @include labelStyle();
      }

      input {
        font-size: 13px;
        font-weight: 600;
      }

      .selected-pdf {
        width: auto;
        max-height: 400px;
        height: auto;
        margin: 25px 0px;

        iframe {
          height: 100%;
          width: 100%;
          border-radius: 15px;
          padding: 5px;
        }
      }
    }

    .upload-btn {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        height: 26px;
        width: auto;
        color: black;
        font-size: 11px;
        padding: 3px 20px;
        border-radius: 15px;
        margin-top: 25px;
        font-weight: 600;
        border: none;
        outline: none;
      }
    }
  }
}
.loading {
  position: fixed;
  inset: 0;
  background: rgba($color: #000000, $alpha: 0.8);
  z-index: 100000;

  display: grid;
  place-content: center;

  p {
    color: white;
    font-size: 15px;
  }
}
</style>
