<template>
  <div v-if="listOfExtractedCarrItemObject.length != 0" class="wrapper">
    <h6><b>My Shopping Cart</b></h6>

    <div class="no-of-items">
      <p>
        <b> No of items selected: </b>
        <span>{{ shoppingCart.totalCartQuantity }}</span>
      </p>
    </div>

    <shoppingCartitem
      v-for="(v, i) in listOfExtractedCarrItemObject"
      :key="i"
      :cartItem="v"
      @removeItem="removeItem(v.id)"
    ></shoppingCartitem>

    <div class="total-cart-price">
      <h5><b>Total Order Price:</b></h5>
      <!-- <p v-if="shoppingCart.overallCartPrice.length===0">0 <i class="fa-solid fa-indian-rupee-sign fa-sm"></i></p> -->
      <p>
        {{ Number(shoppingCart.overallCartPrice).toFixed(2) }}
        <i class="fa-solid fa-indian-rupee-sign fa-sm"></i>
      </p>
    </div>
    <div class="all-other-details">
      <div class="add-more">
        <button @click="goToPage('buy-books')">Want to add more?</button>
      </div>
      <div class="action-btns">
        <button id="action" @click="addAddress = true">
          Select Delivery Address
        </button>
      </div>
    </div>
  </div>

  <div v-else class="emptyCart">
    <p>You cart is empty</p>
  </div>

  <!-- add address and payment modal -->
  <div id="cmodal" v-if="addAddress" @click="addAddress = true">
    <div class="cancel">
      <button @click.stop="addAddress = false">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
    <div class="content-box">
      <div class="button-area">
        <button @click="openRequiredArea(true)">
          Add a new Address <span><i class="fa-solid fa-plus"></i></span>
        </button>
        <button @click="openRequiredArea(false)">
          Select an existing Address
          <span><i class="fa-solid fa-hand-pointer"></i></span>
        </button>
      </div>
    </div>
  </div>

  <!-- action block -->
  <div class="action-block" v-if="addNewAddress || selectSavedAddress">
    <div class="addnewAddress" v-if="addNewAddress">
      <div class="addressDetails">
        <div class="cancelbtn">
          <button @click="addNewAddress = false">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="name">
          <label>Customer Name:</label>
          <input type="text" placeholder="Enter name" v-model="name" />
        </div>
        <p class="error" v-if="nameError">{{ nameError }}</p>
        <div class="address1">
          <label>address 1:</label>
          <input type="text" v-model="address1" />
        </div>
        <p class="error" v-if="address1Error">{{ address1Error }}</p>

        <div class="address2">
          <label>address 2:</label>
          <input type="text" v-model="address2" />
        </div>
        <p class="error" v-if="address2Error">{{ address2Error }}</p>

        <div class="pin">
          <label>PIN:</label>
          <input
            type="text"
            placeholder="Enter a valid PIN"
            v-model="pin"
            @blur="validatePincode(pin)"
            @keyup="onlyNumberAllowd('pin')"
            maxlength="6"
          />
        </div>
        <p class="error" v-if="pinError">{{ pinError }}</p>

        <div class="customer-city">
          <label>City:</label>
          <input type="text" v-model="customerCity" placeholder="Enter city" />
        </div>
        <p class="error" v-if="cityError">{{ cityError }}</p>

        <div class="post-office">
          <label>Post Office:</label>
          <input
            type="text"
            placeholder="Enter post office"
            v-model="postOffice"
            @blur="validatePostOffice"
          />
        </div>
        <p class="error" v-if="postofficeError">{{ postofficeError }}</p>

        <div class="district">
          <label>District:</label>
          <input type="text" placeholder="Enter district" v-model="district" />
        </div>
        <p class="error" v-if="districtError">{{ districtError }}</p>

        <div class="state">
          <label>State:</label>
          <input type="text" placeholder="State" v-model="state" />
        </div>
        <p class="error" v-if="stateError">{{ stateError }}</p>

        <div class="country">
          <label>Country:</label>
          <input type="text" placeholder="Country" v-model="country" />
        </div>
        <p class="error" v-if="countryError">{{ countryError }}</p>
        <div class="phone">
          <label>Phone No:</label>
          <input
            type="text"
            placeholder="Enter a valid Phone No"
            v-model="phoneNo"
            @keyup="onlyNumberAllowd('phoneNo')"
            maxlength="10"
          />
        </div>
        <p class="error" v-if="phoneNoError">{{ phoneNoError }}</p>

        <div class="action">
          <button @click="saveAddress()">Save address</button>
          <button @click="reset">Reset</button>
        </div>
      </div>
    </div>
    <div class="selectExistingAddress" v-if="selectSavedAddress">
      <div class="cancelbtn">
        <button @click="selectSavedAddress = !selectSavedAddress">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>

      <h6 v-if="hasAddress"><b>Select a delivery address</b></h6>
      <p v-else><b>No saved addresses found!</b></p>
      <addressVue
        v-for="(v, i) in storeAddressCollection"
        :address="v"
        :key="i"
        @updateAfterEdit="updateTheEditedAddress"
      ></addressVue>
    </div>
  </div>

  <!-- //need to use the suspense feature of vue  -->
  <div class="loading" v-if="isValidating">
    <p>Please Wait....</p>
  </div>
</template>

<script>
import shoppingCartitem from "@/components/shopping-cart-item.vue";

//importing the address store
import { userAddressCollection } from "@/stores/address-store.js";

//import the shopping cart store
import { shoppingCartStore } from "@/stores/shopping-cart-store";

// import the uploadedbooks store

import { uploadBooks } from "@/stores/upload-book";

import { reactive, ref } from "@vue/reactivity";

//component
import addressVue from "@/components/address-object.vue";
import { onMounted } from "@vue/runtime-core";
import router from "@/router";

//axios import
const axios = require("axios").default;

export default {
  name: "my-shopping-cart",
  components: { shoppingCartitem, addressVue },
  setup() {
    //address store instance
    const addressCollectionStore = userAddressCollection();

    //address collection derived from the store
    const storeAddressCollection = ref([]);

    onMounted(() => {
      //extract all addresses from firebase
      addressCollectionStore.getAllAddressFromFirebase().then(() => {
        //address collection derived from the store
        storeAddressCollection.value = addressCollectionStore.addressCollection;
        // console.log(storeAddressCollection.value);
      });
    });

    function updateTheEditedAddress() {
      addressCollectionStore.getAllAddressFromFirebase().then(() => {
        storeAddressCollection.value = addressCollectionStore.addressCollection;
      });
    }

    //shoppingCart store instance
    const shoppingCart = shoppingCartStore();

    //uploaded book collection store
    const uploadBooksCollection = uploadBooks();

    //List carring all cartItem's full details
    const listOfExtractedCarrItemObject = ref([]);

    //get the collectedId and then retrieve the item details to display in the cart
    onMounted(() => {
      // console.log(shoppingCart.itemIdCollection);

      // shoppingCart.$reset();
      isValidating.value = true;
      var idCollection;
      var book;
      var quantity = 0;
      var cartPrice = 0;
      shoppingCart.extractItemIdCollectionFromFirebase().then(() => {
        // console.log(shoppingCart.itemIdCollection);
        if (shoppingCart.itemIdCollection.length === 0) {
          return;
        } else {
          idCollection = shoppingCart.itemIdCollection;
          idCollection.forEach(async (id) => {
            book = await uploadBooksCollection.getBookFromId(id);
            // console.log(book);
            if (book === null) {
              return;
            } else {
              // console.log(book[0]);
              quantity += 1;
              cartPrice += Number(book[0].currentPrice);

              listOfExtractedCarrItemObject.value.push(book[0]);
              if (shoppingCart.cartItems.length !== idCollection.length) {
                shoppingCart.createIndividualCartItem(
                  book[0].id,
                  1,
                  book[0].currentPrice,
                  book[0].bookName
                );
              }
            }
            shoppingCart.setOverallCartPrice(cartPrice);
            shoppingCart.setTotalCartQuantity(quantity);
          });
          isValidating.value = false;
        }
      });

      if (isValidating.value) {
        setTimeout(() => {
          isValidating.value = false;
        }, 7000);
      }
    });

    //whether user has saved adddresses or not
    const hasAddress = ref(!false);

    const addAddress = ref(false);

    //add new address
    const addNewAddress = ref(false);
    //select from saved addresses
    const selectSavedAddress = ref(false);

    //function to open add new address area
    // or select from existing area
    function openRequiredArea(isNewAddress) {
      if (isNewAddress) {
        addNewAddress.value = true;
        if (selectSavedAddress.value) {
          selectSavedAddress.value = false;
        }
      } else {
        selectSavedAddress.value = true;
        addressCollectionStore.getAllAddressFromFirebase().then(() => {
          //address collection derived from the store
          storeAddressCollection.value =
            addressCollectionStore.addressCollection;
        });

        if (addNewAddress.value) {
          addNewAddress.value = false;
        }
      }
    }

    //array to hold all the post offices name associated with the pincode
    const postOffices = ref([]);

    //v-model variables for the fields
    const name = ref("");
    const address1 = ref("");
    const address2 = ref("");
    const customerCity = ref("");
    const postOffice = ref("");
    const pin = ref("");
    const district = ref("");
    const state = ref("");
    const country = ref("");
    const phoneNo = ref("");

    //errors
    const nameError = ref("");
    const address1Error = ref("");
    const address2Error = ref("");
    const cityError = ref("");
    const postofficeError = ref("");
    const pinError = ref("");
    const districtError = ref("");
    const stateError = ref("");
    const countryError = ref("");
    const phoneNoError = ref("");

    //variable to keep track of the validation status
    const isValidating = ref(false);

    //this object is going to be used for temporary storage of address values
    const addressObject = reactive({
      pin: "",
      city: "",
      postoffices: [],
      district: "",
      state: "",
      country: "",
    });

    const finalAddressObject = reactive({
      name: "",
      address1: "",
      address2: "",
      city: "",
      postoffice: "",
      district: "",
      pin: "",
      state: "",
      country: "",
      phoneNo: "",
    });

    //function to validate the pin code
    const validatePincode = (value) => {
      try {
        if (value.length == 6) {
          pinError.value = "";
          isValidating.value = true;

          axios
            .get("https://api.postalpincode.in/pincode/" + value)
            .then((result) => {
              resetAllError();
              if (result.data[0].PostOffice === null) {
                pinError.value = "Invalid pincode!";
                isValidating.value = false;
                return;
              }
              isValidating.value = true;
              addressObject.pin = value;
              //setting the postOffice list and citylist clean for each api call
              postOffices.value = [];

              result.data[0].PostOffice.forEach((element) => {
                postOffices.value.push(element.Name.toLowerCase());

                if (
                  customerCity.value !== element.Block &&
                  element.Block !== "NA"
                ) {
                  customerCity.value = element.Block;
                  addressObject.city = element.Block;

                  cityError.value = "";
                }
                if (
                  district.value !== element.District &&
                  element.District !== "NA"
                ) {
                  district.value = element.District;
                  addressObject.district = element.District;

                  districtError.value = "";
                }
                if (
                  country.value !== element.Country &&
                  element.Country !== "NA"
                ) {
                  country.value = element.Country;
                  addressObject.country = element.Country;

                  countryError.value = "";
                }
                if (state.value !== element.State && element.State !== "NA") {
                  state.value = element.State;
                  addressObject.state = element.State;

                  stateError.value = "";
                }
              });

              addressObject.postoffices = postOffices.value;

              if (postOffice.value) {
                if (
                  postOffices.value.includes(postOffice.value.toLowerCase())
                ) {
                  postofficeError.value = "";
                  return;
                }
              } else {
                postOffice.value = postOffices.value[0];
              }

              // console.log(postOffices.value);
              pinError.value = "";
              isValidating.value = false;
            });
        } else {
          isValidating.value = false;

          pinError.value = "pincode length must be 6";
        }
      } catch (error) {
        isValidating.value = false;
        pinError.value = "Cant validate pincode!";
      }
    };

    //validate entered postoffice value
    function validatePostOffice() {
      if (postOffice.value) {
        if (postOffices.value.includes(postOffice.value.toLowerCase())) {
          postofficeError.value = "";
          return;
        }
        postofficeError.value = "Invalid post office";
      }
    }

    //function to upload the address
    function saveAddress() {
      if (!customerCity.value) {
        cityError.value = "City cant be empty";
        return;
      }

      if (addressObject.district) {
        if (
          addressObject.district.toLowerCase() !== district.value.toLowerCase()
        ) {
          districtError.value = "District did not match with PIN";
          return;
        }
      }

      if (addressObject.state) {
        if (addressObject.state.toLowerCase() !== state.value.toLowerCase()) {
          stateError.value = "Invalid state";
          return;
        }
      }
      if (addressObject.country) {
        if (
          addressObject.country.toLowerCase() !== country.value.toLowerCase()
        ) {
          countryError.value = "Invalid country";
          return;
        }
      }
      if (!addressObject.postoffices.includes(postOffice.value.toLowerCase())) {
        postofficeError.value = "Invalid post office";
        return;
      }

      if (!name.value) {
        nameError.value = "Cant be empty";
        return;
      }
      if (!address1.value) {
        address1Error.value = "Cant be empty";
        return;
      }
      if (!address2.value) {
        address2Error.value = "Cant be empty";
        return;
      }
      if (!phoneNo.value) {
        phoneNoError.value = "Phone Number cant be empty!";

        if (!phoneNo.value.length === 10) {
          phoneNoError.value = "Enter a valid Phone Number!";
        }
        return;
      }

      resetAllError();

      finalAddressObject.name = name.value;
      finalAddressObject.address1 = address1.value;
      finalAddressObject.address2 = address2.value;
      finalAddressObject.pin = pin.value;
      finalAddressObject.postoffice = postOffice.value;
      finalAddressObject.city = customerCity.value;
      finalAddressObject.district = district.value;
      finalAddressObject.state = state.value;
      finalAddressObject.country = country.value;
      finalAddressObject.phoneNo = phoneNo.value;

      addressCollectionStore.createAddress(finalAddressObject).then(() => {
        reset();
      });
    }

    function resetStore() {
      addressCollectionStore.$reset();
      console.log("done");
    }

    //function to reset all error
    function resetAllError() {
      nameError.value = "";
      address1Error.value = "";
      address2Error.value = "";
      cityError.value = "";
      postofficeError.value = "";
      pinError.value = "";
      districtError.value = "";
      stateError.value = "";
      countryError.value = "";
      phoneNoError.value = "";
    }

    //function to reset all

    function reset() {
      resetAllError();
      name.value = "";
      address1.value = "";
      address2.value = "";
      customerCity.value = "";
      postOffice.value = "";
      pin.value = "";
      district.value = "";
      state.value = "";
      country.value = "";
      phoneNo.value = "";
    }

    //restricting certain input fields to accept only numbers
    function onlyNumberAllowd(input) {
      var restrictRegex = /[^0-9]/gi;
      this[input] = this[input].replace(restrictRegex, "");
    }

    //function to navigate to a particular
    // page when a button or any link is clicked

    function goToPage(name) {
      router.push({
        name: name,
      });
    }

    //removing an item from the cartItems
    function removeItem(uid) {
      var filtered = listOfExtractedCarrItemObject.value.filter(
        (item) => item.id !== uid
      );
      listOfExtractedCarrItemObject.value = filtered;
      // shoppingCart.removeFromIdCollection(uid);
      shoppingCart.removeCartItem(uid);
    }

    return {
      phoneNo,
      phoneNoError,
      updateTheEditedAddress,
      removeItem,
      goToPage,
      resetStore,
      onlyNumberAllowd,
      reset,
      addAddress,
      saveAddress,
      addNewAddress,
      selectSavedAddress,
      validatePostOffice,
      openRequiredArea,
      nameError,
      address1Error,
      address2Error,
      postofficeError,
      pinError,
      districtError,
      stateError,
      countryError,
      name,
      address1,
      address2,
      postOffice,
      pin,
      district,
      state,
      country,
      cityError,
      validatePincode,
      customerCity,
      isValidating,
      hasAddress,
      storeAddressCollection,
      listOfExtractedCarrItemObject,
      shoppingCart,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin smoothenLook {
  border: none;
  outline: none;
}

.emptyCart {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-image: linear-gradient(
    to right top,
    #051937,
    #004d7a,
    #008793,
    #00bf72,
    #a8eb12
  );
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    // background-color: red;
    font-size: 15px;
    color: black;
  }
}

.wrapper {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-image: linear-gradient(
    to right top,
    #051937,
    #004d7a,
    #008793,
    #00bf72,
    #a8eb12
  );

  padding: 25px;

  @media (max-width: 576px) {
    padding: 5px;
  }

  h6 {
    text-align: center;
    color: black;
    font-size: 17px;
    text-shadow: 0 -1px 4px #fff, 0 -2px 10px #ff0, 0 -10px 20px #ff8000,
      0 -18px 40px #f00;

    @media (max-width: 576px) {
      font-size: 15px;
      margin: 10px auto;
    }
  }
  .no-of-items {
    margin: 25px auto;
    p {
      font-size: 13px;
      font-weight: 500;
      margin-left: 10%;
      // text-decoration: underline;
      span {
        color: black;
        margin: auto 10px;
        font-weight: 700;
      }
    }
  }
  .total-cart-price {
    margin: 40px auto;
    display: flex;
    justify-content: center;
    height: 40px;
    h5 {
      font-size: 14px;
      color: yellow;
      align-items: baseline;
      padding-right: 10px;
      margin-top: 10px;
      text-decoration: underline;
    }
    p {
      font-size: 13px;

      color: yellow;
      padding-bottom: 10px;
      margin-top: 6px;
      font-weight: 600;
    }
  }
  .all-other-details {
    .add-more,
    .action-btns {
      // background: yellow;
      margin: 60px auto;
      display: grid;
      place-content: center;
      button {
        font-size: 11.4px;
        border: 1px solid grey;
        outline: none;
        font-weight: 600;
        padding: 3px 20px;
        border-radius: 5px;
        color: white;
        background: transparent;

        &:hover {
          font-size: 11.3px;
          transform: skewY(-2deg);
          box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
            rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
            rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
        }
      }
    }
    #action {
      background: yellow;
      color: black;
      border: none;
    }
  }
}
//modal to enter address and for payments
#cmodal {
  position: fixed;
  inset: 0;
  height: 99%;
  width: 99%;
  background-color: rgba($color: #000000, $alpha: 0.9);
  margin: 5px auto;

  .cancel {
    text-align: end;
    button {
      @include smoothenLook();
      background: transparent;
      margin: 20px;
      color: red;
    }
  }

  .content-box {
    position: relative;
    height: 95%;
    width: 100%;
    display: grid;
    place-content: center;

    .button-area {
      height: 400px;
      width: 70vw;
      display: grid;
      background: #051937;
      place-content: center;
      border-radius: 10px;

      @media (max-width: 576px) {
        // background: red;
        height: 100vh;
        width: 95vw;
      }
      button {
        @include smoothenLook();
        margin: 20px auto;
        width: 240px;
        font-size: 11px;
        padding: 5px;
        font-weight: 500;
        border-radius: 5px;
        background: black;
        color: whitesmoke;

        span {
          padding: 5px;
          font-size: smaller;
          color: #ff0;
        }
      }
    }
  }
}

.action-block {
  background: rgba($color: #000000, $alpha: 0.7);
  height: 100vh;
  width: 100%;
  position: fixed;
  inset: 0;
  z-index: 20;
  .addnewAddress {
    height: 100%;
    width: 96%;
    background: #064047;
    margin: 10px auto;
    padding: 10px;
    overflow: scroll;

    .addressDetails {
      height: auto;
      padding: 50px auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      overflow-y: scroll;

      .error {
        color: #ed152f;
        font-size: 13px;
        font-weight: 500;
      }

      .cancelbtn {
        width: 100%;
        text-align: end;
        button {
          @include smoothenLook();
          background: transparent;
          color: red;
          transition: all 250ms ease-in 100ms;
          &:hover {
            transform: translateY(-2px) scale(1.5);
          }
        }
      }

      .name,
      .address1,
      .customer-city,
      .address2,
      .post-office,
      .pin,
      .phone,
      .district,
      .state,
      .country {
        @media (max-width: 576px) {
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        width: 48%;
        margin: 15px auto;
        label {
          font-size: 13px;
          font-weight: 500;
          color: white;
          letter-spacing: 0.4px;
        }
        input {
          @include smoothenLook();
          border-radius: 5px;
          // margin: auto 10px;
          float: right;
          text-align: center;
          font-size: 11px;
          width: 70%;
          padding: 5px;
          @media (max-width: 576px) {
            width: 100%;
          }
        }
      }

      .action {
        height: 150px;
        width: 100%;
        margin: 20px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          font-size: 11px;
          font-weight: 500;
          padding: 3px 23px;
          margin: 0px 15px;
          @include smoothenLook();
          border-radius: 10px;
          transition: all 300ms ease-in;
          &:hover {
            background: yellow;
            font-size: 11.2px;
          }
        }
      }
    }
  }
  .selectExistingAddress {
    background-image: linear-gradient(
      to right top,
      #051937,
      #004d7a,
      #008793,
      #00bf72,
      #a8eb12
    );
    height: 96%;
    width: 96%;
    margin: 10px auto;
    padding: 40px;
    overflow: scroll;

    @media (max-width: 576px) {
      padding: 5px;
    }

    .cancelbtn {
      text-align: end;
      button {
        outline: none;
        border: none;
        font-size: 16px;
        background-color: transparent;
        color: red;
      }
    }

    h6 {
      font-size: 16px;
      color: white;
      text-align: center;
      letter-spacing: 0.5px;
      text-decoration: underline;
      margin: 30px auto;
    }
    p {
      color: white;
      font-size: 16px;
      letter-spacing: 0.4px;
      margin: auto;
      text-align: center;
      text-decoration: underline;

      margin-top: 20%;
    }
  }
}

.loading {
  position: fixed;
  inset: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 1000;

  display: grid;
  place-content: center;

  p {
    color: white;
    font-size: 15px;
  }
}
</style>