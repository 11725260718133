<template>
  <div class="wrapper">
    <div class="details-container">
      <div class="category">
        <p>Category: <b>Social Studies & Humanities</b></p>
      </div>

      <div class="details">
        <div class="imagenbtns">
          <div class="image">
            <img :src="book.imageUrl" alt="" />
          </div>
          <div class="btns">
            <button id="buy" @click="shoppingCart.collectItemIds(book.id)">
              Buy Now
            </button>
            <button id="cart" @click="orderCollection.createWishList(book)">
              Add to Wishlist
            </button>
          </div>
        </div>
        <div class="all-informations">
          <h5>
            <b>{{ book.bookName }}</b>
          </h5>

          <h6>
            Author: <b>{{ book.authorName }}</b>
          </h6>

          <div class="current-price">
            <p>Best Price:</p>
            <p id="money">
              <b
                >{{ book.currentPrice }}
                <i class="fa-solid fa-indian-rupee-sign fa-xs"></i
              ></b>
            </p>
          </div>

          <div class="mrp">
            <p>
              MRP:
              <b
                >{{ book.mrp }}
                <i class="fa-solid fa-indian-rupee-sign fa-xs"></i
              ></b>
            </p>
            <p class="discount">{{ book.discount }}% off</p>
          </div>

          <div class="description">
            <p>
              {{ book.bookDescription }}
            </p>
          </div>

          <div class="highlights">
            <ul>
              <li>
                <p>
                  <b>Publication Year:</b> <span>{{ book.pubYear }}</span>
                </p>
              </li>
              <li>
                <p><b>Language:</b></p>
                <span v-for="(v, i) in book.languageList" :key="i">{{
                  v
                }}</span>
              </li>
              <li>
                <p>
                  <b>Binding:</b> <span>{{ book.bindingType }}</span>
                </p>
              </li>
              <li>
                <p>
                  <b>Publisher:</b> <span>{{ book.publisher }}</span>
                </p>
              </li>
              <li>
                <p>
                  <b>Genre:</b> <span>{{ book.genre }}</span>
                </p>
              </li>
              <li>
                <p>
                  <b>ISBN:</b> <span>{{ book.isbn }}</span>
                </p>
              </li>
              <li>
                <p>
                  <b>Pages:</b><span>{{ book.noOfPages }}</span>
                </p>
              </li>
              <li>
                <p>
                  <b>Seller:</b><span>{{ book.sellerName }}</span>
                </p>
              </li>
            </ul>
          </div>

          <div class="author-info">
            <h5><b>Author Information</b></h5>

            <div class="about-author">
              {{ book.authorDetails }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { uploadBooks } from "@/stores/upload-book";
import { onMounted } from "@vue/runtime-core";
import { shoppingCartStore } from "@/stores/shopping-cart-store";
import { bookOrderCollection } from "@/stores/bookorder-collection";

export default {
  name: "book-view",

  setup() {
    const book = ref({});

    //shopping cart store instance
    const shoppingCart = shoppingCartStore();

    //order collection instance
    const orderCollection = bookOrderCollection();

    //bookstore instance
    const books = uploadBooks();

    onMounted(async () => {
      const getSelectedId = books.selectedId;
      var val = await books.getBookFromId(getSelectedId);

      if (val !== null) {
        book.value = val[0];
      }
      // console.log(book.value);
    });

    return {
      book,
      shoppingCart,
      orderCollection,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin commonDescriptionStyle($font-size, $padding: 14px) {
  @media (max-width: 576px) {
    font-size: $font-size;
    font-weight: 600;
    line-height: 20px;
    padding: $padding;
  }
}

.wrapper {
  height: 100%;
  width: 100%;
  background-color: #172640;
  padding: 25px;

  @media (max-width: 576px) {
    padding: 10px;
  }

  .details-container {
    height: 100%;
    width: 100%;
    border: 1px solid black;
    padding: 2px;

    background: #172640;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);

    .category {
      padding: 25px;

      p {
        color: white;
        font-size: 13px;
        font-weight: 400;

        b {
          text-decoration: underline;
        }
      }
    }

    .details {
      display: flex;

      @media (max-width: 576px) {
        flex-direction: column;
      }

      .imagenbtns {
        display: flex;
        flex-direction: column;
        margin: 6px;
        flex: 3;

        .image {
          height: 60%;

          img {
            height: 80%;
            width: 80%;
            border-radius: 15px;
            padding: 4px;
            object-fit: contain;
            cursor: pointer;
          }
        }

        .btns {
          height: auto;
          padding: 10px;

          button {
            padding: 4px 15px;
            border-radius: 10px;
            background-color: white;
            color: black;
            font-size: 11px;
            height: 29px;

            width: 150px;
            margin: 5px 10px;
            border: none;
            outline: none;

            @media (max-width: 576px) {
              height: 25px;
              font-size: 10px;
              font-weight: 600;
            }
          }

          #buy:hover {
            transform: rotate(360deg);
            background-color: blue;
            color: white;
            font-size: 10px;

            transition: all 300ms ease;
          }

          #cart:hover {
            transform: scale(1.1);
            background-color: rgb(119, 72, 121);
            color: white;
            font-size: 10px;
            transition: all 300ms ease;
          }
        }
      }

      .all-informations {
        // background-color: green;
        flex: 5;

        h5 {
          font-size: 16px;
          color: white;
          font-weight: 600;
          padding: 20px;
          text-decoration: underline;

          @include commonDescriptionStyle(13px);
        }

        h6 {
          font-size: 14px;
          color: white;
          font-weight: 400;
          padding: 20px;

          @include commonDescriptionStyle(11.7px);
        }

        .current-price {
          display: flex;
          padding: 20px;

          @media (max-width: 576px) {
            padding: 14px;
          }

          p {
            font-size: 12.5px;
            color: white;

            @include commonDescriptionStyle(11.7px, 0px);
          }

          #money {
            padding-left: 5px;
          }
        }

        .mrp {
          display: flex;
          padding: 10px 20px;

          @media (max-width: 576px) {
            padding: 14px;
          }

          p {
            font-size: 12.5px;
            color: white;

            @include commonDescriptionStyle(11.7px, 0px);
          }

          .discount {
            color: yellow;
            font-weight: 600;
            padding-left: 10px;
          }
        }

        .description {
          p {
            font-size: 12.5px;
            color: white;
            font-weight: 500;
            padding: 20px;
            letter-spacing: 0.3px;
            text-align: justify;

            @include commonDescriptionStyle(11.65px);
          }
        }

        .highlights {
          padding: 40px;

          ul {
            li {
              display: list-item;
              list-style: square;
              list-style-position: outside;
              color: white;

              p {
                color: white;

                @include commonDescriptionStyle(11.7px, 0px);
              }
            }
          }
        }

        .author-info {
          padding: 20px;

          .about-author {
            // padding: 0px 0px 20px 10px;
            font-size: 12.5px;
            color: white;
            font-weight: 500;
            letter-spacing: 0.3px;
            text-align: justify;
            line-height: 25px;

            @include commonDescriptionStyle(11.7px);
          }
          @media screen and (max-width: 576px) {
            padding: 5px;
            // text-align: start;
          }
        }
      }
    }
  }
}
</style>
