<template>
  <div class="wrapper">
    <div class="container">
      <div class="column">
        <div class="col-lg-12">
          <div class="section-heading">
            <h4 id="heading">Photo Gallery</h4>
          </div>

          <div class="gallery-section col-lg-12">
            <div class="gallery" v-for="(album, i) in albumList" :key="i">
              <div class="title-container">
                <p class="galley-title">
                  {{ album.title }}
                </p>
              </div>

              <div class="all-photo2">
                <div
                  class="photo"
                  v-for="(v, i) in album.imageList"
                  :key="i"
                  @click="getSelectedImage(v)"
                >
                  <img :src="v" alt="" />
                </div>
              </div>
            </div>

            <div class="modal-sheet" v-if="showModalSheet">
              <div class="image-container">
                <div class="cancel">
                  <button id="btn" @click="toggleModalSheet">
                    <i class="fa-solid fa-xmark"></i>
                  </button>
                </div>
                <img :src="selectedImage" alt="" />
              </div>
            </div>
          </div>

          <div id="notFound">
            <p v-if="albumList.length === 0">No album found!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";

// import { photoAlbumCollection } from "@/stores/upload-photo-album.js";
import { onMounted } from "@vue/runtime-core";

export default {
  name: "photo-gallery",
  setup() {
    //photo album store instance
    // const albumCollection = photoAlbumCollection();

    //album list
    const albumList = ref([]);

    onMounted(() => {
      // albumCollection.$reset();
      // albumCollection.extractAlbumsFromFirebase().then(() => {
      //   albumList.value = albumCollection.albumCollection;
      // });
      // assigning static values to the album list
      (albumList.value = {
        title: "philanthropic",
        imageList: [
          require("@/assets/images/Sampriti.jpg"),
          require("@/assets/images/Sampriti.jpg"),
          require("@/assets/images/Sampriti.jpg"),
        ],
      }),
        {
          title: "New Activities",
          imageList: [
            require("@/assets/images/Sampriti.jpg"),
            require("@/assets/images/Sampriti.jpg"),
            require("@/assets/images/Sampriti.jpg"),
          ],
        };
    });

    const selectedImage = ref("");
    const showModalSheet = ref(false);

    //get selected Image

    function getSelectedImage(link) {
      selectedImage.value = link;
      console.log(selectedImage.value);
      showModalSheet.value = !showModalSheet.value;
    }

    function toggleModalSheet() {
      showModalSheet.value = !showModalSheet.value;
    }

    return {
      selectedImage,
      getSelectedImage,
      showModalSheet,
      toggleModalSheet,
      albumList,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin makeFlex() {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: #172640;
  // object-fit: cover;
  overflow: auto;

  .section-heading {
    padding-top: 60px;
  }

  .gallery {
    height: auto;
    width: 100%;
    border-bottom: 1px solid gainsboro;
    margin-bottom: 60px;

    @media (max-width: 576px) {
      height: 220px;
    }

    .title-container {
      height: auto;
      width: 100%;
      margin-bottom: 15px;
      text-overflow: ellipsis;
      overflow: hidden;

      .galley-title {
        color: white;
        font-size: 15px;
        font-weight: 900;
        padding-left: 15px;
        letter-spacing: 0.5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 80%;

        @media (max-width: 576px) {
          font-size: 13px;
          padding-left: 10px;
        }
      }
    }

    .all-photo2 {
      height: 80%;
      width: 100%;
      // background-color: blue;

      display: grid;
      grid-auto-flow: column;

      grid-auto-columns: 26%;
      gap: 5px;
      overflow: auto;
      overscroll-behavior-inline: contain;
      margin: auto;
      padding-bottom: 5px;

      @media (max-width: 576px) {
        display: grid;
        grid-auto-flow: row;
        // overflow: hidden;
        grid-template-columns: repeat(3, 63%);
        // gap: 2px;
        column-gap: 8px;
      }

      .photo {
        height: 100%;
        border: 1px solid whitesmoke;
        border-radius: 10px;

        @media (max-width: 576px) {
          border-radius: 5px;

          border: 1px solid #172640;
        }

        img {
          height: 100%;
          width: 100%;
          border-radius: 10px;

          cursor: pointer;

          &:hover {
            transform: translateY(-4px);
            border-color: aqua;
            transition: all 300ms ease;
          }

          @media (max-width: 576px) {
            border-radius: 5px;
          }
        }
      }
    }
  }

  #notFound {
    display: grid;
    place-content: center;
    p {
      font-size: 15px;
      color: white;
    }
  }

  .modal-sheet {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 1);
    // flex-direction: column;

    display: flex;
    justify-content: center;
    align-items: center;

    .image-container {
      height: auto;
      width: auto;
      max-height: 70%;
      max-width: 70%;
      // background-color: red;

      @media (max-width: 576px) {
        height: auto;
        width: auto;
        max-height: 80%;
        max-width: 90%;
      }

      .cancel {
        position: relative;

        #btn {
          color: red;
          background-color: whitesmoke;
          // border: 1px solid blue;
          outline: none;
          border: none;
          border-radius: 50%;
          padding: 10px;
          height: 35px;
          font-size: 12px;
          width: 35px;
          display: flex;
          justify-content: center;
          align-items: center;

          margin: 7px;
          position: absolute;
          top: -25px;
          right: -35px;

          &:hover {
            background-color: red;

            color: white;
            transform: translate(-2px, -3px);
            transition: all 250ms ease 0;
          }

          @media (max-width: 576px) {
            right: 0px;
          }
        }
      }

      img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
      }
    }
  }
}
</style>
