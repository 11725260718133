import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { defineStore } from "pinia";
import { v4 as uid } from "uuid";
import { useToast } from "vue-toastification";
import { db } from "@/firebase/firebase-init.js";
import { trackChangesInTheDatabase } from "@/stores/track-changed-collections-by-admin";

const toast = useToast();

export const sampritiIssueStore = defineStore("sampritiIssue", {
  state: () => ({
    sampritiIssueCollection: [],
    extractedIssueCollection: [],
    FilteredCollection: [],
    downloadedQuerySnapshot: [],
    selectedIssueId: String,
    latestIssue: [],
    LastSnapshotUpdateTime: [],
  }),
  getters: {
    getPost: (state) => {
      return state.PostCollection;
    },
  },
  actions: {
    //use it while uploading a new issue
    async createNewIssue(issue) {
      if (issue === null) {
        toast.warning("Content missing!");
        return;
      }

      var issueObject = {
        id: uid(),
        ...issue,
      };

      this.sampritiIssueCollection.push(issueObject);

      // Add a new document in collection "allAddresses"
      await addDoc(collection(db, "allSampritiIssues"), issueObject).then(
        () => {
          toast.success("New Issue uploaded successfully");
        }
      );

      var timeObj2 = new Date();
      var LastSnapshotUpdateObject2 = {
        date: timeObj2.getDate(),
        time: timeObj2.getTime(),
        month: timeObj2.getMonth(),
        year: timeObj2.getFullYear(),
      };
      await trackChangesInTheDatabase().addNewDatabaseChange(
        "issue",
        LastSnapshotUpdateObject2
      );
    },

    //update an issue in case of existing laetst issue
    async renameExistingIssue(newLabel) {
      var targetId = "";

      const querySnapshot = await getDocs(collection(db, "allSampritiIssues"));
      querySnapshot.forEach((doc) => {
        if (doc.data().title === "Sampriti Latest Issue") {
          targetId = doc.id;
        }
      });

      if (targetId.length !== 0) {
        const addressRef = doc(db, "allSampritiIssues", targetId);
        await updateDoc(addressRef, {
          title: String(newLabel),
        })
          .then(async () => {
            toast.success("Successfully renamed last");
            var timeObj2 = new Date();
            var LastSnapshotUpdateObject2 = {
              date: timeObj2.getDate(),
              time: timeObj2.getTime(),
              month: timeObj2.getMonth(),
              year: timeObj2.getFullYear(),
            };
            await trackChangesInTheDatabase().addNewDatabaseChange(
              "gallery",
              LastSnapshotUpdateObject2
            );

            this.getPdfsFromFirebase();
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    },

    //retrieve all addresses from firebase
    async getPdfsFromFirebase() {
      if (this.sampritiIssueCollection.length === 0) {

        const querySnapshot = await getDocs(
          collection(db, "allSampritiIssues")
        ).catch((e) => {
          console.log(e.message);
        });
        // this.downloadedQuerySnapshot = querySnapshot;

        if (!querySnapshot.empty) {
          this.LastSnapshotUpdateTime = [];
          var timeObj = new Date();
          var LastSnapshotUpdateObject = {
            date: timeObj.getDate(),
            time: timeObj.getTime(),
            month: timeObj.getMonth(),
            year: timeObj.getFullYear(),
          };

          this.LastSnapshotUpdateTime.push(LastSnapshotUpdateObject);

          this.extractedIssueCollection = [];

          querySnapshot.forEach((doc) => {
            if (doc.data().title === "Sampriti Latest Issue") {
              this.latestIssue = [];
              this.latestIssue.push(doc.data());
            }

            this.extractedIssueCollection.push(doc.data());
          });
          this.sampritiIssueCollection = this.extractedIssueCollection;
        }
      } else {

        
        var hasChange = false;
        await trackChangesInTheDatabase()
          .checkChangeInDatabase("issue")
          .then(() => {
            var changeObj =
              trackChangesInTheDatabase().sampritiIssueChangeObject;

            if (changeObj === null) {
              return;
            }

            if (
              JSON.stringify(changeObj) ===
              JSON.stringify(this.LastSnapshotUpdateTime[0])
            ) {
              return;
            } else {
              if (changeObj.date < this.LastSnapshotUpdateTime[0].date) {
                hasChange = false;
              } else if (changeObj.year < this.LastSnapshotUpdateTime[0].year) {
                hasChange = false;
              } else if (changeObj.time < this.LastSnapshotUpdateTime[0].time) {
                hasChange = false;
              } else if (
                changeObj.month < this.LastSnapshotUpdateTime[0].month
              ) {
                hasChange = false;
              } else {
                hasChange = true;
              }
            }
          });

        //run this is there is a chnage
        if (hasChange) {
          const querySnapshot = await getDocs(
            collection(db, "allSampritiIssues")
          );

          this.LastSnapshotUpdateTime = [];
          var timeObj2 = new Date();
          var LastSnapshotUpdateObject2 = {
            date: timeObj2.getDate(),
            time: timeObj2.getTime(),
            month: timeObj2.getMonth(),
            year: timeObj2.getFullYear(),
          };

          this.LastSnapshotUpdateTime.push(LastSnapshotUpdateObject2);

          this.extractedIssueCollection = [];

          querySnapshot.forEach((doc) => {
            if (doc.data().title === "Sampriti Latest Issue") {
              this.latestIssue = [];
              this.latestIssue.push(doc.data());
            }

            this.extractedIssueCollection.push(doc.data());
          });
          this.sampritiIssueCollection = this.extractedIssueCollection;
        }
      }
    },

    setSelectedIssueId(issueId) {
      this.selectedIssueId = issueId;
    },
  },
  persist: true,
});
