<template>
  <div class="swiper-section">
    <swiper
      :slides-per-view="noOfSlides"
      :space-between="5"
      :autoplay="true"
      :speed="1000"
      :loop="true"
      :lazy="true"
      :modules="modules"
      :observer="true"
      :observe-parents="true"
      effect="coverflow"
    >
      <swiper-slide v-for="info in informations" :key="info.id">
        <div class="slide">
          <div class="dark-wrap">
            <div class="icon">
              <img :src="info.icon" />
            </div>
            <div class="content">
              <h4 class="heading">
                {{ info.title }}
              </h4>

              <p class="description">
                {{ info.description }}
              </p>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// import Swiper core and required modules
import { Autoplay, Parallax, Lazy, EffectCoverflow } from "swiper";

// Import Swiper styles
import "swiper/css";

import "swiper/css/autoplay";
import "swiper/css/effect-coverflow";

import { ref } from "@vue/reactivity";
import { onBeforeMount } from "@vue/runtime-core";

// SwiperCore.use([Autoplay, Parallax, EffectFade]);

export default {
  setup() {
    const informations = {
      first: {
        id: 1,
        icon: require("@/assets/images/service-icon-01.png"),
        title: "Double Blind Peer Reviewed",
        description:
          "Initially, all submitted articles are reviewed by our Honorary Editorial Board and the forwarded to the Peer Team/Honorary Technical Review Board.",
      },
      second: {
        id: 2,
        icon: require("@/assets/images/service-icon-02.png"),

        title: "Highly Experienced Review Team",
        description:
          "Members of our Editorial Board, Advisory Board and Technical Review team are experts associated with reputed universities and research institutes.",
      },
      third: {
        id: 3,
        icon: require("@/assets/images/service-icon-03.png"),

        title: "UGC-carelist",
        description:
          "Sampriti also has a prestigious entry to The UGC-carelist as double blind peer reviewd national journal of Humanities and Social Sciences.",
      },
      fourth: {
        id: 4,
        icon: require("@/assets/images/service-icon-02.png"),
        title: "Our Journey",
        description:
          "We have beem publishing Original,Innovative and High Quality research articles since 2015 to develop a culture of Indian stream in research writing.",
      },
    };

    const noOfSlides = ref(1);

    function modifyNoOfSlides() {
      // const windowWidth=window.innerWidth;

      window.addEventListener("resize", () => {
        if (window.innerWidth > 800) {
          noOfSlides.value = 3;
        }

        if (window.innerWidth < 800) {
          noOfSlides.value = 2;
        }
        if (window.innerWidth < 452) {
          noOfSlides.value = 1;
        }
      });
    }

    onBeforeMount(() => {
      if (window.innerWidth > 800) {
        noOfSlides.value = 3;
        // console.log(noOfSlides.value);
      }
      if (window.innerWidth < 800) {
        noOfSlides.value = 2;
      }
      if (window.innerWidth < 452) {
        noOfSlides.value = 1;
      }
    });

    modifyNoOfSlides();

    const getImgUrl = function (path) {
      return require("@/assets/" + path);
    };

    return {
      modules: [Lazy, Autoplay, Parallax, EffectCoverflow],
      informations,
      getImgUrl,
      noOfSlides,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap");

.swiper-section {
  margin-top: -125px;
  position: absolute;
  width: 100%;

  @media (max-height: 552px) {
    margin-top: 10px;
  }

  @media (max-width: 452px) {
    margin-top: -100px;
  }

  @media (max-width: 290px) {
    margin-top: -70px;
  }
}

.slide {
  height: 250px;
  margin: auto 15px;
  border-radius: 17px;
  background-image: linear-gradient(
    to right top,
    #051937,
    #04234f,
    #042e67,
    #0a3881,
    #16439b
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;

  -webkit-box-shadow: 10px -7px 16px -12px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 10px -7px 16px -12px rgba(0, 0, 0, 0.45);
  box-shadow: 10px -7px 16px -12px rgba(0, 0, 0, 0.45);

  .dark-wrap {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 25px;
    border-radius: 17px;

    .icon {
      flex: 1;
      text-align: center;
      margin-bottom: 10px;

      img {
        max-width: 60px;
        margin: 0 auto;
        @media (max-width: 576px) {
          max-width: 50px;
        }
      }
    }

    .content {
      .heading {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: white;
        letter-spacing: 0.8px;
        text-shadow: 2px 2px 2px rgba(206, 89, 55, 0);
        font-family: "Kanit", sans-serif;

        @media (max-width: 576px) {
          font-size: 13px;
          font-weight: 600;
        }
      }

      .description {
        text-align: justify;
        font-weight: 400;
        font-size: 12.5px;
        letter-spacing: 0.5px;
        padding-top: 18px;
        line-height: 21px;
        color: white;
        font-family: "Kanit", sans-serif;

        @media (max-width: 576px) {
          font-size: 11.5px;
          font-weight: 500;
          letter-spacing: 0.35px;
          padding-top: 13px;
        }
      }

      flex: 2;
      padding-top: 15px;

      @media (max-width: 576px) {
        padding-top: 13px;
      }
    }
  }
}
</style>
