<template>
  <div>
    <div
      class="single-order-display"
      @click="goToPages('order-view-for-admin')"
    >
      <div class="status">
        <button>
          Order Status <b>{{ order.orderStatus }}</b>
        </button>
        <button>
          Delivery Status:<b>{{ order.deliveryStatus }}</b>
        </button>
      </div>
      <div class="id-and-info">
        <div class="order-id">
          <h5><b>Order-ID::</b></h5>
          <p>{{ order.orderId }}</p>
        </div>
        <div class="time">
          <P
            >Order Placed: <b>{{ order.orderCreateTime }}</b>
          </P>
          <P
            >Due Delivery Date: <b>{{ order.expectedDeliveryTime }}</b>
          </P>
        </div>
      </div>

      <div class="content-section">
        <div class="total-items">
          <h5><b>Total Items Ordered:: </b></h5>
          <h5>{{ totalOrderdItem }}</h5>
        </div>
        <div class="amount-received">
          <h5><b>Amount received:: </b></h5>
          <h5>
            {{ Number(totalOrderPrice).toFixed(2) }}
            <i class="fa-solid fa-indian-rupee-sign fa-xs"></i>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { onMounted, ref } from "@vue/runtime-core";
import { bookOrderCollection } from "@/stores/bookorder-collection";
export default {
  props: {
    order: Object,
  },

  setup(props) {
    function goToPages(name) {
      // console.log(name);
      orderCollection.setSelectedOrderForAdmin(props.order);
      // orderCollection.$reset();
      return router.push({
        name: name,
      });
    }

    const orderCollection = bookOrderCollection();

    //order items list
    const orderList = ref([]);

    const totalOrderdItem = ref("");
    const totalOrderPrice = ref("");

    onMounted(() => {
      // console.log(props.order);
      var price = 0;
      var quantity = 0;

      orderList.value = props.order.orderedItemsList;
      orderList.value.forEach((order) => {
        price += Number(order.totalItemPrice);
        quantity += Number(order.quantity);
      });
      totalOrderPrice.value = price;
      totalOrderdItem.value = quantity;
    });

    return {
      goToPages,
      totalOrderPrice,
      totalOrderdItem,
    };
  },
};
</script>

<style lang="scss" scoped>
.single-order-display {
  height: auto;
  width: 90%;
  margin: 40px auto;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  //   border: 1px solid goldenrod;
  background: #2a4775;

  cursor: pointer;

  @media (max-width: 500px) {
    width: 100%;
  }

  .status {
    padding: 10px auto;

    button {
      height: 25px;
      font-size: 10px;
      padding: 5px 25px;
      font-weight: 600;
      border: none;
      outline: none;
      border-radius: 15px;
      margin-left: 20px;
      margin-top: 10px;
    }
  }

  .id-and-info {
    display: flex;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    .order-id {
      display: flex;
      flex: 1;

      h5 {
        margin: auto 15px;
        font-size: 14px;
        color: white;
      }

      p {
        color: white;
        font-size: 14px;
        padding: 20px;
        font-weight: 600;
      }
    }

    .time {
      flex: 1;
      text-align: end;

      @media (max-width: 500px) {
        text-align: start;
      }

      p {
        padding: 0px 20px;
        font-size: 12px;
        color: white;
      }
    }
  }

  .content-section {
    padding-top: 10px;
    margin-bottom: 10px;

    .total-items,
    .amount-received {
      display: flex;
      padding: 5px 15px;

      h5 {
        font-size: 14px;
        font-weight: 600;
        margin-right: 20px;
        color: white;
      }
    }
  }
}
</style>
