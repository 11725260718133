<template>
  <div class="wrapper">
    <userActiveOrder
      v-for="(v, i) in allConfirmedOrders"
      :key="i"
      :completeOrder="v"
    ></userActiveOrder>
  </div>
  <div class="loading" v-if="isLoading">
    <p>Loading....</p>
  </div>
</template>

<script>
import userActiveOrder from "@/components/order-for-user-view.vue";
import { onMounted, ref } from "@vue/runtime-core";
import { bookOrderCollection } from "@/stores/bookorder-collection";

export default {
  name: "current-orders",
  components: { userActiveOrder },

  setup() {
    const isLoading = ref(false);

    const allConfirmedOrders = ref([]);

    //get the book order collection instance
    const orderCollection = bookOrderCollection();
    onMounted(() => {
      isLoading.value = true;

      orderCollection.extractOrdersFromBackend(true).then(() => {
        allConfirmedOrders.value = orderCollection.allConfirmedOrdersOfaUser.filter(
          (order)=>order.orderStatus !=='Fulfilled'
        );
        setTimeout(() => {
          isLoading.value = false;
        }, 3000);
      });
    });

    return {
      allConfirmedOrders,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: #1d4f99;
  padding: 30px;

  @media (max-width: 576px) {
    padding: 30px 9px;
  }
}
.loading {
  position: fixed;
  inset: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 9000;

  display: grid;
  place-content: center;

  p {
    color: white;
    font-size: 15px;
  }
}
</style>
