import { defineStore } from "pinia";

export const unncessaryFileUrlCollection = defineStore("unncessary", {
  state: () => ({ fileUrlCollection: [] }),
  getters: {
    getPost: (state) => {
      return state.fileUrlCollection;
    },
  },
  actions: {
    addNewFileUrl(url) {
      if (url!==null) {
        if (!this.fileUrlCollection.includes(url)) {
          this.fileUrlCollection.push(url);
        }
      }
      console.log(this.fileUrlCollection);
    },

    //delete a url from the list
    deleteUrl(url) {
      this.fileUrlCollection = this.fileUrlCollection.filter((x) => x !== url);
    },

    //reset the collection
    resetCollection() {
      this.fileUrlCollection = [];
      console.log(this.fileUrlCollection);
    },
  },
  persist: true,
});
