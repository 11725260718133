<template>
  <!-- <div class="post" @click="gotoPostView(post.id)"> -->
  <div class="post">
    <h5 id="post-title-identifier">
      <!-- <button>Post Title</button> -->
      <button>{{ post.type }}</button>
    </h5>

    <div class="post-title">
      <p id="title" v-if="post">
        {{ post.title }}
      </p>
    </div>

    <div class="post-info">
      <div class="category-name" v-if="post">
        <p><i class="fa-solid fa-user"></i> <span>By admin</span></p>
        <!-- <button>{{ post.type }}</button> -->
      </div>

      <div class="time-of-post" v-if="post">
        <p>{{ post.time }}</p>
      </div>
    </div>

    <div class="cover-image" v-if="post.postImage.length !== 0">
      <img :src="value" v-for="(value, index) in post.postImage" :key="index" />
    </div>
    <!-- <div class="included-images" v-if="post.otherImages.length !== 0">
      <div
        class="other-image"
        v-for="(value, index) in post.otherImages"
        :key="index"
      >
        <img :src="value" alt="" />
      </div>
    </div> -->
    <div class="links" v-if="post.importantLinks.length !== 0">
      <a
        v-for="(v, i) in post.importantLinks"
        :key="i"
        :href="v.linkvalue"
        target="_blank"
        >{{ v.linkvalue }}</a
      >
    </div>

    <div class="description" v-if="post.description.length !== 0">
      <p id="description-text">
        {{ post.description }}
      </p>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { PostCollectionStore } from "@/stores/posts";

export default {
  props: {
    post: Object,
    index: Number,
  },

  setup() {
    //store instance
    const allPost = PostCollectionStore();

    function gotoPostView(postId) {
      allPost.setSelectedPostId(postId);
      router.push({
        name: "fullpost-view",
      });
    }

    return {
      gotoPostView,
    };
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@600&display=swap");

.post {
  width: 65%;
  height: auto;
  background-image: linear-gradient(
    to right top,
    #051937,
    #00384d,
    #205659,
    #537363,
    #878e76
  );
  border: none;
  margin: 20px auto 32px 30px;
  border-radius: 10px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  @media (max-width: 576px) {
    width: 95%;
    margin: 20px auto;
  }
  #post-title-identifier {
    font-size: 11px;
    color: white;
    padding-left: 1.2em;

    button {
      background: blue;
      padding: 2px 15px;

      border: none;
      outline: none;
      margin-bottom: 8px;
      border-radius: 2px;
    }
  }

  .post-info {
    height: auto;
    width: 100%;
    display: flex;
    padding-left: 20px;
    // background: yellow;

    margin-bottom: 20px;
    @media (max-width: 322px) {
      padding-left: 14px;
    }
    .category-name {
      display: flex;
      p {
        font-size: 10px;
        color: white;
        font-weight: 400;
        margin: 3px auto;
        span {
          font-size: 10.5px;
          font-weight: 500;
        }
      }

      // button {
      //   font-size: 10px;
      //   color: white;
      //   background-color: blue;
      //   padding: 2px 18px;
      //   border-radius: 5px;
      //   font-weight: 600;
      //   margin-left: 7px;

      //   border: none;
      //   outline: none;
      //   @media (max-width: 322px) {
      //     height: 30px;
      //     margin-left: 3px;
      //   }
      // }
    }
    .time-of-post {
      p {
        font-size: 10px;
        font-weight: 700;
        color: white;
        padding-left: 10px;
        padding-top: 3px;
        @media (max-width: 322px) {
          // height: 30px;
          padding-left: 8px;
        }
      }
    }
  }

  .post-title {
    height: auto;
    // background: red;
    width: 95%;
    max-height: 140px;
    padding: 5px;
    border-radius: 11px;
    margin: 0 auto 15px auto;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    p {
      color: whitesmoke;
      font-size: 14px;
      padding: 1px;
      font-family: "Kanit", sans-serif;
      display: block;
      @media (max-width: 322px) {
        font-size: 13px;
      }
    }
  }
  .cover-image {
    height: auto;
    width: 100%;
    object-fit: cover;
    img {
      max-width: 90%;
      height: auto;
      max-height: 200px;
      border-radius: 10px;
      outline: none;
      margin-left: 10px;
      &:hover {
        transform: translate(2px, -2px) scaleX(1.05);

        transition: all 350ms ease-in;
      }
    }
    p {
      font-size: 12px;
      color: yellow;
    }
  }

  .included-images {
    height: 146px;
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 26%;
    gap: 4px;
    overflow: auto;
    overscroll-behavior-inline: contain;
    padding-top: 5px;

    margin: 20px auto;

    .other-image {
      border: 1px solid white;
      border-radius: 10px;
      height: 85%;

      margin-left: 10px;

      @media (max-width: 322px) {
        margin-left: 5px;
      }
      img {
        height: 100%;
        border-radius: 10px;
        padding: 1px;
      }

      &:hover {
        transform: translateY(-4px);
        transition: all 350ms ease-in;
        border: 1px solid goldenrod;
      }
    }
  }

  .links {
    display: flex;
    max-height: 50px;
    height: auto;
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
    a {
      padding-left: 20px;
      font-size: 11px;
    }
  }

  .description {
    max-height: 150px;
    height: auto;
    padding: 0px 10px;
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
    -webkit-box-shadow: 10px 10px 40px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 40px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 40px -10px rgba(0, 0, 0, 0.75);
    overflow: hidden;
    z-index: 1;
    border-radius: 5px;

    P {
      font-size: 11.5px;
      font-weight: 400;
      color: white;
      text-align: start;
      padding: 5px 12px;
    }
  }
}
</style>
