<template>
  <div class="wrapper">
    <div class="account-info">
      <div class="edit">
        <button @click="toggleEdit()">
          <i class="fa-solid fa-pen-to-square fa-lg"></i>
        </button>
      </div>

      <h6><b>Account Details</b></h6>

      <div class="name">
        <label>Name:</label>
        <p>{{ currentExtractedUser.name }}</p>
      </div>
      <div class="sex">
        <label>Sex:</label>
        <p>{{ currentExtractedUser.sex }}</p>
      </div>
      <div class="age">
        <label>Age:</label>
        <p>{{ currentExtractedUser.age }}</p>
      </div>
      <div class="email">
        <label>Email:</label>
        <p>{{ currentExtractedUser.email }}</p>
      </div>
      <div class="phone">
        <label>Phone No:</label>
        <p>{{ currentExtractedUser.phoneNo }}</p>
      </div>
      <div class="joindate">
        <label>User since:</label>
        <p>{{ currentExtractedUser.accountCreationTime }}</p>
      </div>
    </div>

    <div class="edit-account-info" v-if="editNow">
      <div class="edit-details">
        <div class="butn">
          <button @click="toggleEdit()">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div class="edit-name">
          <div class="labelContainer">
            <label>Name:</label>
          </div>
          <div class="input-container">
            <input v-model="name" type="text" placeholder="Enter Name" />
          </div>
        </div>
        <div class="edit-sex">
          <div class="labelContainer">
            <label>Sex:</label>
          </div>
          <div class="input-container">
            <select v-model="sex">
              <option>Male</option>
              <option>Female</option>
              <option>others</option>
            </select>
          </div>
        </div>
        <div class="edit-age">
          <div class="labelContainer">
            <label>Age:</label>
          </div>
          <div class="input-container">
            <input
              v-model="age"
              placeholder="Enter age"
              type="text"
              maxlength="2"
              @keyup="onlyNumberAllowd('age')"
            />
          </div>
        </div>
        <div class="edit-email">
          <div class="labelContainer">
            <label>Email:</label>
          </div>
          <div class="input-container">
            <input
              v-model="email"
              type="email"
              placeholder="Enter Email"
              readonly
            />
          </div>
        </div>

        <div class="edit-phone">
          <div class="labelContainer">
            <label>Phone:</label>
          </div>
          <div class="input-container">
            <input
              v-model="phoneNo"
              placeholder="Enter Phone No"
              type="text"
              maxlength="10"
              @keyup="onlyNumberAllowd('phoneNo')"
            />
          </div>
        </div>
        <div class="action-b">
          <button @click="updateUserdetails()">update</button>
          <button @click="editNow = !editNow">cancel</button>
        </div>
      </div>
    </div>
  </div>
  <!-- //need to use the suspense feature of vue  -->
  <div class="loading" v-if="isValidating">
    <p>Please Wait....</p>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { userCreation } from "@/stores/user-verification";
import router from "@/router";
import { useToast } from "vue-toastification";

export default {
  name: "account-info",
  setup() {
    const toast = useToast();

    //whether to edit or not
    const editNow = ref(false);

    //is updating or not
    const isValidating = ref(false);

    //user collection
    const userCollection = userCreation();

    //current extracted user
    const currentExtractedUser = ref("");

    function toggleEdit() {
      editNow.value = !editNow.value;
      updateInitially();
    }

    //v model values for edit
    const name = ref("");
    const sex = ref("");
    const age = ref("");
    const email = ref("");
    const phoneNo = ref("");

    onMounted(() => {
      if (userCollection.currentUser === null) {
        router.push({
          name: "signin-login",
        });
      }

      updateInitially();
    });

    //update fields initially
    function updateInitially() {
      if (userCollection.extractedCustomUser !== null) {
        currentExtractedUser.value = userCollection.extractedCustomUser;
        email.value = currentExtractedUser.value.email;
        name.value = currentExtractedUser.value.name;
        sex.value = currentExtractedUser.value.sex;
        age.value = currentExtractedUser.value.age;
        phoneNo.value = currentExtractedUser.value.phoneNo;
      }
    }

    //update user data
    async function updateUserdetails() {
      if (name.value.length === 0) {
        toast.warning("Name cant be empty!");
        return;
      }
      if (
        name.value === currentExtractedUser.value.name &&
        sex.value === currentExtractedUser.value.sex &&
        age.value === currentExtractedUser.value.age &&
        phoneNo.value === currentExtractedUser.value.phoneNo
      ) {
        toast.warning("No change detected!");
        return;
      }

      const modifiedUser = {
        accountCreationTime: currentExtractedUser.value.accountCreationTime,
        age: age.value,
        email: email.value,
        sex: sex.value,
        name: name.value,
        password: currentExtractedUser.value.password,
        userId: currentExtractedUser.value.userId,
        isAdmin: currentExtractedUser.value.isAdmin,
        phoneNo: phoneNo.value,
      };
      isValidating.value = true;
      await userCollection.updateUserDetails(modifiedUser).then(() => {
        currentExtractedUser.value = userCollection.extractedCustomUser;
        setTimeout(() => {
          editNow.value = false;
          isValidating.value = false;
        }, 1800);
      });
    }

    //restricting certain input fields to accept only numbers
    function onlyNumberAllowd(input) {
      var restrictRegex = /[^0-9]/gi;
      this[input] = this[input].replace(restrictRegex, "");
    }

    return {
      updateInitially,
      onlyNumberAllowd,
      updateUserdetails,
      editNow,
      toggleEdit,
      currentExtractedUser,
      name,
      isValidating,
      sex,
      age,
      email,
      phoneNo,
      userCollection,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin chnageFlxDirection {
  @media screen and (max-width: 500px) {
    flex-direction: column;

    input {
      width: 88%;
      margin: 5px auto;
    }
  }
}

@mixin commonStyleForEditContainers {
  height: auto;
  width: 95%;
  margin: 10px;
  display: flex;

  .labelContainer {
    flex: 2;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 500px) {
      justify-content: start;
    }

    label {
      padding-right: 40px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.12px;
      color: white;
    }
  }

  .input-container {
    flex: 3;

    input {
      height: 29px;
      border: none;
      outline: none;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      background-color: whitesmoke;
    }

    select {
      font-size: 12px;
      height: 28px;
      font-weight: 500;
      border: none;
      outline: none;
      background-color: whitesmoke;
      border-radius: 8px;
    }
  }
}

.wrapper {
  height: 100%;
  width: auto;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 212, 255, 1) 50%
  );

  display: grid;
  place-content: center;

  .account-info {
    background-color: lightblue;
    border-radius: 10px;
    padding: 50px 40px;
    margin: 45px auto;

    @media (max-width: 420px) {
      padding: 50px 20px;
      margin: 20px auto;
    }

    .edit {
      display: flex;
      justify-content: flex-end;

      button {
        padding: 0px;
        background: transparent;
        color: black;
        border: none;
        outline: none;

        &:hover {
          transform: translate(2px, -1px);
          transition: all 200ms ease-out;
          color: goldenrod;
        }

        @media (max-width: 420px) {
          margin-right: 20px;
        }
      }
    }

    h6 {
      font-size: 16px;
      margin: 35px 20px;
      text-decoration: underline;
    }

    .name,
    .sex,
    .age,
    .email,
    .phone,
    .joindate {
      display: flex;
      margin: 30px 45px;

      label {
        font-size: 12px;
        font-weight: 600;
        color: black;
        padding-top: 4px;
        padding-right: 10px;
      }

      p {
        font-size: 14px;
        font-weight: 700;
        color: black;
        letter-spacing: 0.2px;
      }

      @media (max-width: 420px) {
        flex-direction: column;
        margin: 12px 10px;

        p {
          font-size: 12px;
        }
      }
    }
  }

  .edit-account-info {
    position: fixed;
    inset: 0;
    max-height: 100%;
    // background: rgba($color: black, $alpha: 0.75);
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 212, 255, 1) 50%
    );

    overflow: scroll;
    // margin: 10px;

    .edit-details {
      height: 90%;
      width: 80%;
      background: #215991;
      margin: 20px auto;
      overflow: scroll;
      border-radius: 10px;

      @media (max-width: 500px) {
        width: 90%;
        height: 95%;
      }

      .butn {
        // background-color: yellow;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
          margin: 20px;
          background-color: transparent;
          border: none;
          outline: none;
          font-size: 25px;
          color: red;

          @media screen and (max-width: 576px) {
            font-size: 20px;
          }
        }
      }

      .edit-name {
        @include commonStyleForEditContainers();

        @include chnageFlxDirection();
        margin: 18px auto;
      }

      .edit-sex {
        @include commonStyleForEditContainers();

        @include chnageFlxDirection();
        margin: 18px auto;
      }

      .edit-age {
        @include commonStyleForEditContainers();

        @include chnageFlxDirection();
        margin: 18px auto;
      }

      .edit-email {
        @include commonStyleForEditContainers();

        @include chnageFlxDirection();
        margin: 18px auto;
      }

      .edit-phone {
        @include commonStyleForEditContainers();

        @include chnageFlxDirection();
        margin: 18px auto;
      }

      .action-b {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 70px auto;

        button {
          height: 30px;
          padding: 4px 20px;
          font-size: 12px;
          border: none;
          outline: none;
          margin: auto 20px;
          border-radius: 10px;
        }
      }
    }
  }
}
.loading {
  position: fixed;
  inset: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 1000;

  display: grid;
  place-content: center;

  p {
    color: white;
    font-size: 15px;
  }
}
</style>
