<template>
  <div class="wrapper">
    <div class="dark-color-overlay">
      <div class="col-lg-9">
        <div class="all-guidelines">
          <h6>
            <b>Dear Researchers/Academicians</b>
          </h6>
          <br />
          <p>
            It is for information to all interested Researchers/ Academicians
            that
            <b
              >SAMPRITI, a double blind peer reviewed national research journal
              on Humanities and Social Sciences</b
            >, publishes
            <b>two issues in the months of March and September in a year</b>. We
            would like to invite all the interested researchers / academicians
            from the Humanities and Social Sciences discipline to contribute
            research papers for the upcoming issues of SAMPRITI. Also for the
            record, we select research papers on first-come first-serve basis.
          </p>
          <br /><br />
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-5 col-sm-8">
              <h6>
                <b style="text-decoration: underline"
                  >Guidelines for the Authors</b
                >
              </h6>
            </div>
          </div>
          <div class="row justify-content-center py-4">
            <div class="col-lg-10">
              <ol class="list-group list-group-numbered">
                <li class="list-group-item selection-step">
                  Write a self declaration,and certifying that the paper is
                  original and unpublished.
                </li>
                <li class="list-group-item selection-step">
                  Give a Plagiarism certificate.
                </li>
                <li class="list-group-item selection-step">
                  Give full address or institutional designations for
                  communication. for example- email, mobile number, postal
                  address etc.
                </li>
                <li class="list-group-item selection-step">
                  Authors have to give the copyright authority to Sampriti.
                </li>

                <li class="list-group-item selection-step">
                  Write a title on the top of the page in centre and mention
                  about the Author's designation, correspondence address,
                  telephone no, fax no, email id etc.
                </li>
                <li class="list-group-item selection-step">
                  <b>
                    An abstract of maximum 100 words must be enclosed with the
                    paper and mention 3 to 6 key words to specify the
                    article.</b
                  >
                </li>
                <li class="list-group-item selection-step">
                  <b>No paper should exceed 3000 words</b>. It includes abstract
                  and references as well.
                </li>
                <li class="list-group-item selection-step">
                  Manuscript should be typed in double line spacing on one side
                  of the paper (A4 size) and leave one inch in every side of the
                  page. Write the article in page maker software in Times new
                  Roman or Geetanjalilight font (English 12 size and Assamese 14
                  size). Send a hard copy and a soft copy of the manuscript.
                </li>

                <li class="list-group-item selection-step">
                  Author must follow the following steps for writing the
                  research article –
                  <p>
                    <span
                      >(i) Introduction, (ii) Area of research, (iii) Objective
                      (iv) Methodology, (v) Results and Discussion (vi)
                      Conclusion/ recommendations</span
                    >
                  </p>
                </li>

                <li class="list-group-item selection-step">
                  In case of citation author has to mark the proper citation
                  side. For any kind of quotation please follow the quotation
                  mark (“.”) and mention the citation in the body of the text
                  like <b>(Patar 30/ Patar, Dhiraj 30/Patar, Rumi 14)</b>.
                </li>
                <li class="list-group-item selection-step">
                  Cited books, Magazines,and news paper names should be type in
                  Italic.
                </li>
                <li class="list-group-item selection-step">
                  Author should mention properly the references in MLA Handbook
                  method. The reference should mention with the cited page
                  number.
                </li>
                <li class="list-group-item selection-step">
                  Bibliography also should be arranged in MLA Handbook method.
                </li>
              </ol>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-9">
              <h6><b> Book: </b></h6>
              <p>
                Patar, D. Tiwa Loko Sanskritir Abhash. Guwahati: Bandhav, 2013.
                Print.
              </p>
              <br />
              <h6><b> Chapter in Book/ article: </b></h6>
              <p>
                Patar, D. “Tiwa Sakalar Paniyo”, in Tiwa Loko Sanskritir
                Abhash.by Dhiraj Patar. Guwahati: Bandhav, 2013. Print.
              </p>
              <br />
              <p>
                Patar. D. ‘‘Tiwa Lokageet,’’ in Asomar Lokasanskritir Nirbachita
                Prabandh. Edited by Baijayanta Keot. Guwahati: Eastern Book
                House, April, 2016. Print.
              </p>
              <br />
              <h6>
                <b> Edited Book: </b>
              </h6>
              <p>
                Patar, D. (ed.). Asomiya Sahityar Bisar Aru Bishlehhan.
                Guwahati: Sampriti, 2015. Print.
              </p>
              <br />
              <h6>
                <b> Ph.D. Thesis: </b>
              </h6>
              <p>
                Patar, D. Morigaon Jilar Utsav-Anushthan : Eti Samikshatmak
                Adhyayan. Ph.D. Thesis. Gauhati University: India, 2013.
                Unpublished.
              </p>
              <br />
              <h6>
                <b>
                  In case of references please mention the citation page no. of
                  the Book or article
                </b>
              </h6>
              <p>
                Patar, D. “Marriage System of the Gaors.” In Marriage Customs of
                Various Ethnic Groups of Assam. Edited by Putali Kayastha,
                Guwahati: Bhabani, 2016, P. 218. Print.
              </p>
              <br />
              <h6>
                <b> Internet source: </b>
              </h6>
              <p>
                Name of author, Title of the document, retrieved URL of specific
                document, form of document (PDF or General) and last updated
                date.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "guide-lines",

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@mixin makeflex() {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  height: 100%;
  width: 100%;
  background-image: url("@/assets/images/Sampriti.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
  background-attachment: fixed;

  .dark-color-overlay {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    @include makeflex();
    overflow: scroll;
  }

  .all-guidelines {
    height: fit-content;
    width: fit-content;
    background-color: rgba(250, 250, 250, 0.9);
    font-weight: 500;
    border-radius: 5px;
    border-color: white;
    padding: 45px 25px;
    margin: 40px auto;
    @media (max-width: 576px) {
      width: 96%;
      margin: 25px auto;
    }
    .selection-step {
      font-size: 12px;
      line-height: 25px;
      font-weight: 500;
      color: black;
      background-color: transparent;
      border: none;
    }

    p {
      color: black;
      font-size: 12px;
      span {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
</style>
