<template>
  <div>
    <div class="address-object">
      <div class="edit">
        <button @click="editAddress = !editAddress">Edit address</button>
        <button id="delete" @click="deleteAddress($event)">delete</button>
      </div>

      <div class="details">
        <div class="selectbtn">
          <input type="radio" :checked="isSelected" @click="selectAddress" />
        </div>
        <div class="targetAddress" @click="selectAddress">
          <div class="name">
            <label>Name:</label>
            <p>{{ address.name }}</p>
            <p style="display: none" id="addressId2">{{ address.id }}</p>
          </div>
          <div class="original-address">
            <div class="address1">
              <p>
                {{ address.address1 }}
              </p>
            </div>
            <div class="address2">
              <p>
                {{ address.address2 }}
              </p>
            </div>
            <div class="pin">
              <label>PIN:</label>
              <p>
                {{ address.pin }}
              </p>
            </div>
            <div class="post-office">
              <label>Post office:</label>
              <p>
                {{ address.postoffice }}
              </p>
            </div>
            <div class="city">
              <label>City:</label>
              <p>
                {{ address.city }}
              </p>
            </div>
            <div class="district">
              <label>District:</label>
              <p>
                {{ address.district }}
              </p>
            </div>
            <div class="state">
              <label>State:</label>
              <p>
                {{ address.state }}
              </p>
            </div>
            <div class="country">
              <label>Country:</label>
              <p>
                {{ address.country }}
              </p>
            </div>
            <div class="phoneNo">
              <label>Phone Number:</label>
              <p>
                {{ address.phoneNo }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- //edit address screen -->
    <div class="edit-address" v-if="editAddress">
      <div class="cancelEdit">
        <button @click="updateAddressAfterEdit">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>

      <!-- edit address-section -->
      <div class="edit-address-section">
        <div class="name-section">
          <label>Name:</label>
          <!-- <p style="display: none" id="addressId">{{ address.id }}</p> -->
          <input :value="address.name" id="addressName" />
        </div>
        <div class="address1-section">
          <label>Address1:</label>
          <textarea :value="address.address1" id="address1"></textarea>
        </div>
        <div class="address2-section">
          <label>Address2:</label>
          <textarea :value="address.address2" id="address2"></textarea>
        </div>
        <div class="pin-section">
          <label>PIN:</label>
          <input :value="address.pin" id="pin" readonly />
        </div>
        <div class="postoffice-section">
          <label>Post Office:</label>
          <input :value="address.postoffice" id="postoffice" readonly />
        </div>
        <div class="city-section">
          <label>City:</label>
          <input :value="address.city" id="city" readonly />
        </div>
        <div class="District-section">
          <label>District:</label>
          <input :value="address.district" id="district" readonly />
        </div>
        <div class="State-section">
          <label>State:</label>
          <input :value="address.state" id="state" readonly />
        </div>
        <div class="Country-section">
          <label>Country:</label>
          <input :value="address.country" id="country" readonly />
        </div>
        <div class="phoneNo-section">
          <label>Phone No:</label>
          <input
            v-model="phoneNumber"
            id="phone-no"
            maxlength="10"
            @keyup="onlyNumberAllowd('phoneNumber')"
          />
        </div>
        <div class="actionbtns">
          <button @click="saveEditedAddressInStore">Save Changes!</button>
        </div>
      </div>
    </div>
  </div>
  <div class="edit-status-modal" v-if="isEditing">
    <p>Please,wait!</p>
  </div>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";

import { useToast } from "vue-toastification";

//importing the address store
import { userAddressCollection } from "@/stores/address-store";
import router from "@/router";

//import the shopping cart store
import { shoppingCartStore } from "@/stores/shopping-cart-store";
import { onMounted } from "@vue/runtime-core";

export default {
  name: "address-vue",
  props: {
    address: Object,
  },

  emits: ["updateAfterEdit"],

  setup(props, { emit }) {
    function onlyNumberAllowd(input) {
      var restrictRegex = /[^0-9]/gi;
      this[input] = this[input].replace(restrictRegex, "");
    }

    const phoneNumber = ref("");

    onMounted(() => {
      phoneNumber.value = props.address.phoneNo;
    });

    //address store instance
    const addressSelection = userAddressCollection();
    const isSelected = ref(false);

    //emit function to ultimately update the address object afterEdit
    function updateAddressAfterEdit() {
      editAddress.value = false;
      emit("updateAfterEdit");
    }
    //shopping cart instacne
    const shoppingCart = shoppingCartStore();

    //modifying the isSelected value if it is the selected address
    isSelected.value =
      addressSelection.getSelectedAddressId === props.address.id;

    const editAddress = ref(false);

    //get the toast instance
    const toast = useToast();

    //delete a saved address from address stor
    function deleteAddress(event) {
      var result = confirm("Are you sure?");

      if (result === false) {
        event.preventDefault();
        return;
      }
      const addressId = document.getElementById("addressId2");
      addressSelection.deleteAddress(addressId.innerText);
    }

    //to select or unselect address
    function selectAddress() {
      isSelected.value = !isSelected.value;
      if (isSelected.value) {
        addressSelection.setSelectedAddressId(props.address.id);

        //extracting the selected delivery address
        const deliveryAddress = addressSelection.selectedAddress[0];

        // console.log(deliveryAddress);

        //creating the order object
        shoppingCart.createOrderObject(deliveryAddress);

        // toast.success("Address selection successful!");
        router.push({
          name: "order-summary",
        });
      } else {
        addressSelection.removeSelectedAddressId(props.address.id);
      }
    }

    //all postoffice names associated with the PIN
    const postOffices = ref([]);

    const editedAddress = reactive({
      id: "",
      name: "",
      address1: "",
      address2: "",
      city: "",
      postoffice: "",
      district: "",
      pin: "",
      state: "",
      country: "",
      phoneNo: "",
    });

    //save the edited address on the store
    function saveEditedAddressInStore() {
      //get the value of all fields
      // const addressId = document.getElementById("addressId");
      const name = document.getElementById("addressName");
      const address1 = document.getElementById("address1");
      const address2 = document.getElementById("address2");
      const pin = document.getElementById("pin");
      const postoffice = document.getElementById("postoffice");
      const city = document.getElementById("city");
      const district = document.getElementById("district");
      const state = document.getElementById("state");
      const country = document.getElementById("country");
      // const phoneNo = document.getElementById("phone-no");

      //assigning the field values to the reactive object values field
      editedAddress.id = props.address.id;
      editedAddress.name = name.value;
      editedAddress.address1 = address1.value;
      editedAddress.address2 = address2.value;
      editedAddress.pin = pin.value;
      editedAddress.city = city.value;
      editedAddress.postoffice = postoffice.value;
      editedAddress.district = district.value;
      editedAddress.state = state.value;
      editedAddress.country = country.value;
      editedAddress.phoneNo = phoneNumber.value;

      if (
        props.address.id === editedAddress.id &&
        props.address.name === editedAddress.name &&
        props.address.address1 === editedAddress.address1 &&
        props.address.address2 === editedAddress.address2 &&
        props.address.pin === editedAddress.pin &&
        props.address.city === editedAddress.city &&
        props.address.postoffice === editedAddress.postoffice &&
        props.address.district === editedAddress.district &&
        props.address.state === editedAddress.state &&
        props.address.country === editedAddress.country &&
        props.address.phoneNo === editedAddress.phoneNo
      ) {
        toast.warning("No Edit performed");
        return;
      } else {
        if (
          editedAddress.name.length === 0 ||
          editedAddress.address1.length === 0 ||
          editedAddress.address2 === 0
        ) {
          toast.warning("name,address1 or address2 cant be empty!");
          return;
        } else {
          if (editedAddress.phoneNo.length !== 10) {
            toast.warning("Not a valid phone number!");
            return;
          } else {
            isEditing.value = true;
            console.log(isEditing.value);
            addressSelection.editAddress(editedAddress).then(() => {
              isEditing.value = false;
            });
          }
        }
      }
    }

    //show edit status
    const isEditing = ref(false);

    return {
      phoneNumber,
      isEditing,
      deleteAddress,
      saveEditedAddressInStore,
      editedAddress,
      isSelected,
      postOffices,
      toast,
      selectAddress,
      editAddress,
      updateAddressAfterEdit,
      onlyNumberAllowd,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin customShadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.address-object {
  height: auto;
  width: 100%;
  margin: 28px auto;
  // background: red;

  display: flex;
  flex-direction: column;

  .edit {
    margin-bottom: 10px;
    text-align: end;
    button {
      border: none;
      outline: none;
      background: transparent;
      font-size: 12px;
      text-decoration: underline;
      transition: all 200ms ease;
      &:hover {
        font-size: 12.2px;
        color: blue;
        transform: translate(-1px);
      }
    }
    #delete {
      color: red;
      text-decoration: none;
      margin: auto 20px;
      font-size: 13px;
    }
  }

  .details {
    display: flex;
    .selectbtn {
      display: grid;
      place-content: center;
      width: 3%;
      margin: auto 10px;
      input {
        cursor: pointer;
        accent-color: blue;
        width: 15px;
        height: 15px;
        border: none;
        outline: none;
      }
    }
    .targetAddress {
      @mixin customTransition {
        transition: all 300ms ease-out;
        &:hover {
          transform: translate(-2px);
        }
      }

      @mixin lablStyle {
        label {
          font-size: 12px;
          font-weight: 400;
          color: black;
          letter-spacing: 0.8px;
        }
      }
      cursor: pointer;
      width: 95%;
      padding: 10px;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.06);
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      .name {
        display: flex;
        font-size: 13px;
        @include lablStyle();
        @include customTransition();
        p {
          margin-top: -4px;
          margin-left: 10px;
          margin-right: 10px;
          font-size: 13.1px;
          letter-spacing: 0.4px;
          font-weight: 500;
          color: black;
        }
      }

      .original-address {
        margin: 5px;
        p {
          font-size: 13.1px;
          font-weight: 500;
          margin-top: -3px;
          margin-left: 10px;
          margin-right: 10px;
          color: black;
        }
        .address1,
        .address2 {
          margin: 3px auto;
          display: flex;
          font-size: 13px;
          @include customShadow();
          p {
            margin-right: 0px;
            margin-left: 0px;
          }
          @include customTransition();
        }
        .pin {
          display: flex;
          margin: 3px auto;
          @include customShadow();
          @include customTransition();

          font-size: 13px;
          @include lablStyle();
        }
        .post-office {
          display: flex;
          font-size: 13px;
          @include lablStyle();
          margin: 3px auto;
          @include customShadow();
          @include customTransition();
        }
        .city {
          display: flex;
          font-size: 13px;
          @include lablStyle();
          margin: 3px auto;
          @include customShadow();
          @include customTransition();
        }
        .district {
          display: flex;
          font-size: 13px;
          @include lablStyle();
          margin: 3px auto;
          @include customShadow();
          @include customTransition();
        }
        .state {
          display: flex;
          font-size: 13px;
          @include lablStyle();
          margin: 3px auto;
          @include customShadow();
          @include customTransition();
        }
        .country {
          display: flex;
          font-size: 13px;
          @include lablStyle();
          margin: 3px auto;
          @include customShadow();
          @include customTransition();
        }
        .phoneNo {
          display: flex;
          font-size: 13px;
          @include lablStyle();
          margin: 3px auto;
          @include customShadow();
          @include customTransition();
        }
      }
    }
  }
}

.edit-address {
  position: fixed;
  inset: 0;
  // background: rgba(0, 0, 0, 0.6);
  background-image: linear-gradient(
    to right top,
    #c06dc9,
    #8366b6,
    #4d5a98,
    #244974,
    #10364d
  );

  .cancelEdit {
    text-align: end;
    button {
      border: none;
      outline: none;
      font-size: 20px;
      color: red;
      font-weight: 600;
      margin: 20px 35px;
      background: transparent;
    }
  }

  .edit-address-section {
    height: 90%;
    width: 90%;
    margin: 5px auto;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 576px) {
      height: 90%;
      width: 96%;
      display: block;
    }

    @mixin widthCustom {
      width: 50%;
      margin: 6px;
      @media (max-width: 576px) {
        // height: 90%;
        width: 98%;
        margin: 10px auto;
      }
    }
    @mixin labelStyle {
      label {
        font-size: 14px;
        font-weight: 600;
        color: black;
        margin-bottom: 5px;
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }
    }
    @mixin inputStyle {
      input {
        float: right;
        margin-right: 80px;
        border: none;
        outline: none;
        border-radius: 4px;
        padding: 5px 10px;
        text-align: center;
        font-size: 12px;
        width: 55%;

        @media (max-width: 576px) {
          width: auto;
          margin-right: 4px;
          margin-left: 4px;
          margin-bottom: 12px;
        }
      }
    }
    @mixin textAreaStyle {
      textarea {
        width: 55%;

        float: right;
        margin-right: 80px;
        border: none;
        outline: none;
        resize: none;
        border-radius: 4px;
        padding: 5px 10px;
        text-align: center;
        font-size: 12px;
        @media (max-width: 576px) {
          width: auto;
          margin-right: 4px;
          margin-left: 4px;
          margin-bottom: 12px;
        }
      }
    }

    @mixin makeFlex {
      @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
      }
    }

    .name-section {
      @include widthCustom();
      @include inputStyle();
      @include labelStyle();
      @include makeFlex();
    }
    .address1-section {
      @include widthCustom();
      @include textAreaStyle();
      @include labelStyle();
      @include makeFlex();
    }
    .address2-section {
      @include widthCustom();
      @include textAreaStyle();
      @include labelStyle();
      @include makeFlex();
    }
    .pin-section {
      @include widthCustom();
      @include inputStyle();
      @include labelStyle();
      @include makeFlex();
    }
    .postoffice-section {
      @include widthCustom();
      @include inputStyle();
      @include labelStyle();
      @include makeFlex();
    }
    .city-section {
      @include widthCustom();
      @include inputStyle();
      @include labelStyle();
      @include makeFlex();
    }
    .District-section {
      @include widthCustom();
      @include inputStyle();
      @include labelStyle();
      @include makeFlex();
    }
    .State-section {
      @include widthCustom();
      @include inputStyle();
      @include labelStyle();
      @include makeFlex();
    }
    .Country-section {
      @include widthCustom();
      @include inputStyle();
      @include labelStyle();
      @include makeFlex();
    }
    .phoneNo-section {
      @include widthCustom();
      @include inputStyle();
      @include labelStyle();
      @include makeFlex();
    }

    .actionbtns {
      height: 100px;
      width: 100%;
      // background: red;
      text-align: center;
      margin: 30px auto;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      @media (max-width: 576px) {
        align-items: center;
      }

      button {
        height: 25px;
        width: 140px;
        font-size: 12px;
        font-weight: 500;
        border: none;
        outline: none;
        background: blue;
        color: white;
      }
    }
  }
}

.edit-status-modal {
  position: fixed;
  inset: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 1000;

  display: grid;
  place-content: center;

  p {
    color: white;
    font-size: 15px;
  }
}
</style>