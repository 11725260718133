<template>
  <div class="wrapper">
    <section class="heading-page">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2>sampriti publication</h2>
            <h6>
              A Half yearly bi-lingual(Assamese & English) Double-Blind Peer Reviewed
              National Journal on Humanitie & Social Sciences
            </h6>
          </div>
        </div>
      </div>
    </section>

    <section class="sampriti-info">
      <div class="row justify-content-center">
        <!-- <div class="col-lg-1"></div> -->
        <div class="col-lg-9">
          <div class="about-sampriti">
            <p>
              <span>Sampriti, ISSN: 2454-3837</span>, a national journal publishing double
              blind peer- reviewed research articles on Humanities and Social Sciences.
              Sampriti is bi-lingual and published in two languages viz. English and
              Assamese. All research works selected to be published are Double-Blind Peer
              Reviewed.
            </p>
            <br /><br />
            <p>
              The journal runs as a collective effort of an Honorary Advisory
              Board,Technical Review committee and an Editorial Board. For each issue —
            </p>
            <div class="row justify-content-start py-4">
              <div class="col-lg-7">
                <ol class="list-group list-group-numbered">
                  <li class="list-group-item selection-step">
                    The Research articles are reviewed by the Honorary Editorial Board at
                    first before forwarding the same for review to the Peer Team/Honorary
                    Technical Review committee. If necessary the same may be sent back to
                    the authors for revision.
                  </li>
                  <li class="list-group-item selection-step">
                    Certificates are sent to the concerned authors only after publication.
                  </li>
                </ol>
              </div>
            </div>
            <div class="row justify-content-start">
              <h6 id="objective-header">Our Objectives</h6>
              <div class="col-lg-7">
                <ol class="list-group list-group-numbered">
                  <li class="list-group-item selection-step">
                    To publish high quality research papers on Humanities and Social
                    Sciences.
                  </li>
                  <li class="list-group-item selection-step">
                    To publish original innovative and novel research articles.
                  </li>
                  <li class="list-group-item selection-step">
                    To develop a culture of Indian Stream’s Research Writing.
                  </li>
                  <li class="list-group-item selection-step">
                    Fast publication & Open Access.
                  </li>
                  <li class="list-group-item selection-step">
                    To build a standard Pan-India platform for Academics and students of
                    Humanities and Social Sciences.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-1"></div> -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "about-sampriti",
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .heading-page {
    background-image: url("@/assets/images/abt-banner.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    text-align: center;
    height: 450px;
    display: grid;
    place-content: center;

    h6 {
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 600;
      color: black;
      letter-spacing: 0.3px;
      text-shadow: 0px 5px 10px white;

      @media screen and (max-width: 576px) {
        font-size: 11.2px;
        letter-spacing: 0.1px;
        font-weight: 500;
      }
    }

    h2 {
      margin: 20px auto;
      font-size: 26px;
      text-transform: uppercase;
      font-weight: 900;
      color: whitesmoke;
      letter-spacing: 1px;
      text-shadow: 0px 5px 10px blue;

      @media screen and (max-width: 576px) {
        font-size: 20px;
      }
    }
  }

  .sampriti-info {
    background-image: url("@/assets/images/service_background.jpg");
    background-position: center;
    background-attachment: scroll;
    background-repeat: no-repeat;
    opacity: 0.85;
    background-size: cover;

    .about-sampriti {
      width: 100%;
      background-color: rgba(169, 3, 41, 1);
      font-weight: 600;
      border-radius: 5px;
      border-color: white;
      padding: 45px 25px;
      margin: 30px auto;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
        rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

      @media screen and (max-width: 576px) {
        margin: 10px auto;
        width: 95%;
      }

      .selection-step {
        font-size: 12.5px;
        line-height: 25px;
        font-weight: 600;
        color: white;
        border: none;
      }

      p {
        color: whitesmoke;
        font-size: 12.5px;

        span {
          font-weight: bold;
          font-size: 14.1px;
        }
      }
    }
  }

  #objective-header {
    font-size: 14px;
    color: white;
    font-weight: bold;
    text-decoration: underline;
    margin-left: 5px;
  }
}
</style>
