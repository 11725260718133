<template>
  <div class="wrapper">
    <h5>Order Summary Page</h5>

    <div class="order-id">
      <h6>
        Order Id: <b>{{ currentOrder.orderId }}</b>
      </h6>
    </div>

    <div class="cartproduct-information">
      <div class="item-table">
        <table>
          <tr>
            <th>Book Name</th>
            <th>Quantity</th>
            <th>Price</th>
          </tr>
          <tr v-for="(v, i) in orderedItems" :key="i">
            <td class="p-name">
              <p @click="goToBookDetail(v.itemId)">{{ v.bookName }}</p>
            </td>
            <td>
              <p>{{ v.quantity }}</p>
            </td>
            <td>
              <p>
                {{ Number(v.totalItemPrice).toFixed(2) }}
                <i class="fa-solid fa-indian-rupee-sign fa-sm"></i>
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="total-price">
      <p>
        Total Price: <b>{{ Number(totalCartPrice).toFixed(2) }}</b
        ><i class="fa-solid fa-indian-rupee-sign fa-sm"></i>
      </p>
    </div>

    <div class="customer-details">
      <div class="customer-name">
        <p class="heading"><b>Customer Name:</b></p>
        <p>{{ deliveryAddress.name }}</p>
      </div>
      <div class="customer-address">
        <h6><b>Delivery Address</b></h6>
        <p>
          name: <b>{{ deliveryAddress.name }}</b>
        </p>
        <p>
          vill/locality:
          <b>{{ deliveryAddress.address1 }},{{ deliveryAddress.address2 }}</b>
        </p>
        <p>
          PO: <b>{{ deliveryAddress.postoffice }}</b>
        </p>
        <p>
          PIN: <b>{{ deliveryAddress.pin }}</b>
        </p>
        <p>
          District: <b>{{ deliveryAddress.district }}</b>
        </p>
        <p>
          State: <b>{{ deliveryAddress.state }}</b>
        </p>
        <p>
          Country: <b>{{ deliveryAddress.country }}</b>
        </p>
        <p>
          Country: <b>{{ deliveryAddress.phoneNo }}</b>
        </p>
      </div>
    </div>
    <div class="confirm-btn">
      <button @click="createTemporaryOrder">Confirm Order</button>
    </div>
  </div>
</template>

<script>
//import the shopping cart store
import { shoppingCartStore } from "@/stores/shopping-cart-store";

//bookstore

import { onMounted, ref } from "@vue/runtime-core";
import { uploadBooks } from "@/stores/upload-book";
import router from "@/router";
import { bookOrderCollection } from "@/stores/bookorder-collection";

export default {
  name: "order-summary",
  setup() {
    //Order collection Instance
    const orderCollection = bookOrderCollection();

    //get the book store instance
    const books = uploadBooks();

    //shopping cart instance
    const shoppingCart = shoppingCartStore();

    //toast instance

    function goToBookDetail(id) {
      books.uploadedBooks.forEach((book) => {
        if (book.id === id) {
          books.setSelectedIndex(books.uploadedBooks.indexOf(book));
        }
      });
      router.push({
        name: "book-view",
      });
    }

    //create the order object
    function confirmOrder() {
      console.log(shoppingCart.currentOrderObject);
    }

    //Order object
    const currentOrder = ref("");

    //address object
    const deliveryAddress = ref("");

    //cartItems
    const orderedItems = ref([]);

    //total cart price
    const totalCartPrice = ref("");

    //get the order object when mounted
    onMounted(() => {
      currentOrder.value = shoppingCart.currentOrderObject;

      // console.log(currentOrder.value);

      deliveryAddress.value = currentOrder.value.deliveryAddress;
      orderedItems.value = currentOrder.value.orderedItemsList;
      totalCartPrice.value = shoppingCart.getCartPrice;
      // console.log(orderedItems.value);
    });

    //create a new complete order
    function createTemporaryOrder() {
      const status = orderCollection.temporaryOrder(
        shoppingCart.currentOrderObject
      );
      // orderCollection.$reset();

      if (status) {
        router.push({
          name: "payment-for-order",
        });
      }
    }

    return {
      goToBookDetail,
      totalCartPrice,
      orderedItems,
      confirmOrder,
      currentOrder,
      deliveryAddress,
      createTemporaryOrder,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  padding: 20px;
  background: linear-gradient(
    to right top,
    #051937,
    #004d7a,
    #008793,
    #00bf72,
    #a8eb12
  );

  @media (max-width: 400px) {
    padding: 20px 2.5px;
  }

  h5 {
    text-align: center;
    font-size: 16px;
    margin: 30px auto;
    font-weight: 600;
    text-decoration: underline;
  }

  .order-id {
    // background: yellow;
    padding: 0px 0px 0px 25px;
    h6 {
      font-size: 14px;
      margin: 20px 1px;
      color: white;

      b {
        padding: 0px 7px;
        font-size: 12px;
        color: whitesmoke;
      }
    }
  }
  .cartproduct-information {
    height: auto;
    // background: red;
    width: 100%;

    .item-table {
      width: 100%;
      height: auto;
      margin: 30px auto;
      padding: 15px;
      color: white;
      table {
        th {
          width: 33vw;
          height: 28px;
          border: 1px solid white;
          text-align: center;
          font-size: 14.5px;
          font-weight: 500;
        }
        td {
          text-align: center;
          padding: 6px auto;
          border: 1px solid white;
          height: 35px;
          p {
            font-size: 13px;
            font-weight: 500;
            color: white;
          }
        }
        .p-name {
          cursor: pointer;
          p {
            &:hover {
              color: yellow;
              text-decoration: underline;
              font-size: 13.2px;
            }
          }
        }
      }
    }
  }

  .total-price {
    margin: 30px 20px;
    p {
      text-align: right;
      font-size: 13.5px;
      color: white;

      b {
        margin: 0px 5px;
      }
    }
  }

  .customer-details {
    margin: 30px 20px;

    .customer-name {
      display: flex;
      margin: 30px auto;

      .heading {
        font-size: 14px;
        color: white;
        @media (max-width: 500px) {
          font-size: 12.1px;
          letter-spacing: 0.15px;
        }
      }

      p {
        font-size: 13px;
        color: white;
        padding: 0px 10px;
        font-weight: 500;
      }
    }

    .customer-address {
      h6 {
        font-size: 14px;
        color: white;

        @media (max-width: 500px) {
          font-size: 12.1px;
          margin-top: 6px;
          letter-spacing: 0.15px;
        }
      }

      p {
        font-size: 13px;
        margin: 10px;
        letter-spacing: 0.1px;
        color: white;

        @media (max-width: 500px) {
          font-size: 12px;
          letter-spacing: 0.15px;
        }
      }
    }
  }
  .confirm-btn {
    height: 100px;
    // background: purple;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      font-size: 13px;
      padding: 3px 21px;
      border: none;
      outline: none;
      font-weight: 500;
      border-radius: 7px;
    }
  }
}
</style>