<template>
  <div>
    <div class="wrapper">
      <div class="cancel">
        <button @click="removeCartItem">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div class="first-section">
        <div class="photo-name">
          <div class="name">
            <p>{{ cartItem.bookName }}</p>
          </div>
          <div class="image">
            <img :src="cartItem.imageUrl" />
          </div>
          <div class="author">
            <p><b>Author:</b> {{ cartItem.authorName }}</p>
          </div>
        </div>
        <div class="other-info">
          <div class="quantity">
            <p>Quantity:</p>
            <!-- <p style="color:red">{{cartItem.quantitiy}}</p> -->
            <select
              v-model="quantity"
              @change="
                createCartItem(
                  cartItem.id,
                  quantity,
                  cartItem.currentPrice,
                  cartItem.bookName
                ),
                  getPricenQuantity();
                getIndividualPrice();
              "
            >
              <!-- <select v-model="quantity" @change="resetStore" > -->
              <option v-for="(v, i) in 10" :key="i">{{ v }}</option>
            </select>
          </div>
          <div class="best-price">
            <label>Current Price:</label>
            <p id="cPrice">
              {{ cartItem.currentPrice }}
              <i class="fa-solid fa-indian-rupee-sign fa-xs"></i>
            </p>
          </div>
          <div class="discount">
            <label>Discount Applied:</label>

            <p>{{ cartItem.discount }}%</p>
          </div>
          <div class="mrp">
            <label>MRP:</label>
            <p>
              {{ cartItem.mrp }}
              <i class="fa-solid fa-indian-rupee-sign fa-xs"></i>
            </p>
          </div>
        </div>
      </div>
      <div class="second-section">
        <p>
          Item Price:
          <b v-if="itemPrice !== '0.00'"
            >{{ itemPrice }} <i class="fa-solid fa-indian-rupee-sign fa-xs"></i
          ></b>
          <b v-else>
            {{ initialItemPrice }}
            <i class="fa-solid fa-indian-rupee-sign fa-xs"></i>
          </b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";

import { shoppingCartStore } from "@/stores/shopping-cart-store";
import { onMounted } from "@vue/runtime-core";

export default {
  name: "shopping-cart-item",
  props: {
    cartItem: Object,
  },
  emits: ["removeItem"],
  setup(props, { emit }) {
    //shopping cart instance
    const shoppingCart = shoppingCartStore();

    onMounted(
      ()=>{
        console.log(
          props.cartItem
        );
      }
    )



    function removeCartItem() {
      emit("removeItem");
    }

    //creating individual cart item
    function createCartItem(id, quantitiy, currentPrice, bookName) {
      shoppingCart.createIndividualCartItem(
        id,
        quantitiy,
        currentPrice,
        bookName
      );
    }

    //function to reset store
    function resetStore() {
      shoppingCart.$reset();
      console.log("reset done");
    }

    //quantitiy value
    const quantity = ref("");

    const initialItemPrice = ref("");

    onMounted(() => {
      if (shoppingCart.cartItems.length === 0) {
        initialItemPrice.value = props.cartItem.currentPrice;
        quantity.value = 1;
      } else {
        shoppingCart.cartItems.forEach((e) => {
          if (props.cartItem.id === e.itemId) {
            quantity.value = e.quantity;
            initialItemPrice.value =
              props.cartItem.currentPrice * quantity.value;
          }
        });
      }
    });

    function getPricenQuantity() {
      // console.log('running from item');

      shoppingCart.calculatePricenQuantity();
    }

    //total item price
    const itemPrice = ref("");

    //start from here with modifying the quantity value

    function getIndividualPrice() {
      var value = shoppingCart.getIndiviualItemPrice(
        quantity.value,
        props.cartItem.currentPrice
      );
      itemPrice.value = Number(value).toFixed(2);
    }

    onMounted(() => {
      var value = shoppingCart.getIndiviualItemPrice(
        quantity.value,
        props.cartItem.currentPrice
      );
      itemPrice.value = Number(value).toFixed(2);
    });

    return {
      getIndividualPrice,
      quantity,
      itemPrice,
      shoppingCart,
      createCartItem,
      resetStore,
      getPricenQuantity,
      initialItemPrice,
      removeCartItem,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin commonStyle {
  font-size: 13px;
  color: white;
  letter-spacing: 0.4px;
  margin: auto 20px;
  font-weight: 600;
  @media (max-width: 576px) {
    font-size: 11px;
    margin: auto 5px;
  }
}

.wrapper {
  height: 280px;
  width: 70%;
  background-color: rgba($color: #000000, $alpha: 0.6);
  margin: 50px auto;
  padding: 10px;

  @media (max-width: 930px) {
    width: 100%;
    // height: auto;
  }
  @media (max-width: 600px) {
    width: 100%;
    height: auto;
  }

  .cancel {
    text-align: end;
    button {
      border: none;
      outline: none;
      background: transparent;
      color: red;
    }
  }

  .first-section {
    display: flex;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

    @media (max-width: 576px) {
      flex-direction: column;
    }
    .photo-name {
      flex: 1;
      cursor: pointer;
      .name {
        p {
          font-size: 14px;
          font-weight: 600;
          margin: auto 10px;
          width: 290px;
          color: white;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          @media (max-width: 300px) {
            width: 240px;
            font-size: 12.5px;
          }
        }
      }
      .image {
        padding: 10px;
        img {
          border-radius: 5px;
          height: 160px;
          width: 90%;
          @media (max-width: 576px) {
            width: 98%;
          }
        }
      }
      .author {
        p {
          font-size: 13px;
          font-weight: 500;
          margin: auto 10px;
          width: 290px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: white;

          @media (max-width: 300px) {
            width: 240px;
            font-size: 11.5px;
          }
        }
      }
    }
    .other-info {
      flex: 2;
      padding: 20px 30px;

      @media (max-width: 576px) {
        padding: 20px 2px;
        // background: red;
      }

      .quantity {
        display: flex;
        margin: 24px;
        @media (max-width: 576px) {
          margin: 10px;
          // background: #000000;
          justify-content: space-between;
        }
        p {
          @include commonStyle();
        }
        select {
          font-size: 13px;
          color: black;
          padding: 3px 10px;
          border: none;
          outline: none;
          cursor: pointer;
          border-radius: 5px;
          @media (max-width: 576px) {
            font-size: 11px;
            height: 20px;
          }
        }
      }
      .best-price,
      .discount,
      .mrp {
        display: flex;
        margin: 20px;

        @media (max-width: 576px) {
          margin: 10px;
          // background: #000000;
          justify-content: space-between;
        }

        label {
          @include commonStyle();
        }
        p {
          font-size: 13px;
          color: white;
          @media (max-width: 576px) {
            font-size: 11px;
          }
        }
      }
    }
  }
  .second-section {
    height: 40px;
    width: 100%;
    background: transparent;
    text-align: end;
    p {
      font-size: 13px;
      letter-spacing: 0.4px;
      padding-right: 25px;
      padding-top: 4px;
      font-weight: 500;
      color: white;
    }
  }
}
</style>