import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { defineStore } from "pinia";
import { db } from "@/firebase/firebase-init.js";

export const trackChangesInTheDatabase = defineStore("ChangesInTheDatabase", {
  state: () => ({
    galleryChangeObject: Object,
    sampritiIssueChangeObject: Object,
    bookChangeObject: Object,
    postChangeObject: Object,
  }),
  actions: {
    //function to create a new book
    async addNewDatabaseChange(collectionType, timeObject) {
      if (collectionType === "post") {
        const querySnapshot = await getDocs(
          collection(db, "changeInPostCollection")
        );
        var targetId = "";
        var isDeleted = false;
        var emptyPost = false;

        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            targetId = doc.id;
          });
          const addressRef = doc(db, "changeInPostCollection", targetId);
          await deleteDoc(addressRef).then(() => {
            isDeleted = true;
            // console.log("is delete is true");
          });
        } else {
          emptyPost = true;
        }

        if (isDeleted || emptyPost) {
          // console.log("empty previous post change");
          await addDoc(
            collection(db, "changeInPostCollection"),
            timeObject
          );
        }
      } else if (collectionType === "issue") {
        const querySnapshot = await getDocs(
          collection(db, "changeInSampritiIssueCollection")
        );
        var itargetId = "";
        var issueDeleted = false;
        var emptyIssue = false;

        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            itargetId = doc.id;
          });
          const addressRef = doc(
            db,
            "changeInSampritiIssueCollection",
            itargetId
          );
          await deleteDoc(addressRef).then(() => {
            issueDeleted = true;
          });
        } else {
          emptyIssue = true;
        }

        if (issueDeleted || emptyIssue) {
          await addDoc(
            collection(db, "changeInSampritiIssueCollection"),
            timeObject
          );
        }
      } else if (collectionType === "book") {
        const querySnapshot = await getDocs(
          collection(db, "changeInBookCollection")
        );
        var bookTargetId = "";
        var bookDeleted = false;
        var emptyBook = false;

        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            bookTargetId = doc.id;
          });
          const addressRef = doc(db, "changeInBookCollection", bookTargetId);
          await deleteDoc(addressRef).then(() => {
            bookDeleted = true;
          });
        } else {
          emptyBook = true;
        }

        if (bookDeleted || emptyBook) {
          await addDoc(
            collection(db, "changeInBookCollection"),
            timeObject
          );
        }
      } else {
        const querySnapshot = await getDocs(
          collection(db, "changeInPhotoGallery")
        );
        var photoDocId = "";
        var photoDeleted = false;
        var emptyPhotoChange = false;

        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            photoDocId = doc.id;
          });
          const addressRef = doc(db, "changeInPhotoGallery", photoDocId);
          await deleteDoc(addressRef).then(() => {
            photoDeleted = true;
          });
        } else {
          emptyPhotoChange = true;
        }

        if (photoDeleted || emptyPhotoChange) {
          await addDoc(collection(db, "changeInPhotoGallery"), timeObject);
        }
      }
    },

    //extract all posts from firebase
    async checkChangeInDatabase(dataType) {
      //for change in post collection
      if (dataType === "post") {
        const querySnapshot = await getDocs(
          collection(db, "changeInPostCollection")
        );

        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            this.postChangeObject = doc.data();
          });
        } else {
          this.postChangeObject = null;
        }
      }
      //for change in sampriti issue collection
      if (dataType === "issue") {
        const querySnapshot = await getDocs(
          collection(db, "changeInSampritiIssueCollection")
        );

        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            this.sampritiIssueChangeObject = doc.data();
          });
          // console.log(this.sampritiIssueChangeObject);
        } else {
          this.sampritiIssueChangeObject = null;
        }
      }
      //for change in book collection
      if (dataType === "book") {
        const querySnapshot = await getDocs(
          collection(db, "changeInBookCollection")
        );

        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            this.bookChangeObject = doc.data();
          });

          // console.log(this.bookChangeObject);
        } else {
          this.bookChangeObject = null;
        }
      }
      //for change in gallery collection
      if (dataType === "gallery") {
        const querySnapshot = await getDocs(
          collection(db, "changeInPhotoGallery")
        );

        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            this.galleryChangeObject = doc.data();
          });
          // console.log(this.galleryChangeObject);
        } else {
          this.galleryChangeObject = null;
        }
      }
    },
  },
  persist: false,
});
