<template>
  <div class="transaction">
    <p id="tranTime"><b>22 Jul 2023</b></p>

    <h6>Transaction ID: 45321</h6>
    <p>Related Order ID: <b>342</b></p>
    <p>Order Status:<b>Processed</b></p>

    <p id="money">
      Money Received: 45644 <i class="fa-solid fa-indian-rupee-sign fa-sm"></i>
    </p>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.transaction {
  height: auto;
  width: 90%;
  background-color: #e7e5e4;
  border-radius: 10px;
  border: 1px solid white;
  margin: 35px auto;
  padding: 10px 30px;
  cursor: pointer;
  #tranTime {
    text-align: end;
    color: black;
    text-shadow: 2px 2px 2px rgba(206, 89, 55, 0);
  }

  h6 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 25px;
    text-decoration: underline;
  }

  p {
    font-size: 13px;
    font-weight: 500;
  }

  #money {
    font-weight: 600;
    font-size: 14px;
  }
}
</style>
