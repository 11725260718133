import { db } from "@/firebase/firebase-init";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { defineStore } from "pinia";
import { v4 as uid } from "uuid";

const storage = getStorage();

export const allUploadedFilesStorageRefCollection = defineStore(
  "StorageRefCollection",
  {
    state: () => ({
      temporaryCollection: [],
      FilesStorageRefCollection: [],
      selectedFileUrl: "",
    }),
    getters: {},
    actions: {
      //function to create a new book
      newStorageRefObject(member) {
        this.temporaryCollection.push({
          id: uid(),
          ...member,
        });
      },

      //finally storing the reference objects in firebase
      async finalSubmissionIntoFirebase() {
        // Add a new document in collection "allAddresses"

        this.temporaryCollection.forEach(async (x) => {
          await addDoc(collection(db, "FilesStorageRefCollection"), x).then(
            () => {
              this.FilesStorageRefCollection.push(x);
            }
          );
        });
        // toast.success("New post added");
        console.log("strg ref added");
      },

      //find fileStorageRef
      async findFileStorageRef(givenUrl) {
        if (givenUrl === null) {
          return;
        }

        const querySnapshot = await getDocs(
          collection(db, "FilesStorageRefCollection")
        );
        if (querySnapshot !== null) {
          this.temporaryCollection = [];
          querySnapshot.forEach((doc) => {
            if (doc.exists) {
              this.temporaryCollection.push(doc.data());
            }
          });

          if (this.temporaryCollection.length > 0) {
            var target = this.temporaryCollection.filter((item) => {
              if (item.url === givenUrl) {
                return item;
              }
            });
            if (target.length !== 0) {
              return target[0].storageRef;
            } else {
              return null;
            }
          }
        }
      },

      //search item via id
      async deleteFileFromFirebase(strgeRef) {
        if (strgeRef !== null) {
          // Create a reference to the file to delete
          const desertRef = ref(storage, strgeRef);

          // Delete the file
          deleteObject(desertRef)
            .then(() => {
              // File deleted successfully
              this.temporaryCollection = this.temporaryCollection.filter(
                (x) => x.storageRef !== strgeRef
              );
              console.log("deleted");
            })
            .catch((error) => {
              // Uh-oh, an error occurred!
              console.log(error);
            });
        }
      },
    },
    persist: true,
  }
);
