import { addDoc, deleteDoc, doc, getDocs } from "firebase/firestore";
import { defineStore } from "pinia";
import { v4 as uid } from "uuid";

import { useToast } from "vue-toastification";

import { createCollectionReference } from "@/helper-functions/create-collection-reference";

import { userCreation } from "@/stores/user-verification";

const toast = useToast();

export const shoppingCartStore = defineStore("shoppingCart", {
  state: () => ({
    cartItems: [],
    itemIdCollection: [],
    overallCartPrice: String,
    itemPrice: String,
    totalCartQuantity: String,
    currentOrderObject: Object,
    downloadedQuerySnapshot: Object,
  }),
  getters: {
    getAllCartItems: (state) => {
      return state.cartItems;
    },
    getCartPrice: (state) => {
      return state.overallCartPrice;
    },
    getCartQuantity: (state) => {
      return state.totalCartQuantity;
    },
  },
  actions: {
    async collectItemIds(itemId) {
      if (!userCreation().isLoggedIn) {
        toast.info("Please LogIn/SignUp to continue!");
        return;
      } else {
        // console.log(itemId);
        if (itemId.length !== 0) {
          //collection reference

          var collectionReference = await createCollectionReference(
            "cartItemIdCollection"
          );

          if (!this.itemIdCollection.includes(itemId)) {
            //adding to local id collection store
            this.itemIdCollection.push(itemId);
            // console.log(this.itemIdCollection);
            toast.success("Added to Cart!");

            const idObject = {
              label: "bookId",
              id: itemId,
            };
            var duplicateFirebaseEntry = false;
            if (collectionReference !== null) {
              const querySnapshot = await getDocs(collectionReference);

              console.log(querySnapshot);

              if (querySnapshot !== null) {
                querySnapshot.forEach((doc) => {
                  if (doc.data().id === itemId) {
                    console.log("duplicate exists");
                    duplicateFirebaseEntry = true;
                    return;
                  }
                });
                if (!duplicateFirebaseEntry) {
                  await addDoc(collectionReference, idObject).then(() => {
                    console.log("added to backend id collection");
                  });
                }
              }
            }
          } else {
            toast.info("Already exists in the Cart!");
          }
        }
      }
    },

    //extract itemid collection from the firebase
    async extractItemIdCollectionFromFirebase() {
      if (!userCreation().isLoggedIn) {
        toast.info("You need to LogIn/SignUp first! ");
        return;
      } else {
        var collectionReference = await createCollectionReference(
          "cartItemIdCollection"
        );

        if (collectionReference !== null) {
          const querySnapshot = await getDocs(collectionReference);
          if (!querySnapshot.empty) {
            // this.downloadedQuerySnapshot = querySnapshot;
            querySnapshot.forEach((doc) => {
              if (!this.itemIdCollection.includes(doc.data().id)) {
                this.itemIdCollection.push(doc.data().id);
              }
            });
          } else {
            console.log("querysnapshot empty");
          }
        }
      }
    },

    //function to create individual cart items
    async createIndividualCartItem(
      itemId,
      quantity,
      currentItemPrice,
      productName
    ) {
      var totalItemPrice = "";
      if (quantity && currentItemPrice) {
        totalItemPrice = Number(quantity) * Number(currentItemPrice);
      }

      const cartItem = {
        itemId: itemId,
        quantity: quantity,
        totalItemPrice: totalItemPrice,
        bookName: productName,
      };
      // console.log(cartItem);

      //array to hold the itemIds
      var idCollection = [];

      //push items if the cart is empty
      if (this.cartItems.length === 0) {
        this.cartItems.push(cartItem);

        var duplicateFirebaseEntry;

        //collection reference
        var collectionReference = await createCollectionReference(
          "shoppingCartItemsOfUser"
        );

        if (collectionReference !== null) {
          const querySnapshot = await getDocs(collectionReference);
          // console.log(querySnapshot);
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              if (doc.data().itemId === itemId) {
                duplicateFirebaseEntry = true;
                return;
              }
            });
          }
          if (!duplicateFirebaseEntry) {
            await addDoc(collectionReference, cartItem).then(() => {
              toast.success("New cart item added");
            });
          }
        }
      } else {
        for (const item in this.cartItems) {
          var id = this.cartItems[item].itemId;
          if (!idCollection.includes(id)) {
            idCollection.push(id);
          }
        }

        if (idCollection.length !== 0) {
          var filteredItems = this.cartItems.filter(
            (item) => item.itemId !== itemId
          );

          filteredItems.push(cartItem);
          this.cartItems = filteredItems;

          console.log(this.cartItems);

          //collection reference
          var collectionReference2 = await createCollectionReference(
            "shoppingCartItemsOfUser"
          );
          var duplicate = false;

          console.log("running here");

          if (collectionReference2 !== null) {
            const querySnapshot = await getDocs(collectionReference2);
            var docRefId = "";
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {
                if (doc.data().itemId === itemId) {
                  duplicate = true;
                  docRefId = doc.id;
                  return;
                }
              });
            }
            if (!duplicate) {
              await addDoc(collectionReference2, cartItem).then(() => {
                toast.success(" item added");
              });
            } else {
              if (docRefId.length !== 0) {
                await deleteDoc(doc(collectionReference2, docRefId));
                await addDoc(collectionReference2, cartItem);
              }
            }
          }
        }
      }
    },

    //run this function in onmounted hook get the updated cartItems from the firebase
    async updateCartItemFromFirebase() {
      var extractedItems = [];

      //collection reference
      var collectionReference = await createCollectionReference(
        "shoppingCartItemsOfUser"
      );

      if (collectionReference !== null) {
        const querySnapshot = await getDocs(collectionReference);

        if (!querySnapshot.empty) {
          // this.downloadedQuerySnapshot = querySnapshot;
          querySnapshot.forEach((doc) => {
            extractedItems.push(doc.data());
          });

          // console.log(extractedItems);

          var extractedIdFromLocalCartItems = [];

          this.cartItems.forEach((v) => {
            if (!extractedIdFromLocalCartItems.includes(v.itemId)) {
              extractedIdFromLocalCartItems.push(v.itemId);
            }
          });

          extractedItems.forEach((item) => {
            if (!this.itemIdCollection.includes(item.itemId)) {
              this.itemIdCollection.push(item.itemId);
              if (!extractedIdFromLocalCartItems.includes(item.itemId)) {
                this.cartItems.push(item);
              }
            }
          });
        }
      }
    },

    //get individual item price
    getIndiviualItemPrice(quantity, currentItemPrice) {
      var totalItemPrice = "";
      if (quantity && currentItemPrice) {
        totalItemPrice = Number(quantity) * Number(currentItemPrice);
      }
      return totalItemPrice;
    },

    //function to calculate total cart price and quantity
    calculatePricenQuantity() {
      console.log("calculating");

      var price = 0;
      var quantity = 0;
      for (const key in this.cartItems) {
        var curedDate = JSON.parse(JSON.stringify(this.cartItems[key]));
        //all properties are accessible from the object now
        // console.log(curedDate);
        price += curedDate.totalItemPrice;
        quantity += Number(curedDate.quantity);
      }
      this.overallCartPrice = price;
      this.totalCartQuantity = quantity;
    },

    setOverallCartPrice(price) {
      this.overallCartPrice = price;
    },
    setTotalCartQuantity(quantity) {
      this.totalCartQuantity = quantity;
    },

    //remove cart item
    async removeCartItem(id) {
      //collection reference
      var collectionReference = await createCollectionReference(
        "shoppingCartItemsOfUser"
      );

      if (collectionReference !== null) {
        const querySnapshot = await getDocs(collectionReference);
        var targetId = "";
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            if (doc.data().itemId === id) {
              targetId = doc.id;
            }
          });
        }
        if (targetId.length !== 0) {
          await deleteDoc(doc(collectionReference, targetId));
        }
        this.removeFromIdCollection(id);
        if (this.cartItems.length !== 0) {
          var itemToRemove = this.cartItems.filter(
            (item) => item.itemId === id
          );
          var removeQuantity = itemToRemove[0].quantity;
          this.totalCartQuantity = this.totalCartQuantity - removeQuantity;
          var removeAmount = itemToRemove[0].totalItemPrice;
          this.overallCartPrice = this.overallCartPrice - removeAmount;
          var filteredItems = this.cartItems.filter(
            (item) => item.itemId !== id
          );
          this.cartItems = filteredItems;
        }
      }
    },

    //remove from idcollection

    async removeFromIdCollection(id) {
      if (id.length === 0) {
        console.log("empty id");
        return;
      }

      var filtered = this.itemIdCollection.filter((i) => i !== id);
      this.itemIdCollection = filtered;

      //collection reference
      var collectionReference = await createCollectionReference(
        "cartItemIdCollection"
      );

      if (collectionReference !== null) {
        const querySnapshot = await getDocs(collectionReference);
        var targetId = "";

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            if (doc.data().id === id) {
              targetId = doc.id;
            }
          });
          // console.log("target id from remove id collec", targetId);
          if (targetId.length !== 0) {
            await deleteDoc(doc(collectionReference, targetId));
          }
        }
      }
    },

    //create order object
    createOrderObject(address) {
      const orderObject = {
        orderId: uid(),
        orderedItemsList: this.cartItems,
        deliveryAddress: address,
      };
      this.currentOrderObject = orderObject;
    },

    //remove the orderList
    removeOrderList() {
      this.currentOrderObject = null;
    },

    //delete cartItems and id collection from backend on receiving
    // a complete order

    async deleteIdCollectionNshoppingCartItems() {
      var backendIdCollections = [];
      //get all id s from backend

      //collection reference
      var collectionReference = await createCollectionReference(
        "cartItemIdCollection"
      );

      if (collectionReference !== null) {
        const querySnapshot = await getDocs(collectionReference);

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            if (!backendIdCollections.includes(doc.id)) {
              backendIdCollections.push(doc.id);
            }
          });

          if (backendIdCollections.length !== 0) {
            backendIdCollections.forEach(async (id) => {
              await deleteDoc(doc(collectionReference, id));
            });
          }
        }
      }

      //get all cart items from the backend
      //collection reference
      var collectionReference2 = await createCollectionReference(
        "shoppingCartItemsOfUser"
      );
      if (collectionReference2 !== null) {
        const cartItemsSnapshot = await getDocs(collectionReference2);
        var backendCartItemCollection = [];
        cartItemsSnapshot.forEach((doc) => {
          if (!backendCartItemCollection.includes(doc.id)) {
            backendCartItemCollection.push(doc.id);
          }
        });
        if (backendCartItemCollection.length !== 0) {
          backendCartItemCollection.forEach(async (id) => {
            await deleteDoc(doc(collectionReference2, id));
          });
        }
      }
    },
  },
  persist: false,
});
