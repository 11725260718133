<template>
  <div class="wrapper">
    <h6 id="page-heading"><b>Order Details</b></h6>
    <div class="order-id">
      <label>Order Id:</label>
      <p>{{ selectedOrder.orderId }}</p>
    </div>
    <div class="order-date">
      <label>Order Date:</label>
      <p>{{ selectedOrder.orderCreateTime }}</p>
    </div>
    <div class="order-date">
      <label>Expected Delivery Date:</label>
      <p>{{ selectedOrder.expectedDeliveryTime }}</p>
    </div>
    <div class="order-status">
      <label>Order Status:</label>
      <p>{{ selectedOrder.orderStatus }}</p>
    </div>
    <div class="order-status">
      <label>Payment Status:</label>
      <p>{{ selectedOrder.paymentStatus }}</p>
    </div>
    <div class="delivery-status">
      <label>Delivery Status:</label>
      <p>{{ selectedOrder.deliveryStatus }}</p>
    </div>
    <div class="no-of-items-ordered">
      <label>Total Items ordered:</label>
      <p>{{ totalNoOfItems }}</p>
    </div>
    <div class="item-table">
      <table>
        <tr>
          <th>Item Name</th>
          <th>Quantity</th>
          <th>Price</th>
        </tr>
        <tr v-for="(v, i) in selectedOrder.orderedItemsList" :key="i">
          <td>
            <p>{{ v.bookName }}</p>
          </td>
          <td>
            <p>{{ v.quantity }}</p>
          </td>
          <td>
            <p>
              {{ Number(v.totalItemPrice).toFixed(2) }}
              <i class="fa-solid fa-indian-rupee-sign fa-sm"></i>
            </p>
          </td>
        </tr>
      </table>
    </div>
    <div class="total-price">
      <label>Total Price</label>
      <p>
        {{ Number(totalPrice).toFixed(2)
        }}<i class="fa-solid fa-indian-rupee-sign fa-sm"></i>
      </p>
    </div>
    <div class="customer-details">
      <div class="customer-name">
        <h6><b>Customer Name:</b></h6>
        <p>{{ deliveryAddress.name }}</p>
      </div>
      <div class="customer-address">
        <h6><b>Delivery Address</b></h6>
        <p>
          name: <b>{{ deliveryAddress.name }}</b>
        </p>
        <p>
          vill/locality:
          <b>{{ deliveryAddress.address1 }}{{ deliveryAddress.address2 }}</b>
        </p>
        <p>
          PO: <b>{{ deliveryAddress.postoffice }}</b>
        </p>
        <p>
          PIN: <b>{{ deliveryAddress.pin }}</b>
        </p>
        <!-- <p>PS: <b>Mangaldai</b></p> -->
        <p>
          District: <b>{{ deliveryAddress.district }}</b>
        </p>
        <p>
          State: <b>{{ deliveryAddress.state }}</b>
        </p>
        <p>
          Country: <b>{{ deliveryAddress.country }}</b>
        </p>
        <p>
          Country: <b>{{ deliveryAddress.phoneNo }}</b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { bookOrderCollection } from "@/stores/bookorder-collection";

export default {
  name: "full-order-view",

  setup() {
    //get the book order collection instance
    const orderCollection = bookOrderCollection();

    //selected order
    const selectedOrder = ref("");

    //delivery Address
    const deliveryAddress = ref("");

    //total no of items
    const totalNoOfItems = ref("");

    //total Order Price
    const totalPrice = ref("");

    onMounted(() => {
      selectedOrder.value = orderCollection.currentSelectedOrderForViewing;
      deliveryAddress.value = selectedOrder.value.deliveryAddress;
      var quantity = 0;
      var price = 0;
      selectedOrder.value.orderedItemsList.forEach((x) => {
        quantity += Number(x.quantity);
        price += Number(x.totalItemPrice);
      });
      totalNoOfItems.value = quantity;
      totalPrice.value = price;
    });
    return { selectedOrder, deliveryAddress, totalNoOfItems, totalPrice };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to right top,
    #051937,
    #04234f,
    #042e67,
    #0a3881,
    #16439b
  );
  padding: 30px 40px;

  @media (max-width: 576px) {
    padding: 30px 7px;
  }

  #page-heading {
    color: white;
    font-size: 15px;
    text-decoration: underline;
    text-align: center;
  }

  .order-id,
  .order-date,
  .order-status,
  .delivery-status,
  .no-of-items-ordered {
    display: flex;
    margin: 20px 25px;
    label {
      color: white;
      font-size: 14px;
      margin: auto 10px;
    }
    p {
      color: white;
      font-size: 13px;
      font-weight: 600;
    }
  }
  .item-table {
    width: 100%;
    height: auto;
    margin: 30px auto;
    padding: 15px;
    color: white;
    // background: yellow;
    table {
      th {
        width: 33vw;
        height: 26px;
        border: 1px solid white;
        text-align: center;
        font-size: 14.5px;
        font-weight: 500;
      }
      td {
        text-align: center;
        padding: 6px auto;
        border: 1px solid white;
        height: 28px;
        p {
          font-size: 13px;
          font-weight: 500;
          color: white;
        }
      }
    }
  }
  .total-price {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
    font-size: 14px;
    p {
      color: white;
      margin: auto 20px;
      font-weight: 600;
      font-size: 13px;
    }
  }

  .customer-details {
    margin: 30px 20px;

    .customer-name {
      display: flex;
      margin: 30px auto;

      h6 {
        font-size: 14px;
        color: white;
        @media (max-width: 500px) {
          font-size: 12.1px;
          margin-top: 10px;
          letter-spacing: 0.15px;
        }
      }

      p {
        font-size: 13px;
        margin: auto 15px;
        color: white;
      }
    }

    .customer-address {
      h6 {
        font-size: 14px;
        color: white;

        @media (max-width: 500px) {
          font-size: 12.1px;
          margin-top: 6px;
          letter-spacing: 0.15px;
        }
      }

      p {
        font-size: 13px;
        margin: 10px;
        letter-spacing: 0.1px;
        color: white;

        @media (max-width: 500px) {
          font-size: 12px;
          letter-spacing: 0.15px;
        }
      }
    }
  }
}
</style>