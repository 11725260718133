import { db } from "@/firebase/firebase-init";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { defineStore } from "pinia";
import { v4 as uid } from "uuid";
import { useToast } from "vue-toastification";
import { trackChangesInTheDatabase } from "@/stores/track-changed-collections-by-admin";

const toast = useToast();

export const photoAlbumCollection = defineStore("photoAlbum", {
  state: () => ({
    albumCollection: [],
    selectedAlbum: Number,
    LastSnapshotUpdateTime: [],
  }),
  getters: {
    getPost: (state) => {
      return state.albumCollection;
    },
  },
  actions: {
    //create a new album
    async createNewAlbum(album) {
      var albm = {
        id: uid(),
        ...album,
      };

      if (this.albumCollection.length === 0) {
        this.albumCollection.push(albm);

        await addDoc(collection(db, "allAlbums"), albm).then(() => {
          toast.success("New Album uploaded");
        });

        var timeObj = new Date();
        var LastSnapshotUpdateObject = {
          date: timeObj.getDate(),
          time: timeObj.getTime(),
          month: timeObj.getMonth(),
          year: timeObj.getFullYear(),
        };
        await trackChangesInTheDatabase().addNewDatabaseChange(
          "gallery",
          LastSnapshotUpdateObject
        );
      } else {
        var allIds = [];
        this.albumCollection.forEach((x) => {
          allIds.push(x.id);
        });
        if (!allIds.includes(albm.id)) {
          this.albumCollection.push(albm);
          await addDoc(collection(db, "allAlbums"), albm).then(() => {
            toast.success("New Album uploaded");
          });
        }

        var timeObj2 = new Date();
        var LastSnapshotUpdateObject2 = {
          date: timeObj2.getDate(),
          time: timeObj2.getTime(),
          month: timeObj2.getMonth(),
          year: timeObj2.getFullYear(),
        };
        await trackChangesInTheDatabase().addNewDatabaseChange(
          "gallery",
          LastSnapshotUpdateObject2
        );
      }
    },

    //extract all posts from firebase
    async extractAlbumsFromFirebase() {
      //when no chnage fromm admin but local collection storage empty
      if (this.albumCollection.length === 0) {
        const querySnapshot = await getDocs(collection(db, "allAlbums"));
        this.LastSnapshotUpdateTime = [];
        var timeObj = new Date();
        var LastSnapshotUpdateObject = {
          date: timeObj.getDate(),
          time: timeObj.getTime(),
          month: timeObj.getMonth(),
          year: timeObj.getFullYear(),
        };

        this.LastSnapshotUpdateTime.push(LastSnapshotUpdateObject);
        var allExistingAlbumIds = [];

        this.albumCollection.forEach((v) => {
          if (!allExistingAlbumIds.includes(v.id)) {
            allExistingAlbumIds.push(v.id);
          }
        });
        if (querySnapshot !== null) {
          querySnapshot.forEach((doc) => {
            if (!allExistingAlbumIds.includes(doc.data().id)) {
              this.albumCollection.push(doc.data());
            }
          });
        }
      } else {
        var hasChange = false;
        await trackChangesInTheDatabase()
          .checkChangeInDatabase("gallery")
          .then(() => {
            var changeObj = trackChangesInTheDatabase().galleryChangeObject;

            if (changeObj === null) {
              return;
            }

            if (
              JSON.stringify(changeObj) ===
              JSON.stringify(this.LastSnapshotUpdateTime[0])
            ) {
              return;
            } else {
              if (changeObj.date < this.LastSnapshotUpdateTime[0].date) {
                hasChange = false;
              } else if (changeObj.year < this.LastSnapshotUpdateTime[0].year) {
                hasChange = false;
              } else if (changeObj.time < this.LastSnapshotUpdateTime[0].time) {
                hasChange = false;
              } else if (
                changeObj.month < this.LastSnapshotUpdateTime[0].month
              ) {
                hasChange = false;
              } else {
                hasChange = true;
              }
            }
          });

        //run this is there is a chnage
        if (hasChange) {
          const querySnapshot = await getDocs(collection(db, "allAlbums"));

          this.LastSnapshotUpdateTime = [];
          var timeObj2 = new Date();
          var LastSnapshotUpdateObject2 = {
            date: timeObj2.getDate(),
            time: timeObj2.getTime(),
            month: timeObj2.getMonth(),
            year: timeObj2.getFullYear(),
          };

          this.LastSnapshotUpdateTime.push(LastSnapshotUpdateObject2);

          this.albumCollection = [];
          querySnapshot.forEach((doc) => {
            this.albumCollection.push(doc.data());
          });
        }
      }
    },

    setSelectedAlbum(albumObject) {
      this.selectedAlbum = albumObject;
    },
  },
  persist: true,
});
