<template>
  <div class="wrapper">
    <div class="posts-for-selection" v-if="isPostSelectedForEdit === false">
      <h5>Select from Below</h5>

      <div class="all-post">
        <table>
          <tr>
            <th>Post Title</th>
            <th>date</th>
            <th>Edit Option</th>
          </tr>
          <tr v-for="(v, i) in allPostList" :key="i">
            <td>
              <p @click="goToPostViewScreen(v.id)" id="post-ttle">
                {{ v.title }}
              </p>
            </td>
            <td>
              <p>{{ v.time }}</p>
            </td>
            <td>
              <button class="editPostBtn" @click="selectPostForEdit(v.id)">
                Edit
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="edit-post-item" v-else>
      <div class="deletebtn">
        <button
          class="delete-post"
          @click="deletePost(selectedPostObject[0].id)"
        >
          Delete Post
        </button>
      </div>

      <div class="post-time">
        <p>{{ selectedPostObject[0].time }}</p>
      </div>

      <div class="post-category">
        <label>Select Post Category:</label>

        <select v-model="postType">
          <option v-for="(v, i) in typesOfPost" :key="i">
            {{ v }}
          </option>
        </select>
      </div>

      <div class="title">
        <label>Post Title</label>
        <textarea v-model="postTitle"></textarea>
      </div>
      <div class="Image">
        <label>Post Image</label>
        <div class="editableImg">
          <button @click="togglePhotoEditOptions">
            <i class="fa-solid fa-pen"></i>
          </button>
          <select v-if="showPhotoEditOptions">
            <option @click="collectToBeRemovedPostImageUrl(postImageValue[0])">
              Remove
            </option>
            <option @click="replacePostImg">Replace</option>
          </select>
          <div class="image-upload" v-if="replacePostImage">
            <input
              id="pimg"
              @click="getSelectedPhotos('pimg', true)"
              type="file"
              accept="image/gif, image/jpeg, image/png"
            />
          </div>
          <img :src="postImageValue[0]" v-if="postImageValue.length != 0" />
        </div>
      </div>
      <div class="Links">
        <label>Post Links</label>

        <p v-for="(v, i) in linkValues" :key="i">
          <a :href="v.linkvalue" target="_blank"
            >{{ v.label }}
            <button @click.prevent="removePostLink(i)">
              <i class="fa-solid fa-pen"></i></button
          ></a>
        </p>

        <div class="add-new-btn">
          <button @click="showAddLinkOptions = !showAddLinkOptions">
            Add Newlink
          </button>
        </div>

        <div class="add-link" v-if="showAddLinkOptions">
          <input type="text" v-model="newLinkLabel" placeholder="Enter label" />
          <p v-if="labelError">{{ labelError }}</p>
          <input type="text" v-model="newLinkValue" placeholder="link Link" />
          <p v-if="linkError">{{ linkError }}</p>
          <button @click="collectLinks">Add link</button>
        </div>
      </div>
      <div class="otherImages">
        <label>Other Images</label>

        <div class="image-container">
          <div class="other-img" v-for="(v, i) in otherImages" :key="i">
            <button @click="removeOtherImg(v, i)">
              <i class="fa-solid fa-pen"></i>
            </button>
            <img :src="v" alt="" />
          </div>
        </div>
        <div class="add-more-other-images">
          <input
            type="file"
            id="otherImg"
            @click="getSelectedPhotos('otherImg', false)"
            multiple
            accept="image/gif, image/jpeg, image/png"
          />
        </div>
      </div>
      <div class="description">
        <label>Post Description</label>
        <textarea v-model="postDescription"></textarea>
      </div>

      <div class="actionButtons">
        <button @click="createModifiedPost">Save Changes</button>
        <button @click="cancelEdit">Cancel</button>
      </div>
    </div>
  </div>
  <div class="loading" v-if="isBeingEdited">
    <p>Please Wait....</p>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { PostCollectionStore } from "@/stores/posts";
import { allUploadedFilesStorageRefCollection } from "@/stores/uploaded-files-storageRef-collection";
import router from "@/router";
import { useToast } from "vue-toastification";

import { uploadFiles } from "@/stores/file-upload";

export default {
  name: "edit-post",

  setup() {
    //store file urls in case of cancelling edit
    const mayBeDeletableFileUrls = ref([]);

    //upload store instance
    const fileUploadStore = uploadFiles();

    const isBeingEdited = ref(false);

    //fileStorageRefStore
    const strgRefStore = allUploadedFilesStorageRefCollection();

    //toast instance
    const toast = useToast();

    //variable used to show or hide the edit form
    const isPostSelectedForEdit = ref(false);

    //selected post object
    const selectedPostObject = ref([]);

    //post collection instance
    const postCollection = PostCollectionStore();

    //post list
    const allPostList = ref([]);
    onMounted(() => {
      allPostList.value = postCollection.PostCollection;
    });

    //function to select post id for edit purpose
    function selectPostForEdit(id) {
      selectedPostObject.value = postCollection.PostCollection.filter(
        (post) => post.id === id
      );
      if (selectedPostObject.value !== null) {
        postType.value = selectedPostObject.value[0].type;
        postTitle.value = selectedPostObject.value[0].title;
        postImageValue.value = selectedPostObject.value[0].postImage;
        otherImages.value = selectedPostObject.value[0].otherImages;
        linkValues.value = selectedPostObject.value[0].importantLinks;
        postDescription.value = selectedPostObject.value[0].description;
        //change variable to show the edit form
        isPostSelectedForEdit.value = true;
      }
    }

    function createModifiedPost() {
      if (
        postDescription.value === selectedPostObject.value[0].description &&
        linkValues.value === selectedPostObject.value[0].importantLinks &&
        otherImages.value === selectedPostObject.value[0].otherImages &&
        postImageValue.value === selectedPostObject.value[0].postImage &&
        postTitle.value === selectedPostObject.value[0].title &&
        postType.value === selectedPostObject.value[0].type &&
        toBeRemovedRefs.value.length === 0
      ) {
        toast.warning("You Edited Nothing");
        return;
      }

      if (postTitle.value.length !== 0) {
        var modifiedPost = {
          PostedBy: selectedPostObject.value[0].PostedBy,
          description: postDescription.value,
          id: selectedPostObject.value[0].id,
          importantLinks: linkValues.value,
          otherImages: otherImages.value,
          postImage: postImageValue.value,
          time: selectedPostObject.value[0].time,
          title: postTitle.value,
          type: postType.value,
        };
        isBeingEdited.value = true;
        var response = postCollection
          .editPost(selectedPostObject.value[0].id, modifiedPost)
          .then(() => {
            allPostList.value = postCollection.PostCollection;
          });
        if (response) {
          finalRemovalOfFiles();
          isBeingEdited.value = false;
          isPostSelectedForEdit.value = false;
        } else {
          isBeingEdited.value = false;
          toast.warning("Error occurred");
        }
      } else {
        toast.warning("Title cant be empty");
      }
    }

    //function to cancel edit process
    function cancelEdit() {
      isPostSelectedForEdit.value = false;

      mayBeDeletableFileUrls.value.forEach((v) => {
        var stRef = strgRefStore.findFileStorageRef(v);

        if (stRef !== null) {
          strgRefStore.deleteFileFromFirebase(stRef);
        }
      });
    }

    function goToPostViewScreen(id) {
      postCollection.setSelectedPostId(id);
      router.push({
        name: "fullpost-view",
      });
    }

    //edit form related variables and functions are below

    const typesOfPost = [
      "Post Update",
      "Announcement",
      "Book Launch",
      "Discussion",
      "Other Activities",
    ];

    //type of post value holder
    const postType = ref("");

    //variable to hold the Post Title
    const postTitle = ref("");

    //variable to hold the links
    const linkValues = ref([]);

    //whether to show or hide photo edit options
    const showPhotoEditOptions = ref(false);

    //whether to show or hide add link options
    const showAddLinkOptions = ref(false);

    //link error
    const linkError = ref("");

    //linkLabel error
    const labelError = ref("");

    //link label
    const newLinkLabel = ref();

    //link value
    const newLinkValue = ref();

    //whether to replace post image
    const replacePostImage = ref(false);

    //post image holder variables
    const postImageValue = ref([]);

    //other images related to post holder variables
    const otherImages = ref([]);

    //variablnulle to hold description of the post
    const postDescription = ref("");

    function togglePhotoEditOptions() {
      showPhotoEditOptions.value = !showPhotoEditOptions.value;
      if (replacePostImage.value) {
        replacePostImage.value = false;
      }
    }
    //function to enable replace post image option
    function replacePostImg() {
      replacePostImage.value = true;
    }

    function getSelectedPhotos(id, isPostImg) {
      const inputFile = document.getElementById(id);

      inputFile.addEventListener("change", function () {
        if (inputFile.value) {
          const files = inputFile.files;

          if (isPostImg) {
            if (postImageValue.value.length != 0) {
              postImageValue.value = [];
            }
          }

          for (let index = 0; index < files.length; index++) {
            if (files[index].type.match("image")) {
              if (isPostImg) {
                fileUploadStore.uploadSingleImageAndGetDownloadUrl(
                  files[index]
                );
                if (
                  !mayBeDeletableFileUrls.value.includes(
                    fileUploadStore.uploadedSingleImage
                  )
                ) {
                  mayBeDeletableFileUrls.value.push(
                    fileUploadStore.uploadedSingleImage
                  );
                }
                postImageValue.value = fileUploadStore.uploadedSingleImage;
              } else {
                fileUploadStore.uploadMultipleImageAndGetDownloadUrl(
                  files[index]
                );
                fileUploadStore.multipleImages.forEach((url) => {
                  if (!otherImages.value.includes(url)) {
                    otherImages.value.push(url);
                  }
                });
                fileUploadStore.multipleImages.forEach((v) => {
                  if (!mayBeDeletableFileUrls.value.includes(v)) {
                    mayBeDeletableFileUrls.value.push(v);
                  }
                });
              }
            }
            continue;
          }
        }
      });
    }

    //collect all the removeable file storage references
    const toBeRemovedRefs = ref([]);

    //function to remove post image
    function collectToBeRemovedPostImageUrl(url) {
      if (url.length !== 0) {
        var stRef = strgRefStore.findFileStorageRef(url);

        if (stRef !== null) {
          if (!toBeRemovedRefs.value.includes(stRef)) {
            toBeRemovedRefs.value.push(stRef);
          }
        }
      }

      postImageValue.value = [];
      if (replacePostImage) {
        replacePostImage.value = false;
      }
    }

    // also to removed those intermediate cancelled files
    function finalRemovalOfFiles() {
      if (toBeRemovedRefs.value.length !== 0) {
        toBeRemovedRefs.value.forEach((v) => {
          strgRefStore.deleteFileFromFirebase(v);
        });
      }
    }

    //function to remove individual post link
    function removePostLink(id) {
      if (linkValues.value) {
        linkValues.value.splice(id, 1);
      }
    }

    function checkWebLinks() {
      const pattern =
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;

      if (!newLinkValue.value) {
        linkError.value = "Empty link";
        return;
      }

      if (pattern.test(newLinkValue.value.trim())) {
        if (!newLinkValue.value.trim().includes("https://")) {
          linkError.value = "Link must include https://";
          return false;
        }
        linkError.value = "";
        return true;
      }
      linkError.value = "Invalid Link";
      return false;
    }

    function collectLinks() {
      var linkVerified = checkWebLinks();
      if (!linkVerified) {
        return;
      }

      if (!newLinkLabel.value) {
        labelError.value = "link Label is required";
        return;
      }
      if (linkValues.value.length === 0) {
        linkValues.value.push({
          label: newLinkLabel.value,
          linkvalue: newLinkValue.value,
        });
        linkError.value = "";
        labelError.value = "";
        newLinkLabel.value = "";
        newLinkValue.value = "";
        return;
      } else {
        for (var item in linkValues.value) {
          if (linkValues.value[item].label === newLinkLabel.value) {
            labelError.value = "Duplicate Entry not allowed";
            return;
          }
        }
        linkValues.value.push({
          label: newLinkLabel.value,
          linkvalue: newLinkValue.value,
        });
        linkError.value = "";

        labelError.value = "";
        newLinkLabel.value = "";
        newLinkValue.value = "";
      }
    }

    function removeOtherImg(url, id) {
      if (url.length !== 0) {
        var stRef = strgRefStore.findFileStorageRef(url);

        if (stRef !== null) {
          if (!toBeRemovedRefs.value.includes(stRef)) {
            toBeRemovedRefs.value.push(stRef);
          }
        }
      }
      otherImages.value.splice(id, 1);
    }

    //delete a post

    function deletePost(id) {
      var response = confirm("Are you sure to delete the Post?");
      if (response) {
        postCollection.deletePost(id).then(() => {
          isPostSelectedForEdit.value = false;
          allPostList.value = postCollection.PostCollection;
        });
      }
    }

    return {
      finalRemovalOfFiles,
      deletePost,
      typesOfPost,
      collectLinks,
      postType,
      newLinkLabel,
      removeOtherImg,
      newLinkValue,
      postTitle,
      showPhotoEditOptions,
      togglePhotoEditOptions,
      replacePostImage,
      replacePostImg,
      postImageValue,
      otherImages,
      getSelectedPhotos,
      collectToBeRemovedPostImageUrl,
      linkValues,
      removePostLink,
      showAddLinkOptions,
      linkError,
      labelError,
      checkWebLinks,
      postDescription,
      isPostSelectedForEdit,
      allPostList,
      selectPostForEdit,
      goToPostViewScreen,
      selectedPostObject,
      cancelEdit,
      createModifiedPost,
      isBeingEdited,
      mayBeDeletableFileUrls,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin labelStyle {
  label {
    font-size: 14px;
    font-weight: 600;
    color: white;

    text-decoration: underline;
  }
}

.wrapper {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: rgb(69, 81, 182);
  padding: 30px;

  @media (max-width: 400px) {
    padding: 10px;
  }

  //select post section
  .posts-for-selection {
    h5 {
      text-align: center;
      text-decoration: underline;
      margin: 30px auto;
      color: white;
    }

    .all-post {
      width: 100%;
      height: auto;
      margin: 30px auto;
      padding: 15px;
      color: white;
      // background: yellow;

      @media (max-width: 400px) {
        padding: 1px;
      }

      table {
        th {
          width: 33vw;
          height: 29px;
          border: 0.1px solid grey;
          text-align: center;
          font-size: 14.5px;
          font-weight: 500;
        }
        td {
          text-align: center;
          padding: 6px auto;
          border: 0.1px solid grey;
          height: 40px;
          p {
            font-size: 13px;
            font-weight: 500;
            color: white;
          }
          #post-ttle {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
              color: blanchedalmond;
            }
          }

          .editPostBtn {
            border: none;
            outline: none;
            padding: 1px 45px;
            font-size: 12px;
            font-weight: 500;
            border-radius: 7px;
          }
        }
      }
    }
  }

  //edit post form section
  .edit-post-item {
    width: 95%;
    height: 95%;
    margin: 20px auto;
    // background: yellow;

    .deletebtn {
      .delete-post {
        border: none;
        outline: none;
        font-size: 12px;
        background: red;
        padding: 1.5px 20px;
        color: white;
        border-radius: 2px;
      }
    }

    .post-time {
      p {
        text-align: end;
        font-size: 12px;
        color: white;
        font-weight: 600;
      }
    }

    .post-category {
      margin: 25px auto;
      @include labelStyle();

      select {
        font-size: 12px;
        font-weight: 500;
        margin: 2px 15px;
      }
    }

    .title {
      margin: 25px auto;
      @include labelStyle();
      display: flex;
      flex-direction: column;
      textarea {
        height: 100px;
        width: 100%;
        font-size: 12.5px;
        font-weight: 500;
        margin: 10px auto;
        color: black;
        resize: none;
        border: none;
        outline: none;
        text-align: center;
        padding: 30px;
        border-radius: 10px;
      }
    }

    .Image {
      @include labelStyle();
      margin: 25px auto;
      // background: red;

      display: flex;
      flex-direction: column;
      .editableImg {
        position: relative;
        display: flex;
        flex-direction: column;
        button {
          position: absolute;
          top: 0;
          right: 40%;
          font-size: 12px;
          background: transparent;
          border: none;
          outline: none;
          text-decoration: underline;
          color: white;

          &:hover {
            color: red;
            font-size: 14px;
          }
        }
        select {
          font-size: 12px;
          width: 80px;
          margin-top: 50px;
        }

        .image-upload {
          background: red;
          margin: 30px auto;
          // display: none;
          input {
            position: absolute;
            left: 0;
            top: 30;
            font-size: 12px;
            margin: 15px auto;
          }
        }

        img {
          height: 200px;
          width: 50%;
          margin: 20px 15px;
          border-radius: 10px;

          @media (max-width: 576px) {
            width: 90%;
            margin: 20px auto;
          }
        }
      }
    }

    .Links {
      @include labelStyle();
      margin: 25px auto;

      p {
        font-size: 12px;
        font-weight: 500;
        margin: 5px auto;

        button {
          background: transparent;
          border: none;
          outline: none;
          font-size: 12px;
          margin: auto 15px;
        }
      }

      .add-new-btn {
        button {
          height: 25px;
          width: 90px;
          font-size: 10px;
          border-radius: 10px;
          border: none;
          outline: none;
        }
        margin: 20px auto;
      }
      .add-link {
        display: flex;
        flex-direction: column;
        input {
          border: none;
          outline: none;
          width: 100%;
          border-radius: 5px;
          font-size: 10px;
          padding: 8px;
          margin: 20px auto;
          text-align: center;
        }
        button {
          height: 25px;
          width: 90px;
          font-size: 10px;
          border-radius: 10px;
          border: none;
          outline: none;
        }
        p {
          font-size: 12px;
          color: red;
        }
      }
    }
    .otherImages {
      @include labelStyle();
      margin: 25px auto;
      .image-container {
        height: 35%;
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 27%;
        gap: 8px;
        overflow: auto;
        overscroll-behavior-inline: contain;
        padding-top: 5px;
        padding: 20px auto;
        scrollbar-color: red;
        scrollbar-width: 10px;

        .other-img {
          height: 100%;
          button {
            border: none;
            outline: none;
            background: none;
            color: red;
            font-size: 12px;
          }
          img {
            height: 95%;
            border-radius: 5px;
          }
        }
      }

      .add-more-other-images {
        display: flex;
        flex-direction: column;
        margin: 20px auto;
        input {
          font-size: 12px;
          width: 80%;
          cursor: pointer;
        }
      }
    }

    .description {
      @include labelStyle();
      margin: 25px auto;
      display: flex;
      flex-direction: column;
      textarea {
        font-size: 12px;
        font-weight: 500;
        color: black;
        resize: none;
        min-height: 100px;
        margin: 20px auto;
        width: 100%;
        border-radius: 10px;
        padding: 20px;
      }
    }

    .actionButtons {
      margin: 45px auto;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        font-size: 12px;
        padding: 3px 15px;
        font-size: 500;
        border-radius: 12px;
        margin: 1px 10px;
        border: none;
        outline: none;
      }
    }
  }
}
.loading {
  position: fixed;
  inset: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 100000;

  display: grid;
  place-content: center;

  p {
    color: white;
    font-size: 15px;
  }
}
</style>