<template>
  <div class="modal-wrapper">
    <h5><b>Confirm Submission</b></h5>

    <div class="contents">
      <div class="book-title">
        <label>Title</label>
        <p>
          {{ book.bookName }}
        </p>
      </div>

      <div class="prices">
        <div class="current-price">
          <label>Current Price</label>
          <p>
            {{ book.currentPrice }}
            <i class="fa-solid fa-indian-rupee-sign fa-xs"></i>
          </p>
        </div>
        <div class="MRP">
          <label>MRP</label>
          <p>
            {{ book.mrp }} <i class="fa-solid fa-indian-rupee-sign fa-xs"></i>
          </p>
          <p id="discount">{{ book.discount }} off</p>
        </div>
      </div>

      <div class="image">
        <label>Book Image</label>

        <img :src="book.imageUrl" />
      </div>

      <div class="author-details">
        <label>Author Name</label>
        <p>{{ book.authorName }}</p>

        <label>Author Description</label>
        <p>
          {{ book.authorDetails }}
        </p>
      </div>

      <div class="other-details">
        <label>Publication Year::</label>
        <p>{{ book.pubYear }}</p>
        <label>Language::</label>
        <p v-for="(v, i) in book.languageList" :key="i">{{ v }}</p>
        <label>Binding::</label>
        <p>{{ book.bindingType }}</p>
        <label>Publisher::</label>
        <p>{{ book.publisher }}</p>
        <label>Genre::</label>
        <p>{{ book.genre }}</p>
        <label>ISBN::</label>
        <p>{{ book.isbn }}</p>
        <label>Pages::</label>
        <p>{{ book.noOfPages }}</p>
        <label>Seller::</label>
        <p>{{ book.sellerName }}</p>
      </div>

      <div class="book-description">
        <label>Book Description</label>

        <p>
          {{ book.bookDescription }}
        </p>
      </div>

      <div class="submit">
        <button @click="createBookForUpload">Confirm&Submit</button>

        <button @click="showPreview">Edit more!</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showPreview: Function,
    createBookForUpload: Function,
    book: Object,
  },

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@mixin commonStyle {
  label {
    color: white;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 10px;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    padding: 0px 10px;
    color: white;
  }
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100vw;
  overflow-y: scroll;
  background-color: #1f272b;
  scrollbar-color: transparent;

  h5 {
    color: white;
    text-align: center;
    margin: 40px auto;
    font-size: 25px;
    color: goldenrod;
  }

  .contents {
    position: static;
    height: auto;
    width: 90%;
    margin: 20px auto;
    background-color: #1f272b;
    // border: 1px solid black;

    .book-title {
      @include commonStyle();
    }

    .prices {
      @include commonStyle();

      .current-price {
        display: flex;

        p {
          padding: 12px 0;
        }
      }

      .MRP {
        display: flex;

        p {
          padding: 12px 0;
        }

        #discount {
          padding-left: 15px;
          color: yellow;
        }
      }
    }

    .image {
      padding: 12px 10px;

      label {
        color: white;
        font-size: 14px;
      }

      img {
        height: 100px;
        width: 150px;
        margin: auto 15px;
        border-radius: 15px;
      }
    }

    .author-details {
      @include commonStyle();
    }

    .other-details {
      @include commonStyle();
    }

    .book-description {
      label {
        color: white;
        font-size: 14px;
        padding-left: 10px;
        padding-top: 10px;
        text-decoration: underline;
      }

      p {
        color: white;
        font-size: 12px;
        padding: 12px 10px;
      }
    }

    .submit {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 45px auto;
      button {
        font-size: 12px;
        border-radius: 15px;
        width: 150px;
        height: 30px;
        border: none;
        outline: none;
        margin: 0px 10px;
      }
    }
  }
}
</style>