//get datetime value in desired format
function getDesiredDateTime(isDeliveryDate) {
  let dtFormat = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: "true",
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
  const myDateTime = new Date();
  const otherdate = new Date();
  const desiredDate = otherdate.setDate(myDateTime.getDate() + 20);

  if (isDeliveryDate) {
    return dtFormat.format(desiredDate);
  } else {
    return dtFormat.format(myDateTime);
  }
}

export { getDesiredDateTime };
