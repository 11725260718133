<template>
  <div class="wrapper">
    <h6>Important Message</h6>
    <div class="notice">
      <p>
        We are extremely sorry to inform you that due to some technical reasons
        <b>we are currently facing some issues in receiving online payments</b
        >.It may take 4 to 5 days to resolve that issue. So, regarding the
        payment of your current order,
        <b
          >you can reach us on the below phone number or we will contact you
          shortly on the phone number included on your delivery address</b
        >. Thank You!
      </p>

      <div class="pname">
        <p><span>Name:</span> Mr.Diganta Kalita</p>
        <p><span>Phone No:</span> <b>+91 6003309372</b></p>
      </div>
    </div>
    <div class="confirm">
      <button @click="orderCompleteProcess">Proceed</button>
    </div>
  </div>
</template>

<script>
import { bookOrderCollection } from "@/stores/bookorder-collection";

import { shoppingCartStore } from "@/stores/shopping-cart-store";
import { ref } from "@vue/reactivity";
import router from '@/router';

export default {
  name: "payment-error",

  setup() {
    //Order collection Instance
    const orderCollection = bookOrderCollection();

    //shopping cart instance
    const shoppingCart = shoppingCartStore();

    //payment status
    const paymentStatus = ref(false);

    function orderCompleteProcess() {
      paymentStatus.value = true;
      if (paymentStatus.value) {
        const orderCompletionStatus = orderCollection.createCompleteOrder();

        if (orderCompletionStatus) {
          shoppingCart.$reset();
          router.push({
            name: "current-orders",
          });

          shoppingCart.deleteIdCollectionNshoppingCartItems();
        }
      }
    }

    return {
      paymentStatus,
      orderCompleteProcess,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-image: linear-gradient(
    to right top,
    #68136d,
    #49286f,
    #2b3069,
    #15325c,
    #12314b
  );

  display: grid;
  place-content: center;

  h6 {
    font-size: 17px;
    color: white;
    font-weight: 600;
    text-align: center;
    margin: 20px auto;
    text-decoration: underline;
  }

  .notice {
    height: 300px;
    width: 450px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    // border: 1px solid white;
    z-index: 50;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

    p {
      color: white;
      text-align: justify;
      padding: 20px;
      font-size: 12px;
      font-weight: 700;
      b {
        color: yellow;
      }
    }
    .pname {
      p {
        padding: 5px;
        text-align: center;
        span {
          font-size: 11px;
          color: white;
          text-decoration: underline;
        }
      }
    }

    @media (max-width: 500px) {
      width: 95%;
      height: 90%;
      margin: 35px auto;
    }
  }
  .confirm {
    width: 100%;
    margin: 20px auto;
    text-align: center;
    button {
      border: none;
      outline: none;
      padding: 3px 35px;
      border-radius: 7px;
      font-size: 12px;
      font-weight: 600;
      margin: 35px auto;
    }
  }
}
</style>