<template>
  <div class="wrapper">
    <h5><b>Handle Orders</b></h5>

    <div class="type-of-order">
      <div class="new-orders" id="a" @click="changeActiveNo(0)">
        <p>
          <b>New Orders({{ totalNewOrder }})</b>
        </p>
      </div>
      <div class="processed-orders" id="b" @click="changeActiveNo(1)">
        <p>
          <b>Processed Orders({{ totalProcessedOrder }})</b>
        </p>
      </div>
      <div class="completed-orders" id="c" @click="changeActiveNo(2)">
        <p>
          <b>Completed Orders({{ totalFulfilledOrder }})</b>
        </p>
      </div>
    </div>
    <div class="order-display-container">
      <div class="single-order" v-for="(v, i) in orderList" :key="i">
        <singleOrder :order="v"></singleOrder>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

import singleOrder from "@/components/single-order.vue";
import { bookOrderCollection } from "@/stores/bookorder-collection";

export default {
  name: "handle-orders",

  components: {
    singleOrder,
  },

  setup() {
    //order collection instance
    const orderCollection = bookOrderCollection();

    //list to hold all user orders
    const orderList = ref([]);

    //newest Orders
    const newOrders = ref([]);
    //newest Orders
    const processedOrders = ref([]); //newest Orders
    //fullfilled orders
    const fullfilledOrders = ref([]);

    const orderTypeSelector = ref(0);

    //variables holding the no of orders of each category
    const totalNewOrder = ref("");
    const totalProcessedOrder = ref("");
    const totalFulfilledOrder = ref("");

    onMounted(() => {
      orderCollection.extractOrdersFromBackend(false).then(() => {
        orderList.value = orderCollection.allOrdersForAdmin.reverse();

        orderList.value.forEach((order) => {
          if (order.deliveryStatus === "Not Initiated") {
            newOrders.value.push(order);
          }
          if (order.deliveryStatus === "In Route") {
            processedOrders.value.push(order);
          }
          if (order.deliveryStatus === "Delivered") {
            fullfilledOrders.value.push(order);
          }
        });
        orderList.value = newOrders.value;
        totalProcessedOrder.value = processedOrders.value.length;
        totalNewOrder.value = newOrders.value.length;
        totalFulfilledOrder.value = fullfilledOrders.value.length;
      });
    });

    //function to change the active tracker number
    function changeActiveNo(id) {
      orderTypeSelector.value = id;
      changeSectionColor(id);
      if (id === 0) {
        orderList.value = newOrders.value;
      }
      if (id === 1) {
        orderList.value = processedOrders.value;
      }
      if (id === 2) {
        orderList.value = fullfilledOrders.value;
      }
    }

    //change section color if it is active
    function changeSectionColor(id) {
      const getNewOrders = document.getElementById("a");
      const getProcessedOrders = document.getElementById("b");
      const getCompletedOrders = document.getElementById("c");

      if (id === 0) {
        getNewOrders.style.backgroundColor = "yellow";
        getProcessedOrders.style.backgroundColor = "transparent";
        getCompletedOrders.style.backgroundColor = "transparent";
      } else if (id === 1) {
        getNewOrders.style.backgroundColor = "transparent";
        getProcessedOrders.style.backgroundColor = "yellow";
        getCompletedOrders.style.backgroundColor = "transparent";
      } else {
        getNewOrders.style.backgroundColor = "transparent";
        getProcessedOrders.style.backgroundColor = "transparent";
        getCompletedOrders.style.backgroundColor = "yellow";
      }
    }

    onMounted(() => {
      changeSectionColor(0);
    });

    return {
      changeActiveNo,
      orderList,
      totalNewOrder,
      totalProcessedOrder,
      totalFulfilledOrder,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(#4671ea, #ac34e7);
  padding: 30px 25px;

  h5 {
    font-size: 18px;
    text-align: center;
    text-decoration: underline;
  }

  .type-of-order {
    height: 100px;
    width: 100%;
    // background-color: red;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(33%, 1fr);
    gap: 4px;

    @media (max-width: 568px) {
      grid-auto-flow: row;
    }

    .new-orders,
    .processed-orders,
    .completed-orders {
      margin: auto 5px;
      border: 1px solid white;
      text-align: center;
      cursor: pointer;

      p {
        font-size: 12px;
      }
    }
  }

  .order-display-container {
    min-height: 90vh;
    height: 90%;
    width: 95%;
    margin: 35px auto;
    // background-color: #4671ea;
  }
}
</style>
