<template>
  <div class="wrapper">
    <div class="login-area">
      <div class="cancel">
        <button @click="goBack()">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>

      <h5>
        <b>{{ label }}</b>
      </h5>
      <!-- name field -->
      <input
        type="text"
        placeholder="Full Name"
        id="name"
        v-if="signUp"
        v-model="fullName"
      />
      <!-- email field -->
      <input
        type="text"
        placeholder="Email Address"
        id="email"
        v-model="emailvalue"
        @blur="emailValidator(emailvalue, false)"
      />
      <p id="errorId" v-if="emailError">{{ emailError }}</p>
      <p id="errorId" v-if="loginError">{{ loginError }}</p>
      <!-- <p id="errorId" v-if="user.loginError">{{user.loginError}}</p> -->
      <!-- password field -->
      <input
        type="password"
        placeholder="Password"
        id="password"
        v-model="passwordValue"
      />
      <p id="errorId" v-if="passwordError">{{ passwordError }}</p>
      <!-- confirm password field -->
      <input
        type="password"
        placeholder="Confirm Password"
        id="cfm-password"
        v-if="signUp"
        v-model="confirmedPassword"
      />
      <p id="errorId" v-if="signupError.length !== 0">{{ signupError }}</p>
      <!-- <p id="errorId" v-if="passwordError">{{ passwordError }}</p> -->

      <div class="option-area">
        <p v-if="!signUp">Forget password?</p>
        <button @click="triggerReset()" v-if="!signUp">Reset Password</button>
      </div>
      <div class="option-area">
        <p v-if="!signUp">Not a member?</p>
        <button @click="toSignUp()">{{ btnlabel }}</button>
      </div>

      <button id="submit" type="submit" @click="signIn">{{ label }}</button>
    </div>
  </div>
  <!-- //need to use the suspense feature of vue  -->
  <div class="loading" v-if="toReset">
    <div class="reset-screen">
      <div class="cancel">
        <button @click="toReset = false">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>

      <input
        type="text"
        placeholder="Email Address"
        id="email"
        v-model="resetEmail"
        @blur="emailValidator(resetEmail, true)"
      />
      <p id="reseterror" v-if="resetEmailError.length !== 0">
        {{ resetEmailError }}
      </p>

      <button @click="sendResetEmail(resetEmail)">Send</button>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import router from "@/router";

import { userCreation } from "@/stores/user-verification";
import { onMounted } from "@vue/runtime-core";

export default {
  name: "signin-login",
  setup() {
    //show reset screen
    const toReset = ref(false);
    //reset email ref holder
    const resetEmail = ref("");

    const label = ref("Log In");
    const fullName = ref("");
    const btnlabel = ref("SignUp");
    const signUp = ref(false);
    const emailError = ref("");
    const passwordError = ref("");
    const signupError = ref("");
    const loginError = ref("");
    const resetEmailError = ref("");

    function resetAllErrors() {
      emailError.value = "";
      passwordError.value = "";
      signupError.value = "";
      loginError.value = "";
    }

    //store instance
    const user = userCreation();

    //v-model values
    const emailvalue = ref("");
    const passwordValue = ref("");
    const confirmedPassword = ref("");

    onMounted(() => {
      // signupError.value = "";
      resetAllErrors();
    });

    function signIn() {
      resetAllErrors();

      if (emailError.value) {
        return;
      }
      if (passwordValue.value.length < 6) {
        passwordError.value = "Minimum password length is 6";
        return;
      }

      if (signUp.value) {
        if (fullName.value.length === 0) {
          return;
        } else {
          if (confirmedPassword.value === passwordValue.value) {
            user
              .signUp(emailvalue.value, passwordValue.value, fullName.value)
              .then(() => {
                if (user.signupError.length > 0) {
                  if (user.signupError.includes("already-in-use")) {
                    signupError.value = "Account exists,Try to logIn!";
                  } else {
                    signupError.value = user.signupError;
                  }
                }
              });
          } else {
            passwordError.value = "Passwords need to Match";
          }
        }
      } else {
        user
          .logInExistingUser(emailvalue.value, passwordValue.value)
          .then(() => {
            if (user.loginError.length !== 0) {
              loginError.value = user.loginError;
            }
          });
      }
    }

    function toSignUp() {
      if (!signUp.value) {
        signUp.value = true;
        btnlabel.value = "LogIn instead?";
        label.value = "Sign Up";
        resetAllErrors();
      } else {
        signUp.value = false;
        btnlabel.value = "SignUp?";
        label.value = "Log In";
        resetAllErrors();
      }
    }

    function emailValidator(email, toReset) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!pattern.test(email.trim())) {
        if (!toReset) {
          emailError.value = "invalid email";
        } else {
          resetEmailError.value = "invalid email";
        }

        return;
      }

      return true;
    }

    //go back one step function
    function goBack() {
      router.go(-1);
    }

    //reset password function
    function triggerReset() {
      toReset.value = true;
      resetEmailError.value = "";

      // console.log("resetting");
    }

    function sendResetEmail(email) {
      console.log(email);
      resetEmailError.value = "";

      if (email.length !== 0) {
        user.resetPassword(email).then(() => {
          toReset.value = false;
        });
      }
    }

    return {
      sendResetEmail,
      resetEmailError,
      triggerReset,
      loginError,
      label,
      goBack,
      emailvalue,
      signUp,
      toSignUp,
      btnlabel,
      emailValidator,
      passwordValue,
      signIn,
      user,
      fullName,
      passwordError,
      confirmedPassword,
      emailError,
      signupError,
      toReset,
      resetEmail,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  width: 100%;
  background-color: #172640;
  background-image: url("@/assets/images/background-1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 368px) {
    padding: 25px 3px 20px 3px;
  }

  .login-area {
    height: 80%;
    width: 50%;
    background-color: #172640;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px;

    @media (max-width: 700px) {
      width: 90%;
      margin: 10px;
    }

    @media (max-width: 368px) {
      width: 100%;
      margin: 20px 4px;
    }

    .cancel {
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        border: none;
        outline: none;
        background: transparent;
        margin: auto 20px;
        color: white;
        transition: all 350ms ease;
        &:hover {
          color: red;
          transform: scale(1.2);
        }
      }
    }

    h5 {
      text-align: center;
      font-size: 26.6px;
      margin: 30px auto;
      // text-shadow: -8px 7px #cdd2d5;
      font-family: "Great Vibes", cursive, "Franklin Gothic Medium";
      transition: all 350ms ease-in;
      color: yellow;

      &:hover {
        transform: rotate(360deg);
        color: red;
        font-size: 30px;
      }
    }

    #email,
    #password,
    #name,
    #cfm-password {
      height: 35px;
      width: 320px;
      min-width: 210px;
      text-align: center;
      border-radius: 15px;
      border: none;
      outline: none;
      margin: 20px auto;
      font-size: 13px;
      font-weight: 500;
      padding: 1px 4px;

      @media (max-width: 468px) {
        width: 85%;
        font-size: 11px;
      }
    }

    #submit {
      height: 25px;
      width: 130px;
      border-radius: 15px;
      font-size: 11.5px;
      font-weight: 500;
      margin: 40px auto;
      border: none;
      outline: none;
      &:hover {
        background-color: #172640;
        color: white;
        transition: all 500ms ease-in;
        border: 1px solid white;
      }
    }

    .option-area {
      display: flex;
      padding-left: 60px;

      @media (max-width: 468px) {
        padding-left: 20px;
      }

      p {
        font-size: 9px;
        color: white;
      }

      button {
        border: none;
        outline: none;
        background-color: transparent;
        color: white;
        font-weight: 600;
        font-size: 12px;
        padding: 0px 6px;
        text-decoration: underline;
        transition: all 300ms ease-in-out;

        &:hover {
          font-size: 13px;
          transform: translateY(-2px);
        }
      }
    }
  }

  #errorId {
    color: red;
    font-size: 11px;
    font-weight: 500;
  }
}
.loading {
  position: fixed;
  inset: 0;
  background: rgba($color: #000000, $alpha: 0.9);
  z-index: 1000;

  display: grid;
  place-content: center;

  .reset-screen {
    height: 200px;
    width: auto;
    background: #008187;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 268px) {
      width: 90%;
      margin: 20px auto;
    }

    .cancel {
      // background: yellow;
      position: absolute;
      top: -35px;
      right: -30px;
      button {
        background: none;
        font-size: 20px;
        color: red;
        text-align: right;
        &:hover {
          background: none;
          color: white;
          transform: scaleX(1.2);
        }
      }
    }

    input {
      margin: 0px 20px;
      border: none;
      outline: none;
      height: 26px;
      padding: 3px 10px;
      border-radius: 7px;
      font-size: 11.5px;
      text-align: center;
      font-weight: 600;
      margin-top: 20px;

      @media (max-width: 368px) {
        width: 90%;
        margin: 20px auto;
      }
    }

    #reseterror {
      font-size: 11px;
      color: red;
      text-align: center;
      margin: 7px auto;
    }

    button {
      padding: 2px 30px;
      font-size: 11.5px;
      margin-top: 20px;
      border: none;
      outline: none;
      border-radius: 5px;
      transition: all 300ms ease-in;
      &:hover {
        background: goldenrod;
        color: white;
        transform: skewY(1deg);
      }
    }
  }
}
</style>