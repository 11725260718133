<template>
  <div class="wrapper">
    <h6><b>All transactions</b></h6>

    <p v-if="!selectedDate">
      Displaying Data for:
      <span>
        Month: <b>{{ vmonth }}, {{ vyear }}</b></span
      >
    </p>
    <p v-else>
      Displaying Data for:
      <span>
        Selected Date: <b>{{ selectedDate }}</b></span
      >
      <span
        ><button id="modeChangeBtn" @click="chnageFilterMode()">
          Change to month mode?
        </button></span
      >
    </p>

    <div class="month-filter" v-show="!selectedDate">
      <p>Change Month:</p>

      <select v-model="vmonth">
        <option v-for="(month, i) in monthList" :key="i">
          {{ month }}
        </option>
      </select>
      <select v-model="vyear">
        <option v-for="(year, i) in yearList" :key="i">
          {{ year }}
        </option>
      </select>
    </div>
    <div class="date-filter">
      <p>Filter by Date:</p>

      <input type="date" v-model="selectedDate" />
    </div>

    <!-- show transaction values -->

    <div class="transaction-values">
      <p>Total no of transactions: <b>141</b></p>
      <p>
        Total money collected:
        <b>14100 <i class="fa-solid fa-indian-rupee-sign fa-sm"></i></b>
      </p>
    </div>

    <!-- trasactions -->
    <div class="all-transactions">
      <div v-for="(v, i) in 10" :key="i">
        <individualTransaction></individualTransaction>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

import individualTransaction from "@/components/individual-transaction-widget.vue";

export default {
  name: "transaction-details",

  components: {
    individualTransaction,
  },

  setup() {
    const selectedDate = ref("");
    const vmonth = ref("");
    const vyear = ref("");

    function chnageFilterMode() {
      if (selectedDate) {
        selectedDate.value = "";
      }
    }

    const monthList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    const yearList = [
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
    ];

    function today() {
      var date = new Date();
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();

      const dateToday = day + "/" + month + "/" + year;

      vmonth.value = monthList[month - 1];
      vyear.value = year;

      return dateToday;
    }

    onMounted(() => {
      today();
    });

    return {
      selectedDate,
      monthList,
      yearList,
      vmonth,
      vyear,
      chnageFilterMode,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin inputStyle {
  background-color: #4671ea;
  padding: 3px 12px;
  font-size: 11px;
  border: none;
  outline: none;
  color: whitesmoke;
  margin: auto 2px;
  border-radius: 5px;
}

@mixin commonfilterFlexdStyle {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin: 30px auto;

  p {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.2px;
  }
}

#modeChangeBtn {
  margin: auto 10px;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 10px;
  border: none;
}

.wrapper {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(#4671ea, #55d4ae);

  h6 {
    text-align: center;
    font-size: 20px;
    color: white;
    padding: 30px;
    letter-spacing: 0.2px;
    text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.6);
  }

  p {
    font-size: 15px;
    text-align: center;

    span {
      color: yellow;
      letter-spacing: 0.12px;
      text-decoration: underline;
    }
  }

  .month-filter {
    @include commonfilterFlexdStyle();

    select {
      @include inputStyle();
    }
  }

  .date-filter {
    @include commonfilterFlexdStyle();

    input {
      @include inputStyle();
    }
  }

  .transaction-values {
    height: 20%;
    width: 90%;
    // background: yellow;
    margin: 20px auto;
    border-top: 1px solid white;
    border-bottom: 1px solid white;

    p {
      font-size: 13.5px;
      font-weight: 600;
    }
  }

  .all-transactions {
    height: auto;
    min-height: 70%;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
</style>
