import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
import { getDesiredDateTime } from "@/helper-functions/get-simplified-datetime";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "@/firebase/firebase-init";

import { createCollectionReference } from "@/helper-functions/create-collection-reference";

import { userCreation } from "@/stores/user-verification";

const toast = useToast();

export const bookOrderCollection = defineStore("orderCollection", {
  state: () => ({
    //current temporary order object
    currentTemporaryOrder: Object,
    //current temporary order object
    currentSelectedOrderForViewing: Object,

    //current selected Order status
    currentSelectedOrderStatus: String,
    //current selected Order status
    currentSelectedOrderDeliveryStatus: String,

    //this list will hold all the orders of all users for the admin to inspect
    allOrdersForAdmin: [],
    //this list will hold all the confirmed order of a user after the payment completion
    allConfirmedOrdersOfaUser: [],

    //this list will contain all orders of a user after successful delivery
    completedOrdersOfaUser: [],
    //it will contain user wished products
    userWishList: [],

    //current selected order for admin
    currentSelectedOrderForAdmin: Object,
  }),
  getters: {},
  actions: {
    //set currentSelectedOrderForViewing
    setCurrentSelectedOrderForViewing(orderObject) {
      this.currentSelectedOrderForViewing = orderObject;
      // this.currentSelectedOrderStatus = orderObject.orderStatus;
      // this.currentSelectedOrderDeliveryStatus = orderObject.deliveryStatus;
    },

    //function to create temporary order
    temporaryOrder(order) {
      this.currentTemporaryOrder = {
        orderStatus: "Active",
        deliveryStatus: "Not Initiated",
        paymentStatus: "Not Completed",
        ...order,
      };
      return true;
    },

    //function to create order
    async createCompleteOrder() {
      //get the current user id to attach with the Order so that it helps in segregating
      if (!userCreation().isLoggedIn) {
        toast.info("Please LogIn/SignUp");
        return;
      }

      var order = this.currentTemporaryOrder;
      if (order === null) {
        return false;
      }

      var signal = false;

      this.allConfirmedOrdersOfaUser.forEach((x) => {
        if (x.orderId === order.orderId) {
          signal = true;
        }
      });
      this.allOrdersForAdmin.forEach((x) => {
        if (x.orderId === order.orderId) {
          signal = true;
        }
      });

      //wait for payment status here,when payment is done then create the complete order

      if (!signal) {
        const completeOrder = {
          orderStatus: order.orderStatus,
          deliveryStatus: order.deliveryStatus,
          paymentStatus: "paid",
          orderId: order.orderId,
          deliveryAddress: order.deliveryAddress,
          orderedItemsList: order.orderedItemsList,
          orderCreateTime: getDesiredDateTime(),
          expectedDeliveryTime: getDesiredDateTime(true),
          orderPersonName: userCreation().extractedCustomUser.name,
        };

        //collection Reference
        var collectionReference = await createCollectionReference(
          "confirmedOrdersOfaUser"
        );

        if (collectionReference !== null) {
          await addDoc(collectionReference, completeOrder);
          await addDoc(
            collection(db, "allConfirmedOrdersForAdminUse"),
            completeOrder
          ).then(() => {
            toast.success("Order Received");
          });
        }

        this.allConfirmedOrdersOfaUser.push(completeOrder);
        this.allOrdersForAdmin.push(completeOrder);

        this.currentTemporaryOrder = null;
        // toast.success("Order Received");
        return true;
      }
      return false;
    },

    //extract orders from backend
    async extractOrdersFromBackend(isUser) {
      if (isUser) {
        //collection Reference
        var collectionReference = await createCollectionReference(
          "confirmedOrdersOfaUser"
        );
        if (collectionReference !== null) {
          const querySnapshot = await getDocs(collectionReference);
          this.allConfirmedOrdersOfaUser = [];
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              if (doc.exists()) {
                this.allConfirmedOrdersOfaUser.push(doc.data());
              }
            });
          }
        }
      } else {
        const querySnapshot = await getDocs(
          collection(db, "allConfirmedOrdersForAdminUse")
        );
        this.allOrdersForAdmin = [];
        querySnapshot.forEach((doc) => {
          if (doc.exists()) {
            this.allOrdersForAdmin.push(doc.data());
          }
        });
      }
    },

    //function to change the Order status to either completed or cancelled
    async changeOrderStatus(orderId, newStatus) {
      //get the targeted order
      var targetOrder = this.currentSelectedOrderForAdmin;
      // console.log(targetOrder);

      this.currentSelectedOrderStatus = targetOrder.orderStatus;

      if (targetOrder === null) {
        // console.log("target is null");
        return;
      }
      if (targetOrder.deliveryStatus !== "Delivered") {
        toast.warning(
          "Change is not allowed,since order is yet to be delivered!"
        );

        return this.currentSelectedOrderStatus;
      }

      if (targetOrder.orderStatus === "Fulfilled") {
        toast.info("Order is already fulfilled!");

        return this.currentSelectedOrderStatus;
      }

      if (targetOrder.orderStatus === newStatus) {
        toast.info("No Change occurred!");

        return this.currentSelectedOrderStatus;
      } else {
        //delete the targeted order from the all orders list
        this.allConfirmedOrdersOfaUser = this.allConfirmedOrdersOfaUser.filter(
          (order) => order.orderId !== orderId
        );

        //delete the targeted order from the order list of the admin
        this.allOrdersForAdmin = this.allOrdersForAdmin.filter(
          (order) => order.orderId !== orderId
        );

        //modify the targeted order
        const modifiedOrder = {
          orderStatus: newStatus,
          deliveryStatus: targetOrder.deliveryStatus,
          orderId: targetOrder.orderId,
          deliveryAddress: targetOrder.deliveryAddress,
          orderedItemsList: targetOrder.orderedItemsList,
          paymentStatus: targetOrder.paymentStatus,
          orderCreateTime: targetOrder.orderCreateTime,
          expectedDeliveryTime: targetOrder.expectedDeliveryTime,
          orderPersonID: targetOrder.orderPersonID,
        };

        if (newStatus === "Fulfilled") {
          //target order id from confirmedorders from backend
          const querySnapshot = await getDocs(
            collection(db, "allConfirmedOrdersForAdminUse")
          );

          //targetId from adminOrderCollection
          var targetId = "";
          querySnapshot.forEach((doc) => {
            if (doc.exists()) {
              if (doc.data().orderId === orderId) {
                targetId = doc.id;
              }
            }
          });
          const orderRef = doc(db, "allConfirmedOrdersForAdminUse", targetId);

          if (modifiedOrder !== null) {
            await updateDoc(orderRef, modifiedOrder).then(async () => {
              const querySnapshot = await getDocs(
                collection(db, "allConfirmedOrdersForAdminUse")
              );

              this.allOrdersForAdmin = [];
              querySnapshot.forEach((doc) => {
                if (doc.exists()) {
                  this.allOrdersForAdmin.push(doc.data());
                }
              });
              toast.success("Successfully edited");
            });
          }

          //HERE THE USER SHOULD BE FOUND AT FIRST TO PERFORM THIS OPERATION

          //collection Reference
          var collectionReferenceFromUser = await createCollectionReference(
            "confirmedOrdersOfaUser",
            true,
            targetOrder.orderPersonID
          );

          if (collectionReferenceFromUser !== null) {
            //target order id from confirmedorders from backend
            const querySnapshot2 = await getDocs(collectionReferenceFromUser);
            var target = "";
            //targetId from userOrderCollection
            if (querySnapshot2 !== null) {
              querySnapshot2.forEach((doc) => {
                if (doc.exists()) {
                  if (doc.data().orderId === orderId) {
                    target = doc.id;
                  }
                }
              });
            }

            const newOrderRef = doc(collectionReferenceFromUser, target);

            if (newOrderRef !== null) {
              if (modifiedOrder !== null) {
                await updateDoc(newOrderRef, modifiedOrder)
                  .then(() => {
                    this.currentSelectedOrderForAdmin = modifiedOrder;
                    toast.success("This Order is complete and delivered now!");
                    this.currentSelectedOrderStatus = "Fulfilled";
                  })
                  .catch(() => {
                    console.log("error");
                  });
              }
            }
          }

          return this.currentSelectedOrderStatus;
        } else {
          toast.info("Permission denied");
          return this.currentSelectedOrderStatus;
        }
      }
    },

    //function to change the DeliveryStatus to either completed or cancelled
    async changeOrderDeliveryStatus(orderId, newDeliveryStatus) {
      //get the targeted order
      const targetOrder = this.allOrdersForAdmin.filter(
        (order) => order.orderId === orderId
      );

      this.currentSelectedOrderDeliveryStatus = targetOrder[0].deliveryStatus;

      // console.log("change delivery of ", targetOrder);

      if (newDeliveryStatus === "Not Initiated") {
        toast.info("Cant undo delivery status!");
        return this.currentSelectedOrderDeliveryStatus;
      }

      if (targetOrder[0].deliveryStatus === "Delivered") {
        toast.info("Cant undo already delivered Order!");
        return this.currentSelectedOrderDeliveryStatus;
      }

      if (newDeliveryStatus === targetOrder[0].deliveryStatus) {
        toast.info("No Change done!");
        return this.currentSelectedOrderDeliveryStatus;
      }

      //delete the targeted order from the all orders list
      this.allConfirmedOrdersOfaUser = this.allConfirmedOrdersOfaUser.filter(
        (order) => order.orderId !== orderId
      );

      //delete the targeted order from the order list of the admin
      this.allOrdersForAdmin = this.allOrdersForAdmin.filter(
        (order) => order.orderId !== orderId
      );

      //modify the targeted order
      const modifiedOrder = {
        orderStatus: targetOrder[0].orderStatus,
        deliveryStatus: newDeliveryStatus,
        orderId: targetOrder[0].orderId,
        deliveryAddress: targetOrder[0].deliveryAddress,
        orderedItemsList: targetOrder[0].orderedItemsList,
        paymentStatus: targetOrder[0].paymentStatus,
        orderCreateTime: targetOrder[0].orderCreateTime,
        expectedDeliveryTime: targetOrder[0].expectedDeliveryTime,
        orderPersonID: targetOrder[0].orderPersonID,
      };

      var isChnaged = false;

      //target order id from confirmedorders from backend
      const querySnapshot = await getDocs(
        collection(db, "allConfirmedOrdersForAdminUse")
      );
      //targetId from adminOrderCollection
      var targetIdFromAdminOrders = "";
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          if (doc.data().orderId === targetOrder[0].orderId) {
            targetIdFromAdminOrders = doc.id;
          }
        }
      });
      const orderRef = doc(
        db,
        "allConfirmedOrdersForAdminUse",
        targetIdFromAdminOrders
      );

      if (modifiedOrder !== null) {
        await updateDoc(orderRef, modifiedOrder).then(async () => {
          const querySnapshot = await getDocs(
            collection(db, "allConfirmedOrdersForAdminUse")
          );

          this.allOrdersForAdmin = [];
          querySnapshot.forEach((doc) => {
            if (doc.exists()) {
              this.allOrdersForAdmin.push(doc.data());
            }
          });
          isChnaged = true;
          // console.log("modified in backend admin orders");
        });
      }

      //HERE THE USER SHOULD BE FOUND AT FIRST TO PERFORM THIS OPERATION
      //collection Reference
      var collectionReferenceFromUser = await createCollectionReference(
        "confirmedOrdersOfaUser",
        true,
        targetOrder[0].orderPersonID
      );

      if (collectionReferenceFromUser !== null) {
        const querySnapshot2 = await getDocs(collectionReferenceFromUser);

        //targetId from userOrderCollection
        var targetFromUserCollection = "";

        if (querySnapshot2 !== null) {
          querySnapshot2.forEach((doc) => {
            if (doc.exists()) {
              if (doc.data().orderId === targetOrder[0].orderId) {
                targetFromUserCollection = doc.id;
              }
            }
          });
        }
        const newOrderRef = doc(
          collectionReferenceFromUser,
          targetFromUserCollection
        );
        if (modifiedOrder !== null) {
          await updateDoc(newOrderRef, modifiedOrder).then(() => {
            isChnaged = true;
          });
        }
      }

      if (isChnaged) {
        if (newDeliveryStatus === "In Route") {
          //re insert the order after modification
          this.allConfirmedOrdersOfaUser.push(modifiedOrder);
          // this.allOrdersForAdmin.push(modifiedOrder);
          this.currentSelectedOrderForAdmin = modifiedOrder;

          toast.info("Delivery Status modified!");
          this.currentSelectedOrderDeliveryStatus = "In Route";

          return this.currentSelectedOrderDeliveryStatus;
        } else {
          this.currentSelectedOrderForAdmin = modifiedOrder;
          toast.info("Delivery Status is set to Delivered!");
          this.currentSelectedOrderDeliveryStatus = "Delivered";

          return this.currentSelectedOrderDeliveryStatus;
        }
      }
    },

    //function to create a wishlist
    async createWishList(item) {
      if (!userCreation().isLoggedIn) {
        toast.info("Please LogIn/SignUp to continue!");
        return;
      }

      var allWishlistItemIds = [];

      this.userWishList.forEach((x) => {
        if (!allWishlistItemIds.includes(x.id)) {
          allWishlistItemIds.push(x.id);
        }
      });

      if (!allWishlistItemIds.includes(item.id)) {
        this.userWishList.push(item);

        var collectionReference = await createCollectionReference(
          "wishlistOfUser"
        );
        // console.log(collectionReference);

        if (collectionReference !== null) {
          await addDoc(collectionReference, item)
            .then(() => {
              toast.success("Added to Wishlist");
            })
            .catch(() => {
              console.log("failed to add in wishlist in firebase");
            });
        }
      } else {
        toast.info("Already Exists in Wislist");
      }
    },

    //function to extract user wishlist from firebase
    async extractUserWishlist() {
      if (!userCreation().isLoggedIn) {
        return;
      }

      var collectionReference = await createCollectionReference(
        "wishlistOfUser"
      );

      if (collectionReference !== null) {
        const querySnapshot = await getDocs(collectionReference).catch(() => {
          console.log("couldnot extract");
        });
        if (!querySnapshot.empty) {
          this.userWishList = [];
          querySnapshot.forEach((item) => {
            this.userWishList.push(item.data());
          });
        }
      }
    },

    //remove item from wishlist
    async removeWishlistItem(id) {
      //local remove
      var filtered = this.userWishList.filter((x) => x.id !== id);
      this.userWishList = filtered;

      //remove from server
      var targetId = "";

      var collectionReference = await createCollectionReference(
        "wishlistOfUser"
      );

      if (collectionReference !== null) {
        const querySnapshot = await getDocs(collectionReference);

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            if (doc.data().id === id) {
              targetId = doc.id;
            }
          });
          const addressRef = doc(collectionReference, targetId);
          await deleteDoc(addressRef);
        }
      }
      return this.userWishList;
    },

    //set selected Order for Admin
    setSelectedOrderForAdmin(order) {
      this.currentSelectedOrderForAdmin = order;
      this.currentSelectedOrderStatus = order.orderStatus;
      this.currentSelectedOrderDeliveryStatus = order.deliveryStatus;
      // console.log(this.currentSelectedOrderForAdmin);
    },
  },
  persist: !true,
});
