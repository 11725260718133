import { defineStore } from "pinia";
import { v4 as uid } from "uuid";
import { useToast } from "vue-toastification";
import { db } from "@/firebase/firebase-init.js";

import { trackChangesInTheDatabase } from "@/stores/track-changed-collections-by-admin";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";

const toast = useToast();

export const PostCollectionStore = defineStore("postCollection", {
  state: () => ({
    PostCollection: [],
    FilteredCollection: [],
    selectedIndex: Number,
    modifiedIndex: Object,
    downloadedQuerySnapshot: [],
    LastSnapshotUpdateTime: [],
  }),
  getters: {
    getPost: (state) => {
      return state.PostCollection;
    },
  },
  actions: {
    async createNewPost(post) {
      var postObject = {
        id: uid(),
        ...post,
      };
      this.PostCollection.push(postObject);
      await addDoc(collection(db, "allPosts"), postObject).then(() => {
        toast.success("New post added");
      });
      var timeObj = new Date();
      var LastSnapshotUpdateObject = {
        date: timeObj.getDate(),
        time: timeObj.getTime(),
        month: timeObj.getMonth(),
        year: timeObj.getFullYear(),
      };
      await trackChangesInTheDatabase().addNewDatabaseChange(
        "post",
        LastSnapshotUpdateObject
      );
    },

    //extract all posts from firebase
    async extractAllPostsFromFirebase() {
      //this will be executed if there is no chnage but local post collection is zero
      if (this.PostCollection.length === 0) {
        const querySnapshot = await getDocs(collection(db, "allPosts"));

        this.LastSnapshotUpdateTime = [];
        var timeObj = new Date();
        var LastSnapshotUpdateObject = {
          date: timeObj.getDate(),
          time: timeObj.getTime(),
          month: timeObj.getMonth(),
          year: timeObj.getFullYear(),
        };

        this.LastSnapshotUpdateTime.push(LastSnapshotUpdateObject);

        this.downloadedQuerySnapshot = querySnapshot;
        this.PostCollection = [];
        querySnapshot.forEach((doc) => {
          this.PostCollection.push(doc.data());
        });
      } else {
        var hasChange = false;
        await trackChangesInTheDatabase()
          .checkChangeInDatabase("post")
          .then(() => {
            var changeObj = trackChangesInTheDatabase().postChangeObject;
            if (changeObj === null) {
              return;
            }

            if (
              JSON.stringify(changeObj) ===
              JSON.stringify(this.LastSnapshotUpdateTime[0])
            ) {
              return;
            } else {
              if (changeObj.date < this.LastSnapshotUpdateTime[0].date) {
                hasChange = false;
              } else if (changeObj.year < this.LastSnapshotUpdateTime[0].year) {
                hasChange = false;
              } else if (changeObj.time < this.LastSnapshotUpdateTime[0].time) {
                hasChange = false;
              } else if (
                changeObj.month < this.LastSnapshotUpdateTime[0].month
              ) {
                hasChange = false;
              } else {
                hasChange = true;
              }
            }
          });

        //run this is there is a chnage
        if (hasChange) {
          const querySnapshot = await getDocs(collection(db, "allPosts"));

          this.LastSnapshotUpdateTime = [];
          var timeObj2 = new Date();
          var LastSnapshotUpdateObject2 = {
            date: timeObj2.getDate(),
            time: timeObj2.getTime(),
            month: timeObj2.getMonth(),
            year: timeObj2.getFullYear(),
          };

          this.LastSnapshotUpdateTime.push(LastSnapshotUpdateObject2);

          this.downloadedQuerySnapshot = querySnapshot;
          this.PostCollection = [];
          querySnapshot.forEach((doc) => {
            this.PostCollection.push(doc.data());
          });
        }
      }
    },

    setSelectedPostId(postId) {
      this.selectedIndex = postId;
    },

    async editPost(postId, modifiedPost) {
      if (postId.length !== 0) {
        var targetId = "";
        const querySnapshot = await getDocs(collection(db, "allPosts"));

        querySnapshot.forEach((doc) => {
          if (doc.data().id === postId) {
            targetId = doc.id;
          }
        });

        const addressRef = doc(db, "allPosts", targetId);

        if (modifiedPost !== null) {
          await updateDoc(addressRef, modifiedPost).then(async () => {
            const querySnapshot = await getDocs(collection(db, "allPosts"));

            this.PostCollection = [];
            querySnapshot.forEach((doc) => {
              this.PostCollection.push(doc.data());
            });
            toast.success("Successfully edited");
          });
          var timeObj = new Date();
          var LastSnapshotUpdateObject = {
            date: timeObj.getDate(),
            time: timeObj.getTime(),
            month: timeObj.getMonth(),
            year: timeObj.getFullYear(),
          };
          await trackChangesInTheDatabase().addNewDatabaseChange(
            "post",
            LastSnapshotUpdateObject
          );
          return true;
        }
        return false;
      }
      return false;
    },

    async deletePost(postId) {
      var targetId = "";
      const querySnapshot = await getDocs(collection(db, "allPosts"));

      querySnapshot.forEach((doc) => {
        if (doc.data().id === postId) {
          targetId = doc.id;
        }
      });

      const addressRef = doc(db, "allPosts", targetId);

      await deleteDoc(addressRef).then(async () => {
        const querySnapshot = await getDocs(collection(db, "allPosts"));

        this.PostCollection = [];
        querySnapshot.forEach((doc) => {
          this.PostCollection.push(doc.data());
        });
        toast.success("Post deleted!");
      });
      var timeObj = new Date();
      var LastSnapshotUpdateObject = {
        date: timeObj.getDate(),
        time: timeObj.getTime(),
        month: timeObj.getMonth(),
        year: timeObj.getFullYear(),
      };
      await trackChangesInTheDatabase().addNewDatabaseChange(
        "post",
        LastSnapshotUpdateObject
      );
    },
  },
  persist: true,
});
