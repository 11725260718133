<template>
  <div class="active-order" @click="goToPage('full-order-view')">
    <div class="order-status">
      <button>
        <p>
          Order Status: <b>{{ completeOrder.orderStatus }}</b>
        </p>
      </button>
    </div>
    <div class="item-list">
      <h6><b>Item List</b></h6>
      <ol class="list-group list-group-numbered">
        <li v-for="(v, i) in extractedBooks" :key="i">{{ v.bookName }}</li>
      </ol>
    </div>
    <div class="total-price">
      <p>
        Total:
        <b
          >{{ Number(totalOrderValue).toFixed(2) }}
          <i class="fa-solid fa-indian-rupee-sign fa-sm"></i
        ></b>
      </p>
    </div>
    <div class="payment-status">
      <p>
        Payment Status: <b>{{ completeOrder.paymentStatus }}</b>
      </p>
    </div>
    <div class="delivery-status">
      <p>
        Delivery Status: <b>{{ completeOrder.deliveryStatus }}</b>
      </p>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { onMounted, ref } from "@vue/runtime-core";
import { uploadBooks } from "@/stores/upload-book";
import { bookOrderCollection } from '@/stores/bookorder-collection';

export default {
  name: "user-order",

  props: { completeOrder: Object },
  setup(props) {
    //book collection instance from the store
    const booksCollection = uploadBooks();
    //order collection instance
    const orderCollection=bookOrderCollection();
    
    const orderedItemList = ref([]);
    const totalOrderValue = ref("");

    //list to hold the id's of the items
    const itemIdList = [];

    //list to hold the extracted Books which matches with our ordered item list
    const extractedBooks = ref([]);

    //navigate to another page
    function goToPage(name) {
      orderCollection.setCurrentSelectedOrderForViewing(props.completeOrder);
      router.push({
        name: name,
      });
      // orderCollection.$reset();
    }

    onMounted(() => {
      orderedItemList.value = props.completeOrder.orderedItemsList;      
      var price = 0;
      orderedItemList.value.forEach((item) => {
        itemIdList.push(item.itemId);
        price += item.totalItemPrice;
      });
      totalOrderValue.value = price;
      booksCollection.uploadedBooks.forEach((book) => {
        const found = itemIdList.includes(book.id);
        if (found) {
          extractedBooks.value.push(book);
        }
      });
    });

    return {
      goToPage,
      orderedItemList,
      totalOrderValue,
      extractedBooks,
    };
  },
};
</script>

<style lang="scss" scoped>
.active-order {
  width: 85%;
  height: 300px;
  margin: 20px auto;
  cursor: pointer;
  @media (max-width: 576px) {
    width: 95%;
  }

  .order-status {
    background: transparent;
    button {
      border: none;
      outline: none;
      padding: 1px 10px;
      margin: 2px 3px;
      border-radius: 10px;
      p {
        font-size: 11px;
      }
    }
  }

  //item-list
  .item-list {
    height: 50%;
    background-image: linear-gradient(
      to right top,
      #051937,
      #04234f,
      #042e67,
      #0a3881,
      #16439b
    );
    overflow: scroll;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    h6 {
      margin: 4px 6px;
      font-size: 14.5px;
      text-decoration: underline;
      color: white;
    }
    li {
      font-size: 13px;
      font-weight: 600;
      margin: 4px 10px;
      color: white;
    }
  }
  .total-price,
  .payment-status,
  .delivery-status {
    padding: 5px 20px;

    background-image: linear-gradient(
      to right top,
      #051937,
      #04234f,
      #042e67,
      #0a3881,
      #16439b
    );

    p {
      font-size: 13px;
      color: white;
      letter-spacing: 0.5px;
    }
  }

  .delivery-status {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
</style>