<template>
  <section class="post-section">
    <div class="col-lg-9 col-md-7 col-sm-12 col-xs-12 all-posts">
      <section class="background-color-layer">
        <singlePost
          v-for="(v, i) in postList"
          :key="i"
          :post="v"
          :index="i"
        ></singlePost>
      </section>
    </div>

    <div class="col-lg-3 col-md-5 col-sm-0 col-xs-0 post-categories">
      <div class="category-list">
        <h6>Types of Posts</h6>

        <div class="categories">
          <ol>
            <li @click="clicked(0)">Post Update ({{ noOfPostupdate }})</li>
            <li @click="clicked(1)">Announcement ({{ noOfAnnouncemewnt }})</li>
            <li @click="clicked(2)">Book Launch ({{ noOfBookLaunch }})</li>
            <li @click="clicked(3)">Discussion ({{ noOfDiscussionList }})</li>
            <li @click="clicked(4)">
              Other Activities ({{ noOfOtherActivities }})
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import singlePost from "./single-post.vue";

//importing the store
// import { PostCollectionStore } from "@/stores/posts";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

export default {
  components: { singlePost },

  setup() {
    // const postCollection = PostCollectionStore();

    const noOfPostupdate = ref(0);
    const noOfAnnouncemewnt = ref(0);
    const noOfBookLaunch = ref(0);
    const noOfDiscussionList = ref(0);
    const noOfOtherActivities = ref(0);

    function clicked(id) {
      if (id === 0) {
        postList.value = postUpdateList.value;
        noOfPostupdate.value = postUpdateList.value.length;
      }
      if (id === 1) {
        postList.value = announcementList.value;
        noOfAnnouncemewnt.value = announcementList.value.length;
      }
      if (id === 2) {
        postList.value = booklaunchList.value;
        noOfBookLaunch.value = booklaunchList.value.length;
      }
      if (id === 3) {
        postList.value = discussionList.value;
        noOfDiscussionList.value = discussionList.value.length;
      }
      if (id === 4) {
        postList.value = otherActivitiesList.value;
        noOfOtherActivities.value = otherActivitiesList.value.length;
      }
    }

    const postList = ref([]);

    const postUpdateList = ref([]);
    const announcementList = ref([]);
    const booklaunchList = ref([]);
    const discussionList = ref([]);
    const otherActivitiesList = ref([]);

    //check n then insert to list
    function uniquelyInsertToList(list, x) {
      console.log(list);

      // var idList = [];
      // list.forEach((v) => {
      //   if (!idList.includes(v.id)) {
      //     console.log(v.id);
      //     idList.push(v.id);
      //   }
      // });
      // if (!idList.includes(x.id)) {
      // }
      list.push(x);
    }

    //run it after n before mount

    //ref variable to hold post image
    const postImage = ref([]);
    //ref variable to hold other images related to post
    const otherImages = ref([]);
    const importantLinks = ref([]);
    // const description = ref([
    //   "This is a Test Case that whether the post works",
    // ]);

    function countNUpdate() {
      // postList.value = postCollection.PostCollection.reverse();
      postList.value = [
        {
          time: "1 September: 11:15 PM",
          PostedBy: "Admin",
          type: "Announcement",
          postImage: postImage.value,
          title: "Our latest Issue",
          otherImage: otherImages.value,
          importantLinks: importantLinks.value,
          description:
            "Dear Readers and contributors, We are very pleased to present you all with the online version of our latest issue, i.e VOL-X Issue-I, Please check !",
        },
        {
          time: "1 September: 11 PM",
          PostedBy: "Admin",
          type: "Announcement",
          postImage: postImage.value,
          title: "We are back live",
          otherImage: otherImages.value,
          importantLinks: importantLinks.value,
          description:
            "We have been offline for about a month due to various technical reasons, we are extremely sorry for that. Happy to be back again among you guyz!!! ",
        },
      ];

      postList.value.forEach((x) => {
        // console.log("running");
        if (x.type === "Other Activities") {
          uniquelyInsertToList(otherActivitiesList.value, x);
        }
        if (x.type === "Discussion") {
          uniquelyInsertToList(discussionList.value, x);
        }
        if (x.type === "Post Update") {
          console.log("Post Update");
          uniquelyInsertToList(postUpdateList.value, x);
        }
        if (x.type === "Book Launch") {
          uniquelyInsertToList(booklaunchList.value, x);
        }
        if (x.type === "Announcement") {
          console.log("Announcement");
          uniquelyInsertToList(announcementList.value, x);
        }
      });
      noOfPostupdate.value = postUpdateList.value.length;
      noOfAnnouncemewnt.value = announcementList.value.length;
      noOfBookLaunch.value = booklaunchList.value.length;
      noOfDiscussionList.value = discussionList.value.length;
      noOfOtherActivities.value = otherActivitiesList.value.length;
    }

    onMounted(() => {
      countNUpdate();

      // postCollection.extractAllPostsFromFirebase().then(() => {
      //   countNUpdate();
      // });
    });

    return {
      clicked,
      // postCollection,
      postList,
      postUpdateList,
      announcementList,
      booklaunchList,
      discussionList,
      otherActivitiesList,
      noOfPostupdate,
      noOfAnnouncemewnt,
      noOfBookLaunch,
      noOfDiscussionList,
      noOfOtherActivities,
    };
  },
};
</script>

<style lang="scss" scoped>
.post-section {
  padding: 0;
  display: flex;
  background-color: #172640;

  .all-posts {
    background-image: url("@/assets/images/sky-34536.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    border-radius: 15px;

    margin-left: 6px;
  }

  .background-color-layer {
    // background-color: rgba(0, 0, 0, 0.65);
    // background-color: red;
    border-radius: 15px;
    padding: 20px 0px;
    @media (max-width: 576px) {
      width: 96.7vw;
      padding: 20px auto;
    }
  }

  .post-categories {
    background-color: #172640;
    margin: 0px;
    position: relative;

    @media (max-width: 576px) {
      display: none;
    }

    .category-list {
      height: 270px;
      width: 95%;
      position: sticky;
      top: 40%;
      background-color: #1c4d8a;
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid black;

      margin: 0 6.5px;

      h6 {
        font-size: 14px;
        color: white;
        font-weight: 600;
        text-align: start;
        padding-top: 10px;
        padding-left: 3rem;
        margin: 10px auto;
        text-decoration: underline;
      }

      .categories {
        padding: 10px 35px;

        ol {
          padding-left: 10px;

          li {
            font-size: 12.5px;
            font-weight: 600;
            padding-left: 8px;
            // text-decoration: underline;
            line-height: 35px;
            color: whitesmoke;
            box-shadow: 10px 10px 48px 0px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 10px 10px 48px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 10px 10px 48px 0px rgba(0, 0, 0, 0.75);

            &:hover {
              color: goldenrod;
              font-size: 12.6px;
              transform: translateY(-2px);
              transition: all 300ms ease-in 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
