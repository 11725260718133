<template>
  <div class="sub-navigation">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-10">
          <div class="left-content">
            <p>ISSN : <em>2454-3837</em></p>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-2">
          <div class="right-icons">
            <ul>
              <li>
                <a><i class="fa-brands fa-facebook"></i></a>
              </li>
              <li>
                <a><i class="fa-brands fa-twitter"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="navigation">
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-2">
      <div class="logo-area">
        <router-link :to="{ name: 'home-page' }" class="logo"
          >Sampriti</router-link
        >
      </div>
    </div>
    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-10">
      <div class="nav-area">
        <router-link :to="{ name: 'home-page' }" class="nav-link"
          >HOME</router-link
        >
        <router-link :to="{ name: 'about-sampriti' }" class="nav-link"
          >ABOUT</router-link
        >
        <router-link :to="{ name: 'editorial-board' }" class="nav-link"
          >EDITORIAL BOARD</router-link
        >
        <div
          class="nav-link"
          id="sub-menu-clicker"
          @click.stop="showOrHideSubMenu(false)"
        >
          <span>IMPORTANT-LINKS<i class="fa-solid fa-angle-down"></i></span>
        </div>
        <!-- <router-link :to="{ name: '' }" class="nav-link">IMPORTANT-LINKS</router-link> -->
        <router-link :to="{ name: 'published-issues' }" class="nav-link"
          >PUBLISHED ISSUES</router-link
        >
        <!-- <router-link
          :to="{ name: 'signin-login' }"
          class="nav-link"
          v-if="!isLoggedIn"
          >LOGIN/REGISTER</router-link
        > -->
        <li
          v-if="isLoggedIn && !isMainNavigationTransformed"
          class="nav-link"
          @click.stop="showOrHideSubMenu(true)"
        >
          <i class="fa-solid fa-user-check fa-lg"></i>
        </li>
      </div>

      <div class="menu-icon" @click="toggleMobileNav">
        <i class="fa-solid fa-bars"></i>
      </div>
    </div>
  </div>
  <!-- mobile navigation sidemenu -->
  <div class="mobile-navigation" ref="mobile" @click="toggleMobileNav">
    <div class="side-menu">
      <div class="first-section">
        <div class="profile-pic-area">
          <div class="profile-pic">
            <!-- <i class="fa-solid fa-user-tie fa-lg"></i> -->
            <img src="@/assets/logo.png" alt="" srcset="" />
          </div>
        </div>

        <!-- <div class="user-info" @click="goToAccountPage"> -->
        <div class="user-info">
          <h6>
            <b> {{ createuser.userName }} </b>
            <!-- <span>
              <button v-if="isLoggedIn">
                <i class="fa-solid fa-chevron-right fa-sm"></i>
              </button>
            </span> -->
          </h6>
        </div>
      </div>
      <div class="second-section">
        <div class="page-link" v-for="(v, i) in pageList" :key="i">
          <p @click="goToPage(v.link)">{{ v.name }}</p>
        </div>
      </div>
      <div class="third-section">
        <div class="logo-space">
          <img src="@/assets/logo.png" alt="" />
        </div>
        <!-- <div class="contact">
          <p v-if="!isLoggedIn" @click="goToPage('signin-login')">
            SignUp/LogIn
          </p>
          <p v-else @click="toggleLogIn">LogOut</p>
        </div> -->
      </div>
    </div>
  </div>

  <!-- submenu container -->

  <teleport to="body">
    <div class="subMenuHouse" v-if="showSubMenuHouse">
      <ul class="subMenuOne" v-if="showSubMenuOne">
        <!-- <li>
          <router-link :to="{ name: 'photo-gallery' }" class="sub-link"
            >Photo Gallery</router-link
          >
        </li> -->
        <li>
          <router-link :to="{ name: 'guide-lines' }" class="sub-link"
            >Guidelines</router-link
          >
        </li>
        <!-- <li>
          <router-link :to="{ name: 'buy-books' }" class="sub-link"
            >Buy Books</router-link
          >
        </li> -->
        <li>
          <router-link :to="{ name: 'contact-us' }" class="sub-link"
            >Contact Us</router-link
          >
        </li>
      </ul>
      <!-- account-menu -->
      <ul class="account-menu2" v-if="showAccountMenu2">
        <li>
          <router-link
            :to="{ name: 'admin-options' }"
            class="accountMenu-link"
            v-if="isAdmin"
            >Admin Area</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'account-info' }" class="accountMenu-link"
            >Account Info</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'my-wishlist' }" class="accountMenu-link"
            >My Wishlist</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'my-shopping-cart' }"
            class="accountMenu-link"
            >My Cart</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'current-orders' }" class="accountMenu-link"
            >Active Orders</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'previous-orders' }"
            class="accountMenu-link"
            >Previous Orders</router-link
          >
        </li>
        <li>
          <router-link :to="{}" class="accountMenu-link" @click="toggleLogIn"
            >Log-Out</router-link
          >
        </li>
      </ul>
    </div>
  </teleport>
</template>

<script>
import { ref } from "@vue/reactivity";
// import { computed } from "@vue/runtime-core";
import router from "@/router";

import { userCreation } from "@/stores/user-verification";
import { storeToRefs } from "pinia";
import { onBeforeMount } from "@vue/runtime-core";

export default {
  setup() {
    const isAdmin = ref(false);

    //list containing page names
    const pageList = [
      {
        name: "Home",
        link: "home-page",
      },
      {
        name: "About",
        link: "about-sampriti",
      },
      {
        name: "Editorial Board",
        link: "editorial-board",
      },
      {
        name: "Guidelines",
        link: "guide-lines",
      },
      {
        name: "Published Issues",
        link: "published-issues",
      },
      // {
      //   name: "Buy Books",
      //   link: "buy-books",
      // },
      // {
      //   name: "Photo Gallery",
      //   link: "photo-gallery",
      // },
      {
        name: "Contact Us",
        link: "contact-us",
      },
    ];

    const createuser = userCreation();

    // whether the user is loggedin
    const { isLoggedIn } = storeToRefs(createuser);
    const { userName } = storeToRefs(createuser);

    onBeforeMount(() => {
      createuser.observerLoggedInState();
      isAdmin.value = createuser.isAdmin;
      // console.log("running from before mount in navigation");
    });

    function toggleLogIn() {
      createuser.logout();
      router.push({
        name: "home-page",
      });
    }

    //show or hide the accountMenuIcon for main navigation depending on whether mobile nav is active or not
    const isMainNavigationTransformed = ref(false);

    //toshowSubmenyHouse
    const showSubMenuHouse = ref(false);

    const showSubMenuOne = ref(false);
    const showAccountMenu2 = ref(false);

    const showMobileNav = ref(false);

    const mobile = ref(null);

    function toggleMobileNav() {
      showMobileNav.value = !showMobileNav.value;
      if (showMobileNav.value) {
        mobile.value.style.transform = "translateX(0)";
      } else {
        mobile.value.style.transform = "translateX(-100%)";
      }
    }

    //change the mainNavTransformProperty depending upon the innerwidth

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 827) {
        if (!isMainNavigationTransformed.value) {
          isMainNavigationTransformed.value = true;
        }
      } else {
        if (isMainNavigationTransformed.value) {
          isMainNavigationTransformed.value = false;
        }
      }
    });

    //function to navigate to respective page

    function goToPage(name) {
      router.push({
        name: name,
      });
    }

    //go to accountpage if logged in
    // const isuserAccountMobileView=ref(0);

    function goToAccountPage() {
      if (isLoggedIn) {
        goToPage("user-account-mobile-view");
      }
      return;
    }

    //function to show or hide submenus
    function showOrHideSubMenu(isAccountMenu) {
      var windowWidth = window.innerWidth;
      if (isAccountMenu) {
        if (windowWidth > 1120) {
          if (!showSubMenuOne.value) {
            showSubMenuHouse.value = !showSubMenuHouse.value;
          }
          showAccountMenu2.value = !showAccountMenu2.value;
        } else {
          if (!showAccountMenu2.value) {
            showSubMenuHouse.value = true;
            showSubMenuOne.value = false;
            showAccountMenu2.value = true;
          } else {
            showSubMenuOne.value = false;
            showAccountMenu2.value = false;
            showSubMenuHouse.value = false;
          }
        }
      } else {
        if (windowWidth > 1120) {
          if (!showAccountMenu2.value) {
            showSubMenuHouse.value = !showSubMenuHouse.value;
          }
          showSubMenuOne.value = !showSubMenuOne.value;
        } else {
          if (!showSubMenuOne.value) {
            showSubMenuHouse.value = true;

            showAccountMenu2.value = false;
            showSubMenuOne.value = true;
          } else {
            showAccountMenu2.value = false;
            showSubMenuOne.value = false;
            showSubMenuHouse.value = false;
          }
        }
      }
    }

    //hiding submenus when the window size is reduced to alarming level
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1120) {
        if (showSubMenuOne.value) {
          showSubMenuOne.value = false;
        }
        if (showAccountMenu2.value) {
          showAccountMenu2.value = false;
        }
        if (showSubMenuHouse.value) {
          showSubMenuHouse.value = false;
        }
      }
    });

    //function to hide the submenus when clicked on other parts of the window

    document.body.addEventListener("click", () => {
      if (showSubMenuHouse.value) {
        showSubMenuHouse.value = false;
        if (showSubMenuOne.value) {
          showSubMenuOne.value = false;
        }
        if (showAccountMenu2.value) {
          showAccountMenu2.value = false;
        }
      }
    });

    return {
      isLoggedIn,
      showMobileNav,
      toggleMobileNav,
      mobile,
      pageList,
      isMainNavigationTransformed,
      goToPage,
      goToAccountPage,
      showSubMenuHouse,
      showSubMenuOne,
      showAccountMenu2,
      showOrHideSubMenu,
      toggleLogIn,
      createuser,
      userName,
      isAdmin,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin make-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-navigation {
  background-color: #1f272b;

  .left-content {
    height: 100%;

    p {
      text-align: flex-start;
      color: white;
      font-size: 11px;
      padding-top: 15px;
      padding-left: 5px;
      letter-spacing: 0.8px;
      font-weight: 600;

      em {
        color: gold;
        font-size: 10px;
      }
    }

    @media (max-width: 1050px) {
      margin-left: 25px;
    }

    @media (max-width: 991px) {
      display: none;
    }
  }

  .right-icons {
    ul {
      display: flex;
      color: white;
      justify-content: flex-end;
      list-style: none;

      li {
        margin: 8px;
        font-size: 14.2px;
        transform: translateZ(5px);

        &:hover {
          color: yellow;
          cursor: pointer;
          transition: all 250ms ease;
        }
      }
    }
  }
}

.navigation {
  width: 100%;
  background-color: #1c4d8a;
  @include make-flex();

  padding: 0;

  @media (max-width: 827px) {
    background-image: linear-gradient(
      to right top,
      #051937,
      #004d7a,
      #008793,
      #00bf72,
      #a8eb12
    );
    justify-content: space-around;
  }

  @media (max-width: 576px) {
    justify-content: space-around;
  }

  .logo-area {
    flex: 1;
    width: 100%;
    height: 100%;
    text-align: center;

    .logo {
      line-height: 100px;
      color: gold;
      font-size: 38px;
      font-weight: 900;
      font-style: normal;
      font-family: "Great Vibes", cursive, "Franklin Gothic Medium";
      letter-spacing: 2px;

      @media (max-width: 1050px) {
        line-height: 100px;
        font-size: 35px;
      }

      @media (max-width: 827px) {
        color: whitesmoke;
        font-size: 32px;
      }

      &:hover {
        transform: translateY(-4px);
        transition: all 350ms ease-in-out;
        cursor: pointer;
        color: red;
      }
    }
  }

  .nav-area {
    flex: 3;
    @include make-flex();

    .nav-link {
      height: 70px;
      font-size: 12.3px;
      line-height: 40px;
      letter-spacing: 1px;
      font-weight: 800;
      color: white;
      margin-top: 25px;

      @media (max-width: 827px) {
        display: none;
      }

      &:hover {
        color: #f5a425;
        font-size: 12.2px;
        transform: translateX(1px);
        transition: all 150ms ease 0s;
      }

      @media (max-width: 1067px) {
        font-size: 10.9px;
        letter-spacing: 0.7px;
        font-weight: 800;
        padding: 0 10px;
        line-height: 40px;
      }
    }
  }

  .menu-icon {
    display: none;
    cursor: pointer;
    transition: all 300ms ease-in;
    &:hover {
      transform: rotate(360deg);
      color: goldenrod;
    }

    @media (max-width: 827px) {
      font-size: 25px;
      display: grid;
      place-content: center;
      color: black;
      float: right;
      font-weight: 700;
      padding-right: 70px;
    }

    @media (max-width: 576px) {
      padding-right: 1px;
      font-size: 22px;
    }
  }
}

#sub-menu-clicker {
  cursor: pointer;
}

.fa-user-check {
  cursor: pointer;
  margin: auto 10px;
  transition: all 250ms ease-in;

  &:hover {
    transform: scale(1.1);
  }
}

//mobile navigation
.mobile-navigation {
  position: fixed;
  inset: 0;
  // background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 20;
  transform: translateX(-100%);

  transition: all 700ms ease 10ms;

  .side-menu {
    height: 100%;
    width: 60%;
    background-image: linear-gradient(
      to right top,
      #0e2155,
      #00447a,
      #006899,
      #008db4,
      #16b3c9
    );
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
      rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

    @media (max-width: 475px) {
      width: 85%;

      background-image: linear-gradient(
        to right top,
        #051937,
        #004d7a,
        #008793,
        #00447a,
        #0e2155
      );
    }

    .first-section {
      flex: 1;
      display: flex;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

      .profile-pic-area {
        width: 39%;
        display: grid;
        place-content: center;
        .profile-pic {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          background: gray;
          // display: grid;
          place-content: center;
          margin-left: 10px;
          border: 1px solid yellow;
          cursor: pointer;
          img {
            height: 100%;
            width: 100%;
            border-radius: 50%; /* Ensure the image itself is circular */
            object-fit: cover; /* Ensure the image covers the entire div */
          }
          .fa-user-tie {
            color: yellow;
          }
        }
      }
      .user-info {
        width: 61%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: 475px) {
          margin-left: -19px;
        }

        h6 {
          font-size: 24px;
          color: whitesmoke;
          font-weight: 400;
          letter-spacing: 0.2px;
          margin-top: -5px;
          padding-left: 12px;
          text-decoration: underline;
          cursor: pointer;
          transition: all 350ms ease-in-out;
          &:hover {
            color: yellow;
            transform: scale(1.1);
          }
          button {
            border: none;
            outline: none;
            background: transparent;
            margin: auto 6px;
            color: whitesmoke;
            transition: all 350ms ease-in-out;
            &:hover {
              color: yellow;
              transform: scale(1.3);
            }
          }
        }
      }
    }
    .second-section {
      flex: 5;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .page-link {
        background-color: transparent;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
          rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        cursor: pointer;
        transition: all 350ms ease-in-out;
        &:hover {
          text-decoration: underline;
          transform: translateY(1px);
        }
        p {
          font-size: 12.8px;
          font-weight: 600;
          letter-spacing: 0.1px;
          padding-left: 35px;
          color: white;
          margin-bottom: -1px;
        }
      }
    }
    .third-section {
      flex: 1;
      display: flex;
      .logo-space {
        height: 100%;
        width: 37%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 50px;

        @media (max-width: 475px) {
          justify-content: flex-start;
          padding-left: 50px;
        }

        img {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          padding: 1px;
          box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
          transition: transform 500ms ease-in;
          &:hover {
            transform: scale(1.4);
          }
        }
      }
      .contact {
        height: 100%;
        width: 63%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 40px;

        @media (max-width: 475px) {
          padding-left: 40px;
        }

        p {
          font-size: 12px;
          font-weight: 600;
          color: white;
          transition: all 350ms ease-in 150ms;
          cursor: pointer;
          &:hover {
            font-size: 13px;
            color: yellow;
            text-decoration: underline;
            transform: skewY(-5deg);
          }
        }
      }
    }
  }
}

//submenu container

.subMenuHouse {
  position: absolute;
  top: 143px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 250px;
  width: 100%;
  background-color: transparent;
  z-index: 10;

  .subMenuOne {
    display: block;
    position: absolute;
    width: 190px;
    background-color: #1c4d8a;
    overflow: hidden;
    left: 58.5vw;
    // margin-left: -30px;
    li {
      border-bottom: 0.5px solid white;
      text-align: center;
      cursor: pointer;
      .sub-link {
        font-size: 12px;
        padding: 5px auto;
        letter-spacing: 0.8px;
        line-height: 40px;
        color: white;
        font-weight: 600;
        cursor: pointer;
        transition: all 350ms ease-in;
        &:hover {
          font-size: 12.5px;
          color: goldenrod;
        }
      }
    }
  }

  .account-menu2 {
    position: absolute;
    width: 240px;
    background-color: gray;
    right: 3vw;
    height: auto;
    border-radius: 5px;
    li {
      border-bottom: 5px solid gray;
      text-align: center;
    }

    .accountMenu-link {
      font-size: 12px;
      padding: 5px auto;
      letter-spacing: 0.8px;
      line-height: 40px;

      color: black;
      font-weight: 600;
      text-shadow: 0px 5px 10px #2e3436;
      cursor: pointer;
      &:hover {
        font-size: 12.5px;
        color: goldenrod;
      }
    }
  }
}
</style>
