import { defineStore } from "pinia";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { allUploadedFilesStorageRefCollection } from "@/stores/uploaded-files-storageRef-collection";

const storage = getStorage();

const metadata = {
  contentType: "image/jpeg",
};
const pdfMeta = {
  contentType: "application/pdf",
};

export const uploadFiles = defineStore("uploadFile", {
  state: () => ({
    uploadedSingleImage: [],
    uploadedPdf: [],
    multipleImages: [],
  }),
  getters: {},
  actions: {
    //reset multiple images list
    resetMultipleImagsList(){
      this.multipleImages=[]
      console.log(
        this.multipleImages
      );
    },

    async uploadPdfAndGetDownloadUrl(file) {
      this.uploadedPdf = [];

      var fileRef = "pdfs/" + file.name;
      // Upload file and metadata to the object 'images/mountains.jpg'
      const storageRef = ref(storage, fileRef);
      const uploadTask = uploadBytesResumable(storageRef, file, pdfMeta);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          switch (snapshot.state) {
            case "paused":
              break;
            case "running":
              break;
          }
        },
        (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              break;
            case "storage/canceled":
              break;
            case "storage/unknown":
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            if (this.uploadedPdf.length !== 0) {
              this.uploadedPdf = [];
            }
            if (!this.uploadedPdf.includes(downloadURL)) {
              this.uploadedPdf.push(downloadURL);
            }
            const refObject = {
              storageRef: fileRef,
              url: downloadURL,
            };
            allUploadedFilesStorageRefCollection().newStorageRefObject(
              refObject
            );
          });
        }
      );
    },
    async uploadSingleImageAndGetDownloadUrl(file) {
      this.uploadedSingleImage = [];

      var fileRef = "images/" + file.name;
      // Upload file and metadata to the object 'images/mountains.jpg'
      const storageRef = ref(storage, fileRef);
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          switch (snapshot.state) {
            case "paused":
              break;
            case "running":
              break;
          }
        },
        (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              break;
            case "storage/canceled":
              break;
            case "storage/unknown":
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            if (this.uploadedSingleImage.length !== 0) {
              this.uploadedSingleImage = [];
            }
            if (!this.uploadedSingleImage.includes(downloadURL)) {
              this.uploadedSingleImage.push(downloadURL);
            }
            console.log(this.uploadedSingleImage);

            const refObject = {
              storageRef: fileRef,
              url: downloadURL,
            };
            allUploadedFilesStorageRefCollection().newStorageRefObject(
              refObject
            );
          });
        }
      );
    },

    async uploadMultipleImageAndGetDownloadUrl(file) {
      var fileRef = "images/" + file.name;

      // Upload file and metadata to the object 'images/mountains.jpg'
      const storageRef = ref(storage, fileRef);
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          switch (snapshot.state) {
            case "paused":
              break;
            case "running":
              break;
          }
        },
        (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              break;
            case "storage/canceled":
              break;
            case "storage/unknown":
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            if (!this.multipleImages.includes(downloadURL)) {
              this.multipleImages.push(downloadURL);
              const refObject = {
                storageRef: fileRef,
                url: downloadURL,
              };
              allUploadedFilesStorageRefCollection().newStorageRefObject(
                refObject
              );
            }
          });
        }
      );
    },
  },
  persist: !true,
});
