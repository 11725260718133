<template>
  <navigationRowVue></navigationRowVue>
  <router-view />
  <siteFooter></siteFooter>
</template>

<script>
import navigationRowVue from './components/navigation-row.vue'
import siteFooter from '@/components/siteFooter.vue'



export default {
  name: 'App',
  components: {
    navigationRowVue,
    siteFooter,

  }
}
</script>

<style>


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

</style>
