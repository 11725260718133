<template>
  <div class="gallery-wrapper">
    <div id="formArea" class="form-area">
      <!-- <label>Select Images</label> -->
      <div class="title">
        <label>Album Title</label>
        <textarea
          placeholder="Enter Album title"
          v-model="galleryTitle"
        ></textarea>
      </div>

      <input
        type="file"
        multiple="multiple"
        class="input"
        accept="image/png, image/gif, image/jpeg"
        @click="getSelectedPhotos()"
      />

      <div class="selected-album" v-if="selectedPhotos">
        <div class="photo" v-for="(v, i) in selectedPhotos" :key="i">
          <div class="icon" @click="removeSelectedImageById(v, i)">
            <i class="fa-solid fa-xmark icn"></i>
          </div>

          <img :src="v" />
        </div>
      </div>

      <div class="submit">
        <button @click="Preview()">Preview</button>

        <!-- <button disabled="disabled"></button> -->
      </div>
    </div>

    <div class="preview-modal" v-if="showPreview">
      <div class="preview-area">
        <div class="title2">
          <label>Gallery Title</label>
          <p>
            {{ galleryTitle }}
          </p>
        </div>
        <div class="preview-photos">
          <div class="photo" v-for="(v, i) in selectedPhotos" :key="i">
            <div class="icon" @click="removeSelectedImageById(i)">
              <i class="fa-solid fa-xmark icn"></i>
            </div>

            <img :src="v" />
          </div>
        </div>

        <div class="preview-buttons">
          <button @click="uploadAlbum">Upload</button>
          <button @click="Preview()">Edit again</button>
        </div>
      </div>
    </div>
  </div>
  <div class="loading" v-if="isWorkProgress">
    <p>Please Wait....</p>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { photoAlbumCollection } from "@/stores/upload-photo-album.js";
import { useToast } from "vue-toastification";
import { uploadFiles } from "@/stores/file-upload";
import { unncessaryFileUrlCollection } from "@/stores/unnecessary-file-upload-tracker";

import { allUploadedFilesStorageRefCollection } from "@/stores/uploaded-files-storageRef-collection";
import { onMounted } from "@vue/runtime-core";

export default {
  name: "upload-gallery",

  setup() {
    //toast instance
    const toast = useToast();

    //is somthing going on
    const isWorkProgress = ref(false);

    //fiileStorageRefCollection instance
    const flstrgRefCollection = allUploadedFilesStorageRefCollection();

    onMounted(() => {
      unnecessaryUpload.fileUrlCollection.forEach((v) => {
        if (v !== null) {
          var strf = flstrgRefCollection.findFileStorageRef(v);
          if (strf !== null) {
            flstrgRefCollection.deleteFileFromFirebase(strf).then(() => {
              unnecessaryUpload.deleteUrl(v);
            });
          }
        }
      });
    });

    //upload store instance
    const fileUploadStore = uploadFiles();

    //unnecessarily uploaded file tracker store
    const unnecessaryUpload = unncessaryFileUrlCollection();

    //photo album store instance
    const albumCollection = photoAlbumCollection();

    const galleryTitle = ref("");

    const showPreview = ref(false);
    function Preview() {
      if (selectedPhotos.value.length != 0 && galleryTitle.value.length != 0) {
        showPreview.value = !showPreview.value;
      } else {
        console.log("cant preview");
        toast.warning("Fillup all fields to proceed!");
      }
    }

    let selectedPhotos = ref([]);

    function getSelectedPhotos() {
      const inputFile = document.querySelector("input");

      inputFile.addEventListener("change", function () {
        if (inputFile.value) {
          const files = inputFile.files;
          isWorkProgress.value = true;
          if (selectedPhotos.value.length != 0) {
            selectedPhotos.value = [];
          }

          for (let index = 0; index < files.length; index++) {
            if (files[index].type.match("image")) {
              fileUploadStore.uploadMultipleImageAndGetDownloadUrl(
                files[index]
              );
            }
            continue;
          }

          selectedPhotos.value = fileUploadStore.multipleImages;

          setTimeout(() => {
            // console.log(fileUploadStore.multipleImages);
            isWorkProgress.value = false;
            fileUploadStore.multipleImages.forEach((item) => {
              if (item.length !== 0) {
                unnecessaryUpload.addNewFileUrl(item);
              }
            });
          }, 10000);
        }
      });
    }

    //user selected deletable file references
    const userDeletedFiles = ref([]);

    //function to delete user deleted files
    function deleteUserDeletedFiles() {
      if (userDeletedFiles.value.length === 0) {
        console.log("user selected delete file empty");
        return;
      }
      userDeletedFiles.value.forEach((url) => {
        if (url !== null) {
          var strf = flstrgRefCollection.findFileStorageRef(url);
          if (strf !== null) {
            flstrgRefCollection.deleteFileFromFirebase(strf).then(() => {
              unnecessaryUpload.deleteUrl(url);
            });
          }
        }
      });
    }

    function removeSelectedImageById(url, id) {
      if (url.length !== 0) {
        console.log(url);
        userDeletedFiles.value.push(url);
      }
      selectedPhotos.value.splice(id, 1);
    }

    function uploadAlbum() {
      isWorkProgress.value = true;

      var albumObject = {
        title: galleryTitle.value,
        imageList: selectedPhotos.value,
      };
      albumCollection.createNewAlbum(albumObject).then(() => {
        setTimeout(() => {
          isWorkProgress.value = false;
        }, 3000);
      });
      showPreview.value = false;
      galleryTitle.value = "";
      selectedPhotos.value = [];
      unnecessaryUpload.resetCollection();
      deleteUserDeletedFiles();
      fileUploadStore.resetMultipleImagsList();
    }

    return {
      uploadAlbum,
      isWorkProgress,
      selectedPhotos,
      getSelectedPhotos,
      galleryTitle,
      showPreview,
      Preview,
      removeSelectedImageById,
    };
  },
};
</script>

<style lang="scss" scoped>
.gallery-wrapper {
  min-height: 100vh;
  max-height: 100%;
  width: 100%;
  background: linear-gradient(#4671ea, #ac34e7);
  padding: 45px auto;

  display: flex;
  justify-content: center;
  align-items: center;

  .form-area {
    height: 80%;
    width: 90%;

    .title {
      display: flex;
      flex-direction: column;

      @media (max-width: 576px) {
        padding: 25px auto;
      }

      label {
        color: white;
        font-size: 14px;
        font-weight: 600;
      }

      textarea {
        resize: none;
        border-radius: 12px;
        border: none;
        outline: none;
        margin: 15px 0px;
        min-height: 40px;
        max-height: 250px;
        font-size: 12px;
        padding: 10px 15px;
        scrollbar-width: none;

        &:placeholder-shown {
          font-size: 13px;
          text-align: center;
          padding-top: 10px;
        }
      }
    }

    input {
      padding: 25px 45px;
      font-size: 12px;
    }

    .selected-album {
      height: 40%;
      width: 100%;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 27%;
      gap: 8px;
      overflow: auto;
      overscroll-behavior-inline: contain;
      padding-top: 5px;
      padding: 20px auto;
      scrollbar-color: red blue;
      scrollbar-width: 10px;

      .photo {
        height: 100%;
        object-fit: cover;
        display: flex;
        flex-direction: column;

        .icon {
          height: 10%;
          width: auto;

          display: flex;
          align-items: center;
          justify-content: flex-end;

          .icn {
            color: white;
            // border: 1px solid red;
            padding: 5px;
            background-color: red;
            cursor: pointer;

            &:hover {
              transform: scaleX(1.5);
              transition: all 350ms ease-in;
            }
          }
        }

        img {
          border-radius: 10px;
          cursor: pointer;
          height: 90%;

          &:hover {
            transform: scaleX(1.02);
            border: 1px dotted darkgray;
            transition: all 350ms ease-in;
          }
        }
      }
    }

    .submit {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 65px auto;

      button {
        font-size: 12px;
        border-radius: 12px;
        padding: 3px 30px;
        border: none;
        outline: none;

        &:hover {
          transform: scale(1.2);
          color: goldenrod;
          background-color: blue;
          font-size: 10px;
          font-weight: 800;
          transition: all 350ms ease-in;
        }
      }
    }
  }

  .preview-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .preview-area {
      position: relative;
      background-color: #4671ea;
      height: 100%;
      width: 100%;
      padding: 20px;
      overflow: scroll;

      .title2 {
        // height: 50%;
        width: 100%;
        padding: 30px 20px;
        margin: 20px auto;

        label {
          color: white;
          font-size: 14px;
          text-decoration: underline;
          font-weight: 700;
          padding-bottom: 10px;
        }

        p {
          color: white;
          font-size: 12px;
          font-weight: 500;
        }
      }

      .preview-photos {
        height: 300px;
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 27%;
        gap: 8px;
        overflow: auto;
        overscroll-behavior-inline: contain;
        padding-top: 5px;
        padding: 20px auto;
        scrollbar-color: red blue;
        scrollbar-width: 10px;

        .photo {
          height: 100%;
          object-fit: cover;
          display: flex;
          flex-direction: column;

          .icon {
            height: 10%;
            width: auto;

            display: flex;
            align-items: center;
            justify-content: flex-end;

            .icn {
              color: white;
              // border: 1px solid red;
              padding: 5px;
              background-color: red;
              cursor: pointer;

              &:hover {
                transform: scaleX(1.5);
                transition: all 350ms ease-in;
              }
            }
          }

          img {
            border-radius: 10px;
            cursor: pointer;
            height: 90%;

            &:hover {
              transform: scaleX(1.02);
              border: 1px dotted darkgray;
              transition: all 350ms ease-in;
            }
          }
        }
      }

      .preview-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 25px auto;

        button {
          height: 35px;
          width: 120px;
          color: white;
          background-color: blue;
          border-radius: 15px;
          border: none;
          outline: none;
          font-size: 11px;
          margin: auto 10px;
        }
      }
    }
  }
}
.loading {
  position: fixed;
  inset: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 8800;

  display: grid;
  place-content: center;

  p {
    color: white;
    font-size: 15px;
  }
}
</style>