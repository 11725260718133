<template>
  <div class="wrapper">
    <div class="order-display-area">
      <p>
        Total Orders: <b>{{ totalOrderNo }}</b>
      </p>
      <h6><b>All Orders</b></h6>

      <singleOrder
        v-for="(v, i) in adminOrderCollection"
        :key="i"
        :order="v"
      ></singleOrder>
    </div>
  </div>
  <div class="loading" v-if="isLoading">
    <p>Loading....</p>
  </div>
</template>

<script>
import singleOrder from "@/components/single-order.vue";
import { onMounted, ref } from "@vue/runtime-core";
import { bookOrderCollection } from "@/stores/bookorder-collection";

export default {
  name: "all-orders",

  components: {
    singleOrder,
  },

  setup() {
    //order collection instance
    const orderCollection = bookOrderCollection();
    const adminOrderCollection = ref([]);

    //total no of Orders
    const totalOrderNo = ref("");

    const isLoading = ref(false);

    onMounted(() => {
      isLoading.value = true;


      orderCollection.extractOrdersFromBackend(false).then(() => {
        adminOrderCollection.value = orderCollection.allOrdersForAdmin.reverse();
        totalOrderNo.value = adminOrderCollection.value.length;

        setTimeout(() => {
          isLoading.value = false;
        }, 3000);
      });
    });
    return { adminOrderCollection, totalOrderNo, isLoading };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  height: 100%;
  background: linear-gradient(#4671ea, #ac34e9);
  width: 100%;
  padding: 45px;

  .order-display-area {
    min-height: 90vh;
    height: 90%;
    width: 100%;
    // background: red;

    padding: 20px;

    h6 {
      text-align: center;
      font-size: 17px;
      color: white;
      text-decoration: underline;
    }
  }
}
.loading {
  position: fixed;
  inset: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 9000;

  display: grid;
  place-content: center;

  p {
    color: white;
    font-size: 15px;
  }
}
</style>
