<template>
  <div class="wrapper">
    <div class="dark-overlay">
      <div class="col-lg-12">
        <h5><b>Our Published Books</b></h5>
      </div>
      <div class="col-lg-10 col-md-10 col-lg-12 book-container">
        <div
          class="book"
          v-for="(v, i) in books.uploadedBooks"
          :key="i"
          @click="goToBookView(v.id)"
        >
          <div class="book-name">
            <h6>
              <b>
                {{ v.bookName }}
              </b>
            </h6>
          </div>
          <div class="book-image">
            <img :src="v.imageUrl" srcset="" />
            <!-- <img src="@/assets/images/Sampriti.jpg" alt="" srcset=""> -->
          </div>
          <div class="author">
            <p>Author</p>
            <h6>
              {{ v.authorName }}
            </h6>
          </div>
          <div class="price">
            <div class="current-price">
              <h6>
                Best Price:
                <b
                  >{{ v.currentPrice }}
                  <i class="fa-solid fa-indian-rupee-sign fa-xs"></i
                ></b>
              </h6>
            </div>
            <div class="mrp">
              <p>
                MRP:
                <b
                  >{{ v.mrp }}
                  <i class="fa-solid fa-indian-rupee-sign fa-xs"></i
                ></b>
              </p>
              <p>{{ v.discount }}% off</p>
            </div>
          </div>
          <div class="buttons">
            <button id="b" @click.stop="addIdToShoppingCart(v.id)">
              Buy Now
            </button>
            <button id="d" @click.stop="addToWishList(v.id)">
              Add to Wishlist
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading" v-if="isLoading">
    <p>Loading....</p>
  </div>
</template>

<script>
import router from "@/router";

import { uploadBooks } from "@/stores/upload-book";

import { shoppingCartStore } from "@/stores/shopping-cart-store";
import { bookOrderCollection } from "@/stores/bookorder-collection";
import { onMounted, ref } from "@vue/runtime-core";

export default {
  name: "buy-books",

  setup() {
    //order collection instance
    const orderCollection = bookOrderCollection();
    //store instance
    const books = uploadBooks();
    const shoppingCart = shoppingCartStore();

    const isLoading = ref(true);

    onMounted(() => {

      // books.$reset()
      books.extractAllPostsFromFirebase();
      setTimeout(() => {
        isLoading.value = false;
      }, 3000);
    });

    //add itemId to the shoppingCart
    function addIdToShoppingCart(id) {
      shoppingCart.collectItemIds(id);
      // shoppingCart.$reset();
    }

    function goToBookView(id) {
      books.setSelectedId(id);
      router.push({
        name: "book-view",
      });
    }

    //add item to the wishlist
    function addToWishList(id) {
      const targetItem = books.getBookFromId(id);
      orderCollection.createWishList(targetItem[0]);
    }

    return {
      goToBookView,
      books,
      shoppingCart,
      addIdToShoppingCart,
      addToWishList,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin makeElipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wrapper {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-image: url("@/assets/images/sky-34536.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow: auto;

  .dark-overlay {
    height: 100%;
    min-height: 100vh;

    width: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    padding: 50px;

    @media (max-width: 576px) {
      padding: 30px 5px;
    }
    h5 {
      color: white;
      font-size: 16px;
      padding: 16px 12px;
      line-height: 30px;
      letter-spacing: 0.2px;
      text-decoration: underline;
      text-shadow: 1px 2px 3px #666;
    }

    .book-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      column-gap: 15px;
      row-gap: 12px;

      .book {
        margin: 5px;
        border-radius: 10px;
        cursor: pointer;
        background: #213a5a;
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);

        .book-name {
          height: 40px;
          padding: 10px 15px;
          h6 {
            @include makeElipsis();
            font-size: 14px;
            color: white;
            width: 95%;
          }
        }

        .book-image {
          padding: 4px;

          img {
            border-radius: 5px;
            max-height: 250px;
            height: 220px;

            transition: all 300ms ease-in;
            &:hover {
              transform: scale(0.95) rotate(3deg);
            }
          }
        }

        .author {
          @include makeElipsis();

          p {
            font-size: 10px;
            font-weight: 500;
            padding: 4px 10px;
            color: white;
          }

          h6 {
            font-size: 13px;
            font-weight: 800;
            padding: 2px 13px;
            color: white;
            width: 90%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .price {
          .current-price {
            h6 {
              font-size: 13px;
              padding: 4px 10px;
              color: white;
            }
          }

          .mrp {
            display: flex;

            p {
              font-size: 13px;
              padding: 3px 10px;
              color: white;
            }
          }
        }

        .buttons {
          margin-bottom: 10px;

          button {
            height: 25px;
            font-size: 10px;
            font-weight: 600;
            padding: 2px 15px;
            margin: 2px 5px;
            border-radius: 7px;
            background-color: #1c4d8a;
            border: none;
            color: white;
            outline: none;
          }

          #b:hover {
            background-color: whitesmoke;
            color: black;
            transform: translate(-2px, 2px);
            transition: all 250ms ease-out;
          }

          #d:hover {
            background-color: yellow;
            transform: translate(2px, 2px);
            transition: all 250ms ease-out;
          }
        }
      }
    }
  }
}
.loading {
  position: fixed;
  inset: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 1000;

  display: grid;
  place-content: center;

  p {
    color: white;
    font-size: 15px;
  }
}
</style>