<template>
  <div class="wrapper">
    <h6><b>All Previous Orders</b></h6>

    <userOrder
      v-for="(v, i) in completedOrderList"
      :key="i"
      :completeOrder="v"
    ></userOrder>
  </div>
  <div class="loading" v-if="isLoading">
    <p>Loading....</p>
  </div>
</template>

<script>
import userOrder from "@/components/order-for-user-view.vue";
import { bookOrderCollection } from "@/stores/bookorder-collection";
import { onMounted, ref } from "@vue/runtime-core";

export default {
  name: "previous-orders",

  components: {
    userOrder,
  },

  setup() {
    const completedOrderList = ref([]);
    const orderCollection = bookOrderCollection();
    const isLoading = ref(false);

    onMounted(() => {
      isLoading.value = true;

      completedOrderList.value = orderCollection.completedOrdersOfaUser;

      orderCollection.extractOrdersFromBackend(true).then(() => {
        completedOrderList.value =
          orderCollection.allConfirmedOrdersOfaUser.filter(
            (order) => order.orderStatus === "Fulfilled"
          );
        setTimeout(() => {
          isLoading.value = false;
        }, 3000);
      });
    });
    return { completedOrderList,isLoading };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: linear-gradient(#4671ea, #ac34e9);
  padding: 25px;
  h6 {
    text-align: center;
    color: white;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
}
.loading {
  position: fixed;
  inset: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 9000;

  display: grid;
  place-content: center;

  p {
    color: white;
    font-size: 15px;
  }
}
</style>
