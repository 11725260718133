import { db } from "@/firebase/firebase-init";
import { userCreation } from "@/stores/user-verification";
import { collection } from "firebase/firestore";

async function createCollectionReference(newCollectionName, isAdmin, userID) {
  if (!isAdmin || isAdmin === null) {
    var curretUser = userCreation().currentUser;

    var docid = await userCreation().getDocumentReferenceOfCustomUser(
      curretUser.uid
    );
    if (docid !== null) {
      var collectionReference = collection(
        db,
        "allRegisteredUsers",
        docid,
        String(newCollectionName)
      );
      return collectionReference;
    } else {
      return null;
    }
  } else {
    if (userID !== null) {
      var docId = await userCreation().getDocumentReferenceOfCustomUser(userID);

      if (docId !== null) {
        var collectionReference2 = collection(
          db,
          "allRegisteredUsers",
          docId,
          String(newCollectionName)
        );
        return collectionReference2;
      } else {
        return null;
      }
    }
  }
}

export { createCollectionReference };
