<template>
  <section class="editorial-board">
    <div class="col-lg-12">
      <div class="row justify-content-center">
        <div class="all-board-members">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-4 col-sm-8">
              <p id="heading-label">Editorial Board</p>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-5">
              <div class="editorial-board-item">
                <img src="../assets/images/dhiraj.jpg" alt="" />
                <h5>Dr. Dhiraj Patar</h5>
                <span>Chief Editor</span>
                <p>
                  A tireless soul, Dr. Dhiraj Patar, has been active in the
                  fields of Literature and Journalism for about ten years now.
                  He is also the Editor in Chief of the Tiwa newspaper,
                  <b>Tiwa Pathra</b> and currently working as an Assistant
                  Professor of the Department of Assamese,North Lakhimpur
                  College(Autonomous).
                </p>
              </div>
            </div>

            <div class="col-lg-5">
              <div class="editorial-board-item">
                <img src="../assets/images/rumi.jpeg" alt="" />
                <h5>Dr. Rumi Patar</h5>
                <span>Assistant Editor</span>
                <p>
                  Dr. Rumi Patar is currently working as an Assistant Professor
                  of the Department of History,Guwahati College.
                </p>
              </div>
            </div>
          </div>

          <br /><br />
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-4 col-sm-8">
              <p id="heading-label">List of Honorable Advisors</p>
            </div>
          </div>
          <br />

          <div class="row justify-content-center">
            <div class="col-lg-9">
              <div class="member-display-container">
                <div class="row justify-content-center py-4">
                  <div class="col-lg-10">
                    <ol class="list-group list-group-numbered">
                      <li
                        class="list-group-item member"
                        v-for="(v, i) in advisorList"
                        :key="i"
                      >
                        {{ v }}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br /><br />
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-4 col-sm-8">
              <p id="heading-label">List of Honorable Members</p>
            </div>
          </div>
          <br />

          <div class="row justify-content-center">
            <div class="col-lg-9">
              <div class="member-display-container">
                <div class="row justify-content-center py-4">
                  <div class="col-lg-10">
                    <ol class="list-group list-group-numbered">
                      <li
                        class="list-group-item member"
                        v-for="(v, i) in memberList"
                        :key="i"
                      >
                        {{ v }}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "editorial-board",

  setup() {
    const memberList = [
      "Dr. Parag nath",
      "Dr. Dipak Das",
      "Dr. Devopratim Hazarika",
      "Dr. Utpal Saikia",
      "Dr. Jayanta Pathak",
      "Dr. Pranab Prasad Borah",
      "Dr. Abhijeet Borah,",
      "Dr. Jadabendra Borah",
      "Dr. Champak Saikia",
      "Dr. Ratul Deka",
      "Dr. Nitu Saharia",
      "Dr. Sanjib Borah",
      "Dr. Bijaya Borah",
      "Dr. Bhupen Baruah",
      "Dr. Bhanita Das",
      "Dr. Sangita Saikia",
      "Dr. Abhijit Borah",
      "Sri Debajit Bordoloi",
      "Sri Ranjan Jyoti Sarma",
      "Dr. Neetu Saharia",
      "Dr. Nishgandha Talukdar",
    ];

    const advisorList = [
      "Prof. Dipti Phukan Patgiri, Rtd. D. Lit, Dept. of Assamese,Gauhati University.",
      "Prof. Pradip Jyoti Mahanta, Rtd. Dean Faculty of Cultural Studies,Tezpur University. ",
      "Prof. Kanak Chandra Saharia, HOD,Dept. of Assamese, Gauhati University.",
      "Prof. Projit Kumar Palit, Former HOD, Dept. of History, Assam University.",
      "Prof. Nirajana Mahanta Bezbora, Former HOD, Dept. of Assamese,Dibrugarh University.",
      "Prof. Prafulla Kumar Nath, Dept. of Assamese, Gauhati University.",
      "Dr. Raju Baruah, Rtd. Prof & HOD, Dept. of Assamese, Jagirod College. ",
      "Dr. Rabindra Sharmah, Associate Professor and HOD, Centre of Tribal Folklore, Language and Literature, Central University of Jharkhand. ",
      "Dr. Satrupa Dutta Mazumdar, Visiting Scientist(Linguist)Instituite of Cybernetics Systems and Information Technology,(ICSIT) Kolkata & Editor, Journal of Kolkata Society for Asian Studies(JKSAS). ",

      "Prof. Kamaluddin Ahmed, Dept. of Assamese, Gauhati University. ",
      "Prof. Bimal Mazumdar, Dept. of Assamese, Gauhati University. ",
      "Dr. Habibur Rahman, Associate Professor, Dept. of Assamese, Jagirod College.",
      "Dr. Khagen Gogoi, Associate Professor, Dept. of History, Jagirod College.",
      "Dr. Srijani Das, Associate Professor, Dept. of Education, Jagirod College.",
      "Dr. Dhrubajyoti Saharia, Associate Professor, Dept. of Geography,Gauhati University ",
      "Dr. Arabinda Rajkhowa, Assistant Prof. Dept. of Assamese, North Lakhimpur College (A)",
    ];

    return { memberList, advisorList };
  },
};
</script>

<style lang="css" scoped>
section.editorial-board {
  background-image: url("@/assets/images/background-1.jpg");
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.all-board-members {
  height: fit-content;
  width: fit-content;
  background-color: rgba(18, 8, 81, 0.29);
  font-weight: 500;
  border-radius: 15px;
  /* border-color: white; */
  padding: 45px 25px;
  margin: 40px 30px;
}

#heading-label {
  font-weight: bolder;
  font-size: 22px;
  color: yellow;
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0.2px;
  text-shadow: 0px 5px 10px #888a85;
}

.editorial-board-item {
  text-align: center;
  position: relative;
  min-width: 240px;
  -webkit-box-shadow: 0px 2px 10px rgba(18, 8, 81, 0.15);
  box-shadow: 0px 2px 10px rgba(18, 8, 81, 0.15);
  padding: 85px 30px 20px 30px;
  border-radius: 10px;
  background: #215991;
  margin: 80px 40px;
  cursor: pointer;
}

@media (max-width: 576px) {
  .editorial-board-item {
    margin: 80px auto;
  }
}

.editorial-board-item img {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: -50px;
  margin: auto;
  border: none;
  outline: none;
}

.editorial-board-item img:hover {
  transform: translateY(-2px) scale(1.2);
  transition: all 250ms ease-in;
}

.editorial-board-item h5 {
  font-size: 20px;
  color: white;
  font-weight: 600;
  margin-bottom: 5px;
}

.editorial-board-item span {
  font-size: 13px;
  color: yellow;
  text-transform: uppercase;
  display: block;
  margin-bottom: 12px;
}

.editorial-board-item p {
  font-size: 12px;
  font-display: 600;
  color: white;
}

/* container to display the members */

.member-display-container {
  background-color: #215991;
  text-align: start;
  border-radius: 15px;
  margin-top: 40px;
  font-weight: 500;
}

.member {
  font-size: 12px;
  line-height: 25px;
  font-weight: 600;
  color: white;
  text-align: start;
  background-color: transparent;
  border: none;
}
</style>
