import { defineStore } from "pinia";
import { v4 as uid } from "uuid";
import { useToast } from "vue-toastification";

import { addDoc, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore";
import { createCollectionReference } from "@/helper-functions/create-collection-reference";


const toast = useToast();

export const userAddressCollection = defineStore("AddressCollection", {
  state: () => ({
    addressCollection: [],
    downloadedQuerySnapshot: [],
    selectedAddressId: String,
    selectedAddress: Object,
  }),
  getters: {
    getPost: (state) => {
      return state.addressCollection;
    },
    getSelectedAddressId: (state) => {
      return state.selectedAddressId;
    },

    getSelectedAddress: (state) => {
      return state.selectedAddress;
    },
  },
  actions: {
    //create a new address in firebase
    async createAddress(address) {
      var addressObject = {
        id: uid(),
        ...address,
      };

      var collectionReference = await createCollectionReference("allAddresses");

      if (collectionReference !== null) {
        // Add a new document in collection "allAddresses"
        await addDoc(collectionReference, addressObject).then(() => {
          toast.success("New address added");
        });
      }
    },

    //retrieve all addresses from firebase
    async getAllAddressFromFirebase() {
      console.log("running address");

      if (this.addressCollection.length === 0) {
        var collectionReference = await createCollectionReference(
          "allAddresses"
        );
        if (collectionReference !== null) {
          const querySnapshot = await getDocs(collectionReference);

          if (!querySnapshot.empty) {
            console.log(querySnapshot);
            this.downloadedQuerySnapshot = querySnapshot;
            this.addressCollection = [];
            querySnapshot.forEach((doc) => {
              this.addressCollection.push(doc.data());
            });
          }
        }
      } else {
        console.log("address collection exists");
      }
    },

    //edit an address stored in firebase
    async editAddress(userEditedAddress) {
      var targetId = "";
      this.downloadedQuerySnapshot.forEach((doc) => {
        if (doc.data().id === userEditedAddress.id) {
          targetId = doc.id;
        }
      });

      var collectionReference = await createCollectionReference("allAddresses");

      if (collectionReference !== null) {
        const addressRef = doc(collectionReference, targetId);

        await updateDoc(addressRef, userEditedAddress).then(() => {
          toast.success("Successfully edited");
        });
      }
    },

    //delete an address
    async deleteAddress(id) {
      var targetId = "";
      this.downloadedQuerySnapshot.forEach((doc) => {
        if (doc.data().id === id) {
          targetId = doc.id;
        }
      });

      var collectionReference = await createCollectionReference("allAddresses");

      if (collectionReference !== null) {
        const addressRef = doc(collectionReference, targetId);
        await deleteDoc(addressRef);
        //get the editable address first
        const addressToRemove = this.addressCollection.filter(
          (address) => address.id === id
        );
        const addressIndex = this.addressCollection.indexOf(addressToRemove[0]);

        //remove the old address first
        this.addressCollection.splice(addressIndex, 1);

        this.removeSelectedAddressId(id);
      }
    },

    //set selected address Id
    setSelectedAddressId(id) {
      // console.log(id);
      this.selectedAddressId = id;
      this.extractSelectedAddressObject();
    },
    //remove selected address Id
    removeSelectedAddressId(id) {
      if (this.selectedAddressId === id) {
        this.selectedAddressId = "";
      } else {
        console.log("did not match");
      }
    },

    //get the selected address object
    extractSelectedAddressObject() {
      const deliveryAddress = this.addressCollection.filter(
        (address) => address.id === this.selectedAddressId
      );
      this.selectedAddress = deliveryAddress;
    },
  },
  persist: true,
});
