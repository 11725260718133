<template>
  <div class="wrapper">
    <div class="menu" id="menuId">
      <div class="options" v-if="isAdmin">
        <p @click="goToPage('admin-options')" v-if="isAdmin">Admin Area</p>
      </div>
      <div class="options">
        <p @click="goToPage('account-info')">Account Info</p>
      </div>
      <div class="options">
        <p @click="goToPage('my-wishlist')">My Wishlist</p>
      </div>
      <div class="options">
        <p @click="goToPage('my-shopping-cart')">My Cart</p>
      </div>
      <div class="options">
        <p @click="goToPage('current-orders')">Active Orders</p>
      </div>
      <div class="options">
        <p @click="goToPage('previous-orders')">Previous Orders</p>
      </div>
      <div class="options">
        <p>Log Out</p>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { userCreation } from "@/stores/user-verification";
import { onMounted, ref } from "@vue/runtime-core";

export default {
  name: "user-account-mobile-view",

  setup() {
    const createuser = userCreation();

    const isAdmin = ref(false);

    onMounted(() => {
      if (createuser.extractedCustomUser !== null) {
        isAdmin.value = createuser.isAdmin;
      }
    });

    //target a specific page width
    //and if it exceeds push another page

    function pushWhenResizeExceeds() {
      window.addEventListener("resize", () => {
        var screenWidth = window.innerWidth;

        if (screenWidth >= 827) {
          router.replace({
            name: "home-page",
          });
        }
      });
    }
    pushWhenResizeExceeds();

    function goToPage(name) {
      router.push({
        name: name,
      });
    }

    return {
      goToPage,
      isAdmin,
      pushWhenResizeExceeds,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to right top,
    #6f235f,
    #602766,
    #4e2b6a,
    #392f6d,
    #1c316d
  );

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px auto;
  .menu {
    background-image: linear-gradient(
      to right top,
      #051937,
      #004d7a,
      #008793,
      #00bf72,
      #a8eb12
    );
    height: 100%;
    width: 95%;
    display: grid;
    border-radius: 8px;
    place-content: center;
    grid-template-columns: 100%;

    @media (min-width: 827px) {
      display: none;
    }
    //create a condition to push it to home page when this display hits

    .options {
      width: 95%;
      height: 45px;
      // background: green;
      margin: 15px auto;
      text-align: center;
      display: grid;
      place-content: center;
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      p {
        font-size: 13px;
        color: black;
        font-weight: 600;
        cursor: pointer;
        transition: all 350ms ease;
        &:hover {
          font-size: 13.3px;
          color: white;
          transform: translateY(-2px);
        }
      }
    }
  }
}
</style>