<template>
  <div class="wrapper">
    <h6><b>Create Post</b></h6>

    <div class="create-post-form">
      <div class="post-category">
        <label>Select Post category:</label>
        <select v-model="selectedcategory">
          <option v-for="(v, i) in typesOfPost" :key="i">
            {{ v }}
          </option>
        </select>
      </div>
      <div class="title">
        <label> Post Title:</label>
        <textarea
          id="txtarea1"
          maxlength="200"
          class="txtarea"
          @keyup="adjustTextFieldHeight('#txtarea1')"
          v-model="postTitle"
          placeholder="Enter post title"
        ></textarea>
        <p v-if="emptyTitleError">{{ emptyTitleError }}</p>
      </div>

      <div class="post-image">
        <label>Post Image</label>
        <input
          type="file"
          accept="image/gif, image/jpeg, image/png"
          id="postImg"
          @click="getSelectedPhotos('postImg', true)"
        />
        <div class="photo" v-for="(v, i) in postImage" :key="i">
          <img :src="v" />
        </div>
      </div>
      <div class="important-links">
        <label>Important Post Links</label>
        <input
          type="text"
          v-model="individualLink"
          id="linkinput"
          @keypress.enter="checkWebLinks(individualLink)"
        />
        <input
          v-if="showAddLinkButton"
          type="text"
          v-model="linkLabel"
          placeholder="Add Link label"
        />
        <button v-if="showAddLinkButton" @click="collectLinks">Add link</button>
        <p v-if="invalidLinkError" id="errorInfo">{{ invalidLinkError }}</p>

        <div class="added-links-display" v-if="linkValues.length != 0">
          <p v-for="(v, i) in linkValues" :key="i">
            <a :href="v.linkvalue" target="_blank">{{ v.label }}</a>
          </p>
        </div>
      </div>
      <div class="other-images">
        <label>Other Images</label>
        <input
          type="file"
          multiple
          @click="getSelectedPhotos('otherImg', false)"
          id="otherImg"
          accept="image/png, image/gif, image/jpeg"
        />
        <div class="display-other-imgs" v-if="otherImages.length != 0">
          <div class="other-img" v-for="(v, i) in otherImages" :key="i">
            <img :src="v" />
          </div>
        </div>
      </div>
      <div class="post-description">
        <label>Post description</label>
        <textarea
          maxlength="500"
          @keyup="adjustTextFieldHeight('#txtarea2')"
          id="txtarea2"
          placeholder="Enter Post Description"
          v-model="descriptionValue"
        ></textarea>
      </div>

      <div class="action-btns">
        <button @click="validateBeforePreview">Preview</button>
        <button @click="resetAll">Reset</button>
      </div>
    </div>
    <div class="preview-page" v-if="showPreview">
      <div class="preview-post-category">
        <label>Select Post category:</label>
        <select v-model="selectedcategory">
          <option v-for="(v, i) in typesOfPost" :key="i">
            {{ v }}
          </option>
        </select>
      </div>
      <div class="ptitle-preview">
        <label>Post Title</label>
        <p>{{ postTitle }}</p>
      </div>
      <div class="pImage-preview" v-if="postImage.length != 0">
        <label>Post Image</label>
        <img :src="postImage[0]" alt="" />
      </div>
      <div class="pLinks-preview" v-if="linkValues.length != 0">
        <label>Post Links</label>
        <p v-for="(v, i) in linkValues" :key="i">
          <a :href="v.linkvalue" target="_blank">{{ v.label }}</a>
        </p>
      </div>
      <div class="potherImages-preview" v-if="otherImages.length != 0">
        <label>Other Images</label>

        <div class="oImg">
          <div class="oth-img" v-for="(v, i) in otherImages" :key="i">
            <img :src="v" />
          </div>
        </div>
      </div>
      <div class="pdescription-preview" v-if="descriptionValue">
        <label>Post Description</label>
        <p>{{ descriptionValue }}</p>
      </div>

      <div class="actionButtons">
        <button @click="UserEnteredPost">Submit & Uplaod</button>
        <button @click="showPreview = false">Edit Again</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";

import { PostCollectionStore } from "@/stores/posts";
import { uploadFiles } from "@/stores/file-upload";
import { getDesiredDateTime } from "@/helper-functions/get-simplified-datetime";

// import { uploadImageAndGetDownloadUrl } from "@/helper-functions/file-upload.js";

export default {
  name: "create-post",

  setup() {
    //upload store instance
    const fileUploadStore = uploadFiles();

    //show or hide preview page
    const postCollection = PostCollectionStore();
    const showPreview = ref(false);

    //array to hold anchor links if added
    var linkValues = [];
    //ref variable to hold individual link values
    const individualLink = ref("");
    //ref variable to hold individual link label
    const linkLabel = ref("");
    //ref variable to hold link related error
    const invalidLinkError = ref("");
    //ref variable to show or hide  add link
    // button depending upon the validity of link
    const showAddLinkButton = ref(false);

    //ref variable to hold post image
    const postImage = ref([]);
    //ref variable to hold other images related to post
    const otherImages = ref([]);
    //ref value to hold the post title
    const postTitle = ref("");
    //variable to hold error if title is empty
    const emptyTitleError = ref("");
    //variable to hold the description
    const descriptionValue = ref("");

    //types of Posts
    const typesOfPost = [
      "Post Update",
      "Announcement",
      "Book Launch",
      "Discussion",
      "Other Activities",
    ];

    //selected category
    const selectedcategory = ref("Post Update");

    function collectLinks() {
      var getinputIdvalue = document.getElementById("linkinput").value;

      if (!linkLabel.value) {
        invalidLinkError.value = "link Label is required";
        return;
      }
      if (linkValues.length === 0) {
        linkValues.push({
          label: linkLabel.value,
          linkvalue: getinputIdvalue,
        });
        invalidLinkError.value = "";
        individualLink.value = "";
        linkLabel.value = "";
        return;
      } else {
        for (var value in linkValues) {
          if (linkValues[value].label === linkLabel.value) {
            invalidLinkError.value = "Duplicate Entry not allowed";
            return;
          }
        }
        linkValues.push({
          label: linkLabel.value,
          linkvalue: getinputIdvalue,
        });
        invalidLinkError.value = "";
        individualLink.value = "";
        linkLabel.value = "";
      }
    }
    //adjust textfield height with content
    function adjustTextFieldHeight(id) {
      // document.querySelectorAll('textarea')
      const selectTextArea = document.querySelector(id);
      selectTextArea.addEventListener("keyup", (e) => {
        selectTextArea.style.height = "auto";
        const scrollHeight = e.target.scrollHeight;
        selectTextArea.style.height = `${scrollHeight}px`;
      });
    }

    function checkWebLinks(txtAreaVal) {
      const pattern =
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;

      if (pattern.test(txtAreaVal.trim())) {
        if (!txtAreaVal.trim().includes("https://")) {
          invalidLinkError.value = "Link must include https://";
          return;
        }

        invalidLinkError.value = "";
        showAddLinkButton.value = true;
        return;
      }
      invalidLinkError.value = "Invalid Link";
      showAddLinkButton.value = false;
    }

    function getSelectedPhotos(id, isPostImg) {
      const inputFile = document.getElementById(id);

      inputFile.addEventListener("change", function () {
        if (inputFile.value) {
          const files = inputFile.files;

          if (isPostImg) {
            if (postImage.value.length != 0) {
              postImage.value = [];
            }
          } else {
            // otherImages
            if (otherImages.value.length != 0) {
              otherImages.value = [];
            }
          }

          for (let index = 0; index < files.length; index++) {
            if (files[index].type.match("image")) {
              if (isPostImg) {
                fileUploadStore.uploadSingleImageAndGetDownloadUrl(
                  files[index]
                );
                postImage.value = fileUploadStore.uploadedSingleImage;
              } else {
                fileUploadStore.uploadMultipleImageAndGetDownloadUrl(
                  files[index]
                );
                otherImages.value = fileUploadStore.multipleImages;
              }
            }
            continue;
          }
        }
      });
    }

    function validateBeforePreview() {
      if (!postTitle.value) {
        emptyTitleError.value = "Post Title cant be empty";
        return;
      } else {
        emptyTitleError.value = "";
      }
      if (emptyTitleError.value) {
        return;
      }
      showPreview.value = !showPreview.value;
    }

    //function to reset all
    function resetAll() {
      postTitle.value = "";
      linkLabel.value = "";
      otherImages.value = [];
      postImage.value = [];
      linkValues.value = [];
      individualLink.value = "";
      invalidLinkError.value = "";
      showAddLinkButton.value = false;
      emptyTitleError.value = "";
      descriptionValue.value = "";
    }

    const post = ref({});

    function UserEnteredPost() {
      post.value = {
        time: getDesiredDateTime(),
        PostedBy: "Admin",
        type: selectedcategory.value,
        postImage: postImage.value,
        title: postTitle.value,
        otherImages: otherImages.value,
        importantLinks: linkValues,
        description: descriptionValue.value,
      };

      showPreview.value = !showPreview.value;

      postCollection.createNewPost(post.value);
      resetAll();
      fileUploadStore.resetMultipleImagsList();

      // postCollection.$reset();
    }

    return {
      post,
      UserEnteredPost,
      collectLinks,
      linkValues,
      adjustTextFieldHeight,
      invalidLinkError,
      checkWebLinks,
      emptyTitleError,
      individualLink,
      selectedcategory,
      showAddLinkButton,
      linkLabel,
      descriptionValue,
      postImage,
      getSelectedPhotos,
      otherImages,
      postTitle,
      validateBeforePreview,
      resetAll,
      showPreview,
      typesOfPost,
      postCollection,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin txtareaStyle {
  textarea {
    border-radius: 12px;
    min-height: auto;
    max-height: 200px;
    width: 60%;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    resize: none;
    scrollbar-width: none;
    border: none;
    outline: none;
    &::placeholder {
      display: grid;
      place-items: center;
    }
  }
}
@mixin labelStyle {
  label {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin: auto 15px;
    text-align: center;
  }
}

.wrapper {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: #172539;
  padding: 30px 40px;

  @media (max-width: 592px) {
    padding: 30px 15px;
  }

  h6 {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    color: white;
    text-decoration: underline;
    letter-spacing: 0.3px;
  }
  .create-post-form {
    margin: 55px auto;

    color: white;
    @media (max-width: 592px) {
      margin: 30px auto;
    }

    .post-category {
      @include labelStyle();
      margin: 35px auto;

      select {
        font-size: 12px;
        font-weight: 500;
      }
    }
    .title {
      margin: 35px auto;
      width: 100%;

      @include labelStyle();
      @include txtareaStyle();
      textarea {
        width: 90%;
      }
      p {
        color: red;
        margin: 20px;
        font-size: 12px;
      }
      @media (max-width: 592px) {
        display: flex;
        flex-direction: column;
        label {
          text-align: start;
          margin: 20px 0px;
          font-size: 13px;
        }
        textarea {
          width: 100%;
        }
      }
    }
    .post-image {
      margin: 45px auto;
      @include labelStyle();

      input {
        font-size: 12px;
        border: none;
        outline: none;
        margin-top: 10px;
      }
      .photo {
        margin: 20px auto;
        padding: 5px 20px;
        img {
          max-height: 300px;
          height: auto;
          max-width: 70%;
          width: auto;
          margin: 4px auto;
        }
      }
      @media (max-width: 592px) {
        label {
          text-align: start;
          margin-left: 0px;
          font-size: 13px;
        }
      }
    }

    .important-links {
      margin: 45px auto;
      @include labelStyle();
      input {
        height: 35px;
        border-radius: 10px;
        border: none;
        outline: none;
        font-size: 12px;
        padding: 1px 10px;
        margin-right: 15px;
        margin-bottom: 20px;
      }
      button {
        font-size: 12px;
        margin: auto 15px;
        width: 80px;
        font-weight: 500;
      }

      .added-links-display {
        p {
          color: white;
          font-size: 12px;
          margin: 8px 15px;
        }
      }

      @media (max-width: 592px) {
        display: flex;
        flex-direction: column;
        label {
          text-align: start;
          margin: 20px 0px;
          font-size: 13px;
        }
        input {
          width: 100%;
        }
      }
    }

    .action-btns {
      display: grid;
      grid-auto-flow: column;
      place-content: center;
      button {
        margin: 10px 25px;
        font-size: 12px;
        padding: 1px 20px;
        font-weight: 500;
        border-radius: 10px;
      }
    }
  }
  .other-images {
    margin: 45px auto;
    @include labelStyle();
    input {
      height: 35px;
      border-radius: 10px;
      border: none;
      outline: none;
      font-size: 12px;
      margin-top: 10px;
    }
    @media (max-width: 592px) {
      label {
        text-align: start;
        margin-left: 0px;
        font-size: 13px;
      }
    }
    .display-other-imgs {
      height: 30%;
      width: 100%;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 27%;
      gap: 8px;
      overflow: auto;
      overscroll-behavior-inline: contain;
      padding-top: 5px;
      padding: 20px auto;
      scrollbar-color: red;
      scrollbar-width: 10px;
      .other-img {
        height: 100%;
        object-fit: cover;
        img {
          border-radius: 10px;
          cursor: pointer;
          height: 90%;
        }
      }
    }
  }

  .post-description {
    margin: 45px auto;
    @include labelStyle();
    textarea {
      width: 95%;
      margin: 25px 15px;
      min-height: 100px;
      resize: none;
      border: none;
      outline: none;
      text-align: center;
      font-size: 13px;
      border-radius: 10px;
    }
    @media (max-width: 592px) {
      display: flex;
      flex-direction: column;
      label {
        text-align: start;
        margin: 20px 0px;
        font-size: 13px;
      }
      textarea {
        width: 100%;
        margin: auto 0px;
      }
    }
  }
  #errorInfo {
    color: red;
    margin: 10px 30px;
  }

  //previewpage style

  @mixin previewPageCommon {
    margin: 20px auto;
    width: 90%;
    label {
      font-size: 15px;
      font-weight: 600;
      text-decoration: underline;
      color: white;
    }
  }

  .preview-page {
    position: fixed;
    inset: 0;
    background: rgb(29, 94, 148);
    overflow-y: scroll;
    padding: 30px;
    @media (max-width: 576px) {
      padding: 30px 5px;
    }

    .preview-post-category {
      @include previewPageCommon();
      select {
        margin: auto 20px;
        font-size: 12px;
        font-weight: 500;
        border: none;
        outline: none;
      }
    }

    .ptitle-preview {
      @include previewPageCommon();
      p {
        font-size: 13px;
        font-weight: 500;
        margin-top: 20px;
        color: white;
      }
    }

    .pImage-preview {
      @include previewPageCommon();
      display: flex;
      flex-direction: column;
      img {
        height: 150px;
        width: 80%;
        margin-top: 20px;
      }
    }
    .pLinks-preview {
      @include previewPageCommon();
      p {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.2px;
        color: white;
      }
    }

    .potherImages-preview {
      @include previewPageCommon();

      .oImg {
        height: 30%;
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 27%;
        gap: 8px;
        overflow: auto;
        overscroll-behavior-inline: contain;
        padding-top: 5px;
        padding: 20px auto;
        scrollbar-color: red;
        scrollbar-width: 10px;
        .oth-img {
          height: 100%;
          object-fit: cover;
          img {
            border-radius: 10px;
            cursor: pointer;
            height: 90%;
          }
        }
      }
    }

    .pdescription-preview {
      @include previewPageCommon();
      p {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.2px;
        color: white;
      }
    }

    .actionButtons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px auto;

      button {
        font-size: 10px;
        font-weight: 400;
        height: 30px;
        padding: 2px 15px;
        border-radius: 10px;
        border: none;
        outline: none;
        margin: 4px 15px;
      }
    }
  }
}
</style>