import router from "@/router";
import { defineStore } from "pinia";

import { getDesiredDateTime } from "@/helper-functions/get-simplified-datetime";

import { useToast } from "vue-toastification";
import { auth, db } from "@/firebase/firebase-init";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";

import { userAddressCollection } from "@/stores/address-store";

const toast = useToast();

export const userCreation = defineStore("uCreate", {
  state: () => ({
    isLoggedIn: false,
    isAdmin: false,
    loginError: "",
    signupError: "",
    userName: "Sampriti",
    currentUser: Object,
    extractedCustomUser: Object,
    userRefId: "",
  }),
  getters: {},
  actions: {
    async logInExistingUser(email, password) {
      if (email.length !== 0 && password.length >= 6) {
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in
            this.currentUser = userCredential.user;
            // ...
            this.isLoggedIn = true;
            this.loginError = "";
            router.push({
              name: "home-page",
            });
            toast.success("you are logged in!");
          })
          .catch((e) => {
            if (String(e.message).includes("wrong-password")) {
              this.loginError = "Incorrect password!";
              toast.error("Incorrect password!");

              setTimeout(() => {
                this.loginError = "";
              }, 2000);
            } else {
              this.loginError = "Error occurred!";
              toast.error("Login failed!");
              setTimeout(() => {
                this.loginError = "";
              }, 2000);
            }
          });
      } else {
        if (email.length === 0) {
          this.loginError = "Email is empty";
          setTimeout(() => {
            this.loginError = "";
          }, 2000);
        }
        if (password.length < 6) {
          this.loginError = "Minimum password length is 6";
          setTimeout(() => {
            this.loginError = "";
          }, 2000);
        }
      }
    },

    async signUp(email, password, fullName) {
      if (email.length != 0 && password.length >= 6) {
        this.signupError = "";
        createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            const userId = userCredential.user.uid;
            // console.log(userId);
            const curatedUser = {
              userId: userId,
              email: email,
              password: password,
              isAdmin: false,
              name: fullName,
              sex: "Male",
              age: "",
              phoneNo: "",
              accountCreationTime: getDesiredDateTime(false),
            };

            // Signed in
            // ...
            await addDoc(
              collection(db, "allRegisteredUsers"),
              curatedUser
            ).then(() => {
              toast.success("New user added");
              router.push({
                name: "home-page",
              });
            });
          })
          .catch((error) => {
            if (this.signupError.includes("already-in-use")) {
              this.signupError = error.message;
              toast.warning("Account exists!");
              setTimeout(() => {
                this.signupError = "";
              }, 2000);
            } else {
              this.signupError = error.message;
              toast.warning("SignUp failed!");
              setTimeout(() => {
                this.signupError = "";
              }, 2000);
            }
          });
      }
    },

    //observe auth state to determine whether user is loggedin or not
    observerLoggedInState() {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.currentUser = user;
          this.isLoggedIn = true;
          this.extractCustomizedUser(user.uid).then(() => {
            if (this.extractedCustomUser !== null) {
              this.userName = this.extractedCustomUser.name;
              this.isAdmin = this.extractedCustomUser.isAdmin;
            }
          });

          // ...
        } else {
          // User is signed out
          this.isLoggedIn = false;
          this.userName = "Sampriti";
        }
      });
    },

    //if user is loggedin then extract the customUser
    //with additional info from firestore

    async extractCustomizedUser(userId, isUpdate) {
      if (userId.length === 0) {
        return null;
      } else {
        if (this.userRefId.length !== 0) {
          if (isUpdate === true && this.extractedCustomUser !== null) {
            const docRef = doc(db, "allRegisteredUsers", this.userRefId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              this.extractedCustomUser = docSnap.data();
            } else {
              this.userRefId = null;
            }
          }
          if (this.extractedCustomUser === null) {
            const docRef = doc(db, "allRegisteredUsers", this.userRefId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
              this.extractedCustomUser = docSnap.data();
            } else {
              this.userRefId = null;
            }
          }
        } else {
          const querySnapshot = await getDocs(
            collection(db, "allRegisteredUsers")
          ).catch((e) => {
            console.log(e.message);
          });

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              if (doc.data().userId === userId) {
                this.extractedCustomUser = doc.data();
                this.userRefId = doc.id;
              }
            });
          }
        }
      }
    },

    //log out the registered user
    logout() {
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          this.isLoggedIn = false;
          this.currentUser = null;
          this.extractedCustomUser = null;
          this.userRefId = null;
          toast.success("Logout successful");
          this.$reset();
          userAddressCollection().$reset();
        })
        .catch(() => {
          // An error happened.
          toast.warning("Logout failed!");
        });
    },

    //send password reset link
    async resetPassword(email) {
      if (email.length === 0) {
        return;
      } else {
        await sendPasswordResetEmail(auth, email)
          .then(() => {
            router.push({
              name: "signin-login",
            });
            toast.success("Reset email sent");
          })
          .catch(() => {
            toast.error("Error occurred");
          });
      }
    },

    //extract custom users document reference
    async getDocumentReferenceOfCustomUser(userId) {
      if (!userId) {
        return null;
      } else {
        var docId = "";
        if (userId.length === 0) {
          return null;
        } else {
          const querySnapshot = await getDocs(
            collection(db, "allRegisteredUsers")
          ).catch((e) => {
            console.log(e.message);
          });
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              if (doc.data().userId === userId) {
                docId = doc.id;
              }
            });
            return docId;
          }
        }
      }
    },

    //update user details
    async updateUserDetails(modifiedUser) {
      if (this.currentUser === null) {
        return;
      } else {
        var targetUserRefId =
          this.userRefId.length > 0
            ? this.userRefId
            : await this.getDocumentReferenceOfCustomUser(this.currentUser.uid);
        if (targetUserRefId !== null) {
          const addressRef = doc(db, "allRegisteredUsers", targetUserRefId);

          if (modifiedUser !== null) {
            await updateDoc(addressRef, modifiedUser).then(async () => {
              await this.extractCustomizedUser(this.currentUser.uid, true).then(
                () => {
                  toast.success("User details edited successfully");
                }
              );
            });
          }
        }
      }
    },
  },
  persist: true,
});
