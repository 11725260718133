<template>
  <div>
    <section class="footer-section">
      <div class="footer">
        <p>Copyright © {{date.getFullYear()}} Sampriti Publication.</p>
        <!-- <p>All Rights Reserved.</p> -->
        <h6 id="dname" v-if="path === '/'">
          Designed & Developed by: <b>Manjit Bhagawati</b>
        </h6>
        <h6 id="dcontact" v-if="path === '/'">
          Developer contact: <span>latebloomermegabyte@gmail.com</span>
        </h6>
      </div>
    </section>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useRoute } from "vue-router";
export default {
  name: "siteFooter",
  setup() {
    const date= new Date();



    const route = useRoute();

    const path = computed(() => route.path);


    return { path,date };
  },
};
</script>

<style lang="scss" scoped>
.footer-section {
  height: 100px;
  padding-top: 25px;

  @media (max-width: 468px) {
    height: auto;
    padding-top: 0px;
  }

  .footer {
    height: 100%;
    padding: 10px 20px;

    // background: red;

    p {
      font-size: 11px;
      @media (max-width: 468px) {
        font-size: 10px;
      }
    }
    #dname {
      font-size: 9px;
      color: white;
      @media (max-width: 468px) {
        font-size: 8.5px;
      }
    }
    #dcontact {
      font-size: 9.1px;
      color: white;
      span{
        text-decoration: underline;
      }

      @media (max-width: 468px) {
        font-size: 8.6px;
      }
    }
  }
}
</style>
