<template>
  <div class="wrapper">
    <div class="post-view">
      <div class="post-category">
        <button>{{ post.type }}</button>
      </div>

      <div class="post-title">
        <h6>
          <b> {{ post.title }} </b>
        </h6>
      </div>

      <div class="upload-details">
        <button disabled="disabled">by Admin</button>
        <p>{{ post.time }}</p>
      </div>

      <div class="links">
        <p v-if="postLinks.length !== 0">Important Links</p>
        <div class="all-links">
          <a
            v-for="(v, i) in post.importantLinks"
            :key="i"
            :href="v.linkvalue"
            target="_blank"
            >{{ v.label }}</a
          >
        </div>
      </div>

      <div class="post-image" v-if="postImage.length !== 0">
        <img :src="post.postImage" alt="" />
      </div>

      <div class="other-images" v-if="otherImages.length !== 0">
        <img v-for="(v, i) in post.otherImages" :key="i" :src="v" />
      </div>

      <div class="post-description">
        <p>
          {{ post.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
//importing post store
import { PostCollectionStore } from "@/stores/posts";
import { ref } from "@vue/reactivity";
import { onMounted } from "vue";

export default {
  name: "fullpost-view",

  setup() {
    //store instance
    const allPost = PostCollectionStore();

    //post instance
    const post = ref({});
    //post links
    const postLinks = ref([]);

    //post image
    const postImage = ref([]);
    //other images
    const otherImages = ref([]);

    onMounted(() => {
      var filtered = allPost.PostCollection.filter(
        (post) => post.id === allPost.selectedIndex
      );

      post.value = filtered[0];
      console.log(post.value);
      postLinks.value = post.value.importantLinks;
      postImage.value = post.value.postImage;
      otherImages.value = post.value.otherImages;
    });

    return { post, postLinks, postImage, otherImages };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-image: url(https://cdn.pixabay.com/photo/2018/05/26/01/48/thunderstorm-3430471_960_720.jpg);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 50px;

  @media (max-width: 700px) {
    padding: 50px 10px;
  }

  @media (max-width: 400px) {
    padding: 5px 5px;
  }

  .post-view {
    height: 80%;
    width: 80%;
    background-color: #213a5a;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.18);

    @media (max-width: 700px) {
      width: 98%;
      height: 98%;
    }

    .post-category {
      button {
        padding: 2px 15px;
        font-size: 12px;
        margin-top: 20px;
        margin-left: 20px;
        border: none;
        outline: none;
        background: blue;
        color: white;
        font-weight: 600;
      }
    }

    .post-title {
      h6 {
        line-height: 25px;
        color: white;
        font-size: 13.2px;
        padding: 35px 35px;
        text-align: justify;

        // font-weight: 700;
        @media (max-width: 700px) {
          text-align: start;
          font-size: 13px;
        }

        @media (max-width: 400px) {
          padding: 35px 15px;
        }
      }
    }

    .upload-details {
      display: flex;

      button {
        background: white;
        border: none;
        height: 25px;
        width: 100px;
        outline: none;
        padding: 3px 15px;
        font-size: 11px;
        margin: auto 35px;
        font-weight: 500;

        @media (max-width: 400px) {
          text-align: start;
          font-size: 8px;
          font-weight: 600;
        }
      }

      p {
        color: whitesmoke;
        font-size: 11px;

        @media (max-width: 400px) {
          text-align: start;
          font-size: 10px;
        }
      }
    }

    .links {
      margin: 25px 15px;

      p {
        padding-left: 20px;
        color: white;
        font-size: 13px;
        font-weight: 600;
        text-decoration: underline;
      }

      .all-links {
        display: flex;

        a {
          padding-left: 20px;
          font-size: 11.5px;
          font-weight: 500;
        }
      }
    }

    .post-image {
      height: 40%;
      width: 75%;
      margin: 35px auto;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
        cursor: pointer;
      }
    }

    .other-images {
      height: 20%;
      width: 90%;

      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 27%;
      gap: 8px;
      overflow: auto;
      overscroll-behavior-inline: contain;
      padding-top: 5px;
      margin: 20px auto;

      img {
        border-radius: 10px;
        cursor: pointer;
        max-height:150px;
        transition: all 350ms ease-in;
        &:hover {
          transform: scaleX(1.2);
          border: .5px dotted goldenrod;
        }
      }
    }

    .post-description {
      p {
        color: white;
        font-size: 13px;
        line-height: 22px;
        letter-spacing: 0.2px;
        padding: 35px;
        text-align: justify;
        font-weight: 400;

        cursor: pointer;

        @media (max-width: 400px) {
          padding: 35px 15px;
        }
      }
    }
  }
}
</style>