<template>
  <div class="wrapper">
    <div v-if="hasItems === true" class="cart-items">
      <div class="cart-item" v-for="(v, i) in wishlist" :key="i">
        <!-- <div class="availability">
          <button>Unavailable</button>
        </div> -->
        <div class="other-contents" @click="goToPage(v.id)">
          <div class="when-added">
            <!-- <p>Added 26 July,2022</p> -->
            <button @click.stop="removeItemFromWishlist(v.id)">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div class="details">
            <div class="item-img">
              <div class="image-container">
                <img :src="v.imageUrl" />
              </div>
              <div class="infos">
                <h6>
                  <b>{{ v.bookName }} </b>
                </h6>
                <div class="price">
                  <div class="current-price">
                    <p id="current">
                      Best Price:
                      <b
                        >{{ v.currentPrice }}
                        <i class="fa-solid fa-indian-rupee-sign fa-sm"></i
                      ></b>
                    </p>
                    <p id="discount">
                      discount: <b>{{ v.discount }}%</b>
                    </p>
                  </div>
                  <div class="mrp">
                    <p>
                      MRP:
                      <b
                        >{{ v.mrp }}
                        <i class="fa-solid fa-indian-rupee-sign fa-sm"></i
                      ></b>
                    </p>
                  </div>
                  <div class="author">
                    <p>
                      Author: <b>{{ v.authorName }}</b>
                    </p>
                  </div>
                  <div class="order-now">
                    <button @click.stop="addWishListItemToShoppingCart(v.id)">
                      Order Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="empty" v-else>
      <h6><b>The Wishlist is Empty!</b></h6>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import router from "@/router";
import { bookOrderCollection } from "@/stores/bookorder-collection";
import { onMounted } from "@vue/runtime-core";
import { uploadBooks } from "@/stores/upload-book";
import { shoppingCartStore } from "@/stores/shopping-cart-store";

// import { createCollectionReference } from "@/helper-functions/create-collection-reference";

export default {
  name: "user-wishlist",

  setup() {
    //bookstore instance
    const books = uploadBooks();

    //shopping cart instance
    const shoppingCart = shoppingCartStore();

    //order and wishlist collection
    const owCollection = bookOrderCollection();

    const wishlist = ref([]);

    //whether the cart has items
    const hasItems = ref(false);

    onMounted(() => {
      owCollection.extractUserWishlist().then(() => {
        if (owCollection.userWishList.length !== 0) {
          wishlist.value = owCollection.userWishList;
          // console.log(wishlist.value);
          hasItems.value = true;
        }
      });
    });

    function removeItemFromWishlist(id) {
      wishlist.value = owCollection.removeWishlistItem(id);
      if (wishlist.value.length === 0) {
        hasItems.value = false;
      }
    }

    function goToPage(id) {
      books.setSelectedId(id);

      router.push({
        name: "book-view",
      });
    }

    //function to add wishlist item to the shopping cart as order
    function addWishListItemToShoppingCart(id) {
      shoppingCart.collectItemIds(id);
      this.removeItemFromWishlist(id);
    }

    return {
      hasItems,
      goToPage,
      wishlist,
      removeItemFromWishlist,
      addWishListItemToShoppingCart,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin borOutlineNone {
  border: none;
  outline: none;
}

.wrapper {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 212, 255, 1) 50%
  );

  .empty {
    height: 100vh;
    width: 100%;
    display: grid;
    place-items: center;

    h6 {
      font-size: 25px;
      color: black;
      font-weight: 500;
      letter-spacing: 0.1px;
      text-shadow: 0px 5px 10px #8f5902;

      @media (max-width: 400px) {
        font-size: 20px;
      }
    }
  }

  .cart-items {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    background-color: transparent;
    padding: 35px 10px;

    @media screen and (max-width: 658px) {
      padding: 35px 5px;
    }

    .cart-item {
      background: yellow;
      height: 250px;
      width: 80%;
      background: transparent;
      margin: 45px auto;

      @media screen and (max-width: 658px) {
        height: 420px;
        width: 100%;
        margin: 30px auto;
      }

      .availability {
        height: auto;
        width: 100%;
        margin-bottom: 5px;

        button {
          font-size: 12px;
          font-weight: 500;
          padding: 3px 13px;
          border-radius: 4px;
          @include borOutlineNone();
        }

        @media screen and (max-width: 658px) {
          height: auto;
        }
      }

      .other-contents {
        cursor: pointer;
        background: rgba($color: black, $alpha: 0.65);
        border-radius: 10px;

        @media screen and (max-width: 658px) {
          height: 90%;
        }

        .when-added {
          display: flex;
          justify-content: flex-end;
          padding: auto;
          height: 30px;

          p {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.12px;
            margin: auto 10px;
            color: white;
          }

          button {
            background: transparent;
            @include borOutlineNone();
            font-size: 20px;
            margin: auto 20px;
            color: white;

            &:hover {
              color: red;
              transform: scale(1.1);
              transition: all 200ms ease-in;
            }
          }

          @media screen and (max-width: 658px) {
            height: auto;
          }
        }

        .details {
          height: 200px;
          width: auto;
          display: flex;

          @media screen and (max-width: 658px) {
            height: 85%;
            width: auto;
          }

          .item-img {
            display: flex;
            width: 100%;

            @media screen and (max-width: 658px) {
              flex-direction: column;
            }

            .image-container {
              height: 100%;
              width: 50%;

              @media screen and (max-width: 658px) {
                height: 50%;
                width: 100%;
                margin: 10px auto 10px 5px;
              }

              @media screen and (max-width: 270px) {
                margin: 10px auto 10px auto;
              }

              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 8px;

                @media screen and (max-width: 658px) {
                  height: 100%;
                  width: 90%;
                  object-fit: cover;
                }

                @media screen and (max-width: 270px) {
                  object-fit: scale-down;
                }
              }
            }

            .infos {
              width: 50%;
              height: 100%;
              padding-left: 30px;

              @media screen and (max-width: 658px) {
                height: 50%;
                width: 100%;
                padding-left: 1px;
              }

              h6 {
                font-size: 17px;
                font-weight: 700;
                width: 95%;
                white-space: nowrap;
                letter-spacing: 0.1px;
                text-overflow: ellipsis;
                line-height: 25px;
                overflow: hidden;
                margin: 12px auto;
                color: white;

                @media screen and (max-width: 658px) {
                  font-size: 13px;
                  line-height: 20px;
                  margin: 9px auto;
                  width: 80%;
                  margin-left: 4px;
                }
              }

              .price {
                width: 96%;
                margin: 9px auto;

                @media screen and (max-width: 658px) {
                  margin: 6px auto;
                }

                .current-price {
                  display: flex;

                  #current {
                    font-size: 12.5px;
                    font-weight: 500;
                    letter-spacing: 0.1px;
                    margin-right: 20px;
                    color: white;

                    @media screen and (max-width: 658px) {
                      font-size: 10.3px;
                    }
                  }

                  #discount {
                    background-color: white;
                    padding: 2px 9px;
                    font-size: 13px;
                    font-weight: 600;
                    border-radius: 9px;

                    @media screen and (max-width: 658px) {
                      font-size: 11px;
                    }
                  }
                }

                .mrp {
                  p {
                    font-size: 12.5px;
                    font-weight: 500;
                    letter-spacing: 0.1px;
                    margin-right: 20px;
                    color: white;

                    @media screen and (max-width: 658px) {
                      font-size: 10.3px;
                    }
                  }
                }
              }

              .author {
                width: 96%;

                p {
                  font-size: 12px;
                  font-weight: 600;
                  letter-spacing: 0.2px;
                  color: white;

                  @media screen and (max-width: 658px) {
                    font-size: 10.3px;
                  }
                }
              }

              .order-now {
                button {
                  @include borOutlineNone();
                  height: 25px;
                  font-size: 11px;
                  font-weight: 600;
                  padding: 4px 15px;
                  color: white;
                  letter-spacing: 0.1px;
                  background-color: blue;
                  border-radius: 4px;
                  margin: 25px auto;

                  &:hover {
                    transform: skewY(-7deg);
                    transition: all 250ms ease-in;
                    background: goldenrod;
                    color: white;
                    border: 0.5px solid black;
                  }

                  @media screen and (max-width: 658px) {
                    margin: 10px auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
