<template>
  <div class="wrapper">
    <div class="order-id">
      <h6><b>Order-ID:</b></h6>
      <p>{{ selectedOrder.orderId }}</p>
    </div>
    <div class="order-id">
      <h6><b>Order Date:</b></h6>
      <p>{{ selectedOrder.orderCreateTime }}</p>
    </div>
    <div class="order-id">
      <h6><b>Due Delivery Date:</b></h6>
      <p>{{ selectedOrder.expectedDeliveryTime }}</p>
    </div>

    <div class="order-status">
      <h6><b>Order Status:</b></h6>
      <p>{{ orderStatus }}</p>
      <button @click="showOrHideModal(false)">change</button>
    </div>
    <div class="delivery-status">
      <h6><b>Delivery Status:</b></h6>
      <p>{{ deliveryStatus }}</p>
      <button @click="showOrHideModal(true)">change</button>
    </div>
    <div class="order-id">
      <h6><b>Payment Status:</b></h6>
      <p>{{ selectedOrder.paymentStatus }}</p>
    </div>

    <div class="customer-details">
      <div class="customer-name">
        <h6><b>Customer Name:</b></h6>
        <p>{{ deliveryAddress.name }}</p>
      </div>
      <div class="customer-address">
        <h6><b>Delivery Address</b></h6>
        <p>
          name: <b>{{ deliveryAddress.name }}</b>
        </p>
        <p>
          vill/locality:
          <b>{{ deliveryAddress.address1 }},{{ deliveryAddress.address2 }}</b>
        </p>
        <p>
          PO: <b>{{ deliveryAddress.postoffice }}</b>
        </p>
        <p>
          PIN: <b>{{ deliveryAddress.pin }}</b>
        </p>
        <!-- <p>PS: <b>Mangaldai</b></p> -->
        <p>
          District: <b>{{ deliveryAddress.district }}</b>
        </p>
        <p>
          State: <b>{{ deliveryAddress.state }}</b>
        </p>
        <p>
          Country: <b>{{ deliveryAddress.country }}</b>
        </p>
        <p>
          Phone Number: <b>{{ deliveryAddress.phoneNo }}</b>
        </p>
      </div>
    </div>

    <!-- item list -->
    <div class="ordered-item-list">
      <table class="table">
        <tr>
          <th
            v-for="(header, index) in tableHeaders"
            class="tbheader"
            :key="index"
          >
            {{ header }}
          </th>
        </tr>
        <tr v-for="(order, index) in itemList" :key="index">
          <td class="tbdata">
            <p>{{ order.bookName }}</p>
          </td>
          <td class="tbdata">
            <p>{{ order.quantity }}</p>
          </td>
          <td class="tbdata">
            <p>
              {{ Number(order.totalItemPrice).toFixed(2) }}
              <i class="fa-solid fa-indian-rupee-sign fa-xs"></i>
            </p>
          </td>
        </tr>
      </table>

      <div class="total-amount">
        <p>
          Total amount received:
          <span
            >{{ Number(totalOrderValue).toFixed(2) }}
            <i class="fa-solid fa-indian-rupee-sign fa-xs"></i
          ></span>
        </p>
      </div>
    </div>

    <!-- update button -->
    <div class="save-changes">
      <button>Save Changes</button>
      <button>Cancel</button>
    </div>

    <div class="change-modal" v-if="showModal">
      <div class="popup">
        <h6 v-if="changeDeliveryStatus" class="header">
          Change Delivery Status
        </h6>
        <h6 class="header" v-else>Change Order Status</h6>

        <h6 v-if="changeDeliveryStatus" class="status">
          current Delivery Status: <b>{{ selectedOrder.deliveryStatus }}</b>
        </h6>
        <h6 class="status" v-else>
          current odrer Status: <b>{{ selectedOrder.orderStatus }}</b>
        </h6>

        <div class="change-status">
          <label>Choose New Status</label>
          <div class="delivery-status" v-if="changeDeliveryStatus">
            <select>
              <option
                v-for="(status, id) in deliveryStatusList"
                :key="id"
                @click="updateTempStatusHolder(status)"
              >
                {{ status }}
              </option>
            </select>
          </div>
          <div class="order-status" v-else>
            <select>
              <option
                v-for="(status, id) in orderStatusList"
                :key="id"
                @click="updateTempStatusHolder(status)"
              >
                {{ status }}
              </option>
            </select>
          </div>
        </div>

        <div class="buttons">
          <button
            @click="
              updateStatus(
                changeDeliveryStatus,
                tempStatusHolder,
                selectedOrder.orderId
              )
            "
          >
            update
          </button>
          <button @click="cancelModal()">cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { bookOrderCollection } from "@/stores/bookorder-collection";

export default {
  name: "order-view-for-admin",
  setup() {
    //order collection instance
    const orderCollection = bookOrderCollection();

    //delivery address
    const deliveryAddress = ref('');
    //delivery address
    const itemList = ref([]);

    const totalOrderValue = ref("");

    const orderStatus = ref("");

    const deliveryStatus = ref("");
    onMounted(() => {
      selectedOrder.value = orderCollection.currentSelectedOrderForAdmin;

      
      
      deliveryAddress.value = selectedOrder.value.deliveryAddress;
      itemList.value = selectedOrder.value.orderedItemsList;
      var price = 0;
      itemList.value.forEach((item) => {
        price += Number(item.totalItemPrice);
      });
      totalOrderValue.value = price;
      orderStatus.value = orderCollection.currentSelectedOrderStatus;
      deliveryStatus.value = orderCollection.currentSelectedOrderDeliveryStatus;
    });

    const selectedOrder = ref("");

    const showModal = ref(false);

    const changeDeliveryStatus = ref(false);

    //variable to hold temporary status value
    const tempStatusHolder = ref("");

    function updateTempStatusHolder(status) {
      tempStatusHolder.value = status;
    }

    const deliveryStatusList = ["Not Initiated", "In Route", "Delivered"];
    const orderStatusList = ["Active", "Fulfilled"];

    const tableHeaders = [
      "Product Name",
      "No of Copies ordered",
      "Total Price",
    ];

    //function to update respective statuses
    function updateDeliveryStatus(status, id) {
      orderCollection.changeOrderDeliveryStatus(id, status);
      deliveryStatus.value = orderCollection.currentSelectedOrderDeliveryStatus;
    }
    function updateOrderStatus(status, id) {
      orderCollection.changeOrderStatus(id, status);
      orderStatus.value = orderCollection.currentSelectedOrderStatus;
    }

    function updateStatus(isDelivery, tempStatus, id) {
      if (tempStatus) {
        if (isDelivery) {
          updateDeliveryStatus(tempStatus, id);
        } else {
          updateOrderStatus(tempStatus, id);
        }
        showModal.value = false;
      } else {
        alert("Select to update");
      }
    }

    //show or hide modal
    function showOrHideModal(isDelivery) {
      showModal.value = !showModal.value;

      if (isDelivery) {
        changeDeliveryStatus.value = true;
      } else {
        changeDeliveryStatus.value = false;
      }
    }

    function cancelModal() {
      showModal.value = false;
    }

    return {
      totalOrderValue,
      itemList,
      deliveryAddress,
      tableHeaders,
      changeDeliveryStatus,
      deliveryStatusList,
      orderStatusList,
      showModal,
      tempStatusHolder,
      updateDeliveryStatus,
      updateOrderStatus,
      showOrHideModal,
      cancelModal,
      updateTempStatusHolder,
      updateStatus,
      selectedOrder,
      orderStatus,
      deliveryStatus,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: linear-gradient(#4671ea, #ac34e9);
  padding: 25px 30px;

  @media (max-width: 500px) {
    padding: 25px 10px;
  }

  .order-id {
    display: flex;
    margin: 20px 0px;

    h6 {
      font-size: 15px;

      @media (max-width: 500px) {
        // margin: 0px 5px;
        font-size: 12px;
        margin-top: 6px;
      }
    }

    p {
      font-size: 14px;
      margin: auto 25px;
      font-weight: 500;

      @media (max-width: 500px) {
        // margin: 0px 5px;
        font-size: 11px;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }

  .order-status,
  .delivery-status {
    display: flex;

    margin: 30px auto;

    h6 {
      font-size: 15px;

      @media (max-width: 500px) {
        font-size: 12px;
        margin-top: 6px;
      }
    }

    p {
      text-align: start;
      font-size: 14px;
      margin: 0px 20px;

      @media (max-width: 500px) {
        margin: 0px 6px;
        font-size: 12px;
        font-weight: 600;
      }
    }

    button {
      height: auto;
      width: auto;
      padding: 5px 20px;
      color: black;
      background: white;
      border: none;
      outline: none;
      border-radius: 15px;
      font-size: 12px;

      @media (max-width: 500px) {
        font-size: 9.5px;
      }
    }
  }

  .customer-details {
    margin: 30px auto;

    .customer-name {
      display: flex;
      margin: 30px auto;

      h6 {
        font-size: 15px;

        @media (max-width: 500px) {
          font-size: 12.1px;
          margin-top: 6px;
          letter-spacing: 0.15px;
        }
      }

      p {
        font-size: 13px;
        margin: auto 15px;
      }
    }

    .customer-address {
      h6 {
        font-size: 15px;

        @media (max-width: 500px) {
          font-size: 12.1px;
          margin-top: 6px;
          letter-spacing: 0.15px;
        }
      }

      p {
        font-size: 14px;
        margin: 10px;
        letter-spacing: 0.1px;

        @media (max-width: 500px) {
          font-size: 12px;
          letter-spacing: 0.15px;
        }
      }
    }
  }

  .ordered-item-list {
    width: 100%;
    margin: 30px auto;

    height: auto;
    padding: 15px;

    .table {
      th {
        font-size: 14px;
        letter-spacing: 0.15px;

        @media (max-width: 500px) {
          font-size: 12px;
        }
      }

      td {
        p {
          font-size: 12px;

          @media (max-width: 500px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .total-amount {
    p {
      font-size: 14px;
      margin: 40px 0px;
      font-weight: 700;
    }
  }

  .save-changes {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 30px auto;

    button {
      height: 30px;
      width: auto;
      padding: 5px 20px;
      border-radius: 14px;
      border: none;
      outline: none;
      margin: 30px 20px;
      font-size: 12px;

      @media (max-width: 500px) {
        font-size: 9.5px;
      }
    }
  }

  .change-modal {
    position: fixed;
    inset: 0;
    background-color: rgba($color: #000000, $alpha: 0.75);
    display: grid;
    place-items: center;

    .popup {
      height: 300px;
      width: 40%;
      background: #ac34e9;

      @media (max-width: 768px) {
        height: 50%;
        width: 70%;
      }
      @media (max-width: 400px) {
        height: 80%;
        width: 90%;
      }

      .header {
        font-size: 14.5px;
        font-weight: 600;
        letter-spacing: 0.2px;
        margin: 25px auto;
        text-align: center;
      }

      .status {
        font-size: 13.5px;
        font-weight: 600;
        letter-spacing: 0.2px;
        color: white;
        text-align: center;
      }

      .change-status {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        label {
          font-size: 13.5px;
          text-decoration: underline;
          text-align: center;
          margin-top: 20px;
        }

        .delivery-status,
        .order-status {
          font-size: 12px;
          font-weight: 500;
        }
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          padding: 6px 20px;
          font-size: 11px;
          font-weight: 600;
          background: goldenrod;
          color: white;
          border: none;
          outline: none;
          margin: 0 10px;
          border-radius: 12px;
        }
      }
    }
  }
}
</style>
