import { createRouter, createWebHashHistory } from "vue-router";

import HomePage from "../views/Home-page.vue";
import publishedIssues from "../views/published-issues.vue";

import aboutSampriti from "@/views/aboutSampriti.vue";
import editorialBoard from "@/views/editorialBoard.vue";

import photoGallery from "@/views/photo-gallery.vue";

import guidelines from "@/views/guide-lines.vue";
import buybooks from "@/views/buy-books.vue";
import bookview from "@/views/book-view.vue";
import fullpostview from "@/views/fullpost-view.vue";
import contactUs from "@/views/contact-us.vue";
import signIn from "@/views/signin-login.vue";
import adminOptions from "@/views/admin-options.vue";
import createPost from "@/views/adminOptions/upload-post.vue";
import editPost from "@/views/adminOptions/edit-post.vue";
import uploadbook from "@/views/adminOptions/upload-books-for-sale.vue";
import uplaodPhotos from "@/views/adminOptions/upload-gallery-album.vue";
import uploadNewIssue from "@/views/adminOptions/upload-new-issue.vue";
import handleOrders from "@/views/adminOptions/handle-order.vue";
import orderview from "@/views/adminOptions/order-view.vue";
import allOrders from "@/views/adminOptions/all-orders.vue";
import alltransaction from "@/views/adminOptions/transaction-details.vue";
import paymentErrorNotice from "@/views/adminOptions/payment-error-notice.vue";
import previousOrders from "@/views/user-account/previous-orders.vue";
import accounInfo from "@/views/user-account/account-info.vue";
import wishlist from "@/views/user-account/wishlist.vue";
import shoppingcart from "@/views/user-account/my-shopping-cart.vue";
import currentOrders from "@/views/user-account/current-orders.vue";
import fullOrderView from "@/views/user-account/fullorder-view-for-user.vue";
import userAccountMobileView from "@/views/user-account/user-account-mobile-view.vue";
import orderSummary from "@/views/user-account/order-summary.vue";
import paymentForOrder from "@/views/user-account/payment-for-order.vue";

import { userCreation } from "@/stores/user-verification";

const routes = [
  {
    path: "/",
    name: "home-page",
    component: HomePage,
  },
  {
    path: "/about",
    name: "about-sampriti",
    component: aboutSampriti,
  },

  {
    path: "/editorialBoard",
    name: "editorial-board",
    component: editorialBoard,
  },

  {
    path: "/publishedIssues",
    name: "published-issues",
    component: publishedIssues,
  },
  {
    path: "/photoGallery",
    name: "photo-gallery",
    component: photoGallery,
  },
  {
    path: "/guidelines",
    name: "guide-lines",
    component: guidelines,
  },
  {
    path: "/buybooks",
    name: "buy-books",
    component: buybooks,
  },
  {
    path: "/bookview",
    name: "book-view",
    component: bookview,
  },
  {
    path: "/postview",
    name: "fullpost-view",
    component: fullpostview,
  },
  {
    path: "/contact",
    name: "contact-us",
    component: contactUs,
  },
  {
    path: "/signin",
    name: "signin-login",
    component: signIn,
    meta: {
      guestOnly: true,
    },
  },
  {
    path: "/sampriti-admin",
    name: "admin-options",
    component: adminOptions,
    meta: {
      requiresAuth: true,
      adminOnly: true,
    },
  },
  {
    path: "/sampriti-admin/uploadbook",
    name: "upload-book-for-sale",
    component: uploadbook,
    meta: {
      requiresAuth: true,
      adminOnly: true,
    },
  },
  {
    path: "/sampriti-admin/create-post",
    name: "create-post",
    component: createPost,
    meta: {
      requiresAuth: true,
      adminOnly: true,
    },
  },
  {
    path: "/sampriti-admin/edit-post",
    name: "edit-post",
    component: editPost,
    meta: {
      requiresAuth: true,
      adminOnly: true,
    },
  },
  {
    path: "/sampriti-admin/uploadPhotos",
    name: "upload-gallery",
    component: uplaodPhotos,
    meta: {
      requiresAuth: true,
      adminOnly: true,
    },
  },
  {
    path: "/sampriti-admin/uploadNewIssue",
    name: "upload-new-issue",
    component: uploadNewIssue,
    meta: {
      requiresAuth: true,
      adminOnly: true,
    },
  },
  {
    path: "/sampriti-admin/handleOrders",
    name: "handle-orders",
    component: handleOrders,
    meta: {
      requiresAuth: true,
      adminOnly: true,
    },
  },
  {
    path: "/sampriti-admin/order-details-for-admin",
    name: "order-view-for-admin",
    component: orderview,
    meta: {
      requiresAuth: true,
      adminOnly: true,
    },
  },
  {
    path: "/sampriti-admin/all-orders",
    name: "all-orders",
    component: allOrders,
    meta: {
      requiresAuth: true,
      adminOnly: true,
    },
  },
  {
    path: "/sampriti-admin/transaction-details",
    name: "transaction-details",
    component: alltransaction,
    meta: {
      requiresAuth: true,
      adminOnly: true,
    },
  },
  {
    path: "/user/previous-orders",
    name: "previous-orders",
    component: previousOrders,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/account-information",
    name: "account-info",
    component: accounInfo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/wishlist",
    name: "my-wishlist",
    component: wishlist,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/shoppingcart",
    name: "my-shopping-cart",
    component: shoppingcart,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/shoppingcart/order-summary",
    name: "order-summary",
    component: orderSummary,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/shoppingcart/order-summary/payment-for-order",
    name: "payment-for-order",
    component: paymentForOrder,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/shoppingcart/order-summary/payment-error",
    name: "payment-error",
    component: paymentErrorNotice,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/active-orders",
    name: "current-orders",
    component: currentOrders,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/full-order-view",
    name: "full-order-view",
    component: fullOrderView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/user-account-mobile-view",
    name: "user-account-mobile-view",
    component: userAccountMobileView,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_url),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const guestOnly = to.matched.some((record) => record.meta.guestOnly);
  const adminOnly = to.matched.some((record) => record.meta.adminOnly);

  const currentUser = userCreation().currentUser;
  if (currentUser === null) {
    userCreation().observerLoggedInState();
  }

  const isAdmin = userCreation().isAdmin;
  if (requiresAuth && currentUser === null) {
    next("/signin");
  } else if (guestOnly && currentUser === null) {
    next("/signin");
  } else if (
    requiresAuth &&
    currentUser !== null &&
    adminOnly &&
    isAdmin === null
  ) {
    next("/");
  } else {
    next();
  }
});

export default router;
