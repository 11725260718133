<template>
  <div class="wrapper">
    <div class="page-label">
      <h5><b>Admin Options</b></h5>
    </div>

    <div class="admin-options">
      <ul>
        <li @click="goToPages('create-post')">
          <h6><b>Upload a Post</b></h6>
        </li>
        <li @click="goToPages('edit-post')">
          <h6><b>Edit a Post</b></h6>
        </li>
        <li @click="goToPages('upload-gallery')">
          <h6><b>Upload Photos to Gallery</b></h6>
        </li>
        <li @click="goToPages('upload-new-issue')">
          <h6><b>Upload a New Issue</b></h6>
        </li>
        <li @click="goToPages('upload-book-for-sale')">
          <h6><b>Upload a Book for Sale</b></h6>
        </li>
        <li @click="goToPages('handle-orders')">
          <h6><b>Handle Customer Orders</b></h6>
        </li>
        <li @click="goToPages('all-orders')">
          <h6><b>All Orders</b></h6>
        </li>
        <!-- <li @click="goToPages('transaction-details')"> -->
        <li>
          <h6><b>Transaction Details</b></h6>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import router from "@/router";
export default {
  name: "admin-options",

  setup() {
    function goToPages(name) {
      return router.push({
        name: name,
      });
    }

    return {
      goToPages,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin makeFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  height: 100%;
  width: 100%;
  background-color: #1f272b;
  font-family: "Taviraj", serif;

  @include makeFlex();
  flex-direction: column;

  .page-label {
    @include makeFlex();

    h5 {
      color: white;
      font-size: 25px;
      margin: 40px auto;
    }
  }

  .admin-options {
    height: 80%;
    width: 80%;
    background: #1f272b;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);

    margin: 50px auto;

    @include makeFlex();

    @media (max-width: 400px) {
      width: 95%;
    }

    li {
      display: list-item;
      list-style-type: square;
      color: white;

      h6 {
        font-size: 16px;
        margin: 29px auto;
        text-decoration: underline;

        @media (max-width: 400px) {
          font-size: 13px;
        }
      }

      &:hover {
        cursor: pointer;
        font-size: 16.5px;
        color: goldenrod;
        transform: skewY(-5deg);
        transition: all 300ms ease-in;
      }
    }
  }
}
</style>
