<template>
  <div class="main-banner">
    <section>
      <img
        src="@/assets/images/sky-34536.png"
        alt=""
        id="homepage-firstsection-bg"
      />
      <div class="header-text">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="caption">
                <h6>Hello Readers</h6>
                <h2>
                  Welcome to
                  <span class="journalName">Sampriti</span>
                </h2>
                <p>
                  A Half yearly bi-lingual(Assamese & English) Double-Blind Peer
                  Reviewed National Journal on Humanities & Social Sciences.
                </p>
                <div class="main-button-red">
                  <a href="" @click="goTopublishedIssue" target="_blank">Explore latest> </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "homePageMainBanner",

  setup() {
    function goTopublishedIssue() {
      return router.push({
        name: "published-issues",
      });
    }

    return {
      goTopublishedIssue,
    };
  },
};
</script>

<style lang="scss" scoped></style>
