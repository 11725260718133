<template>
  <div class="wrapper">
    <div class="contact-details">
      <h5><b>Contact us</b></h5>
      <div class="address">
        <h6>Our Address</h6>
        <p>
          NEAR GAUHATI UNIVERSITY, ASSAM FOREST SCHOOL-781014,GHY-14,ASSAM,INDIA
        </p>
      </div>
      <div class="email-address">
        <h6>Contact Email</h6>
        <p>sampritipublication@gmail.com</p>
      </div>
    </div>

    <div class="contact-form">
      <h5><b>Communicate with Us!</b></h5>
      <form
        action="https://formsubmit.co/ad580e3315d160646414381c6099afe3"
        method="POST"
      >
        <input
          type="text"
          class="name"
          name="name"
          placeholder="Your name"
          v-model="senderName"
        />
        <input
          type="text"
          v-model="email"
          class="email"
          name="email"
          placeholder="Email"
          @blur="emailValidator(email)"
        />
        <p v-if="emailError" id="errorInfo">Invalid Email</p>
        <input
          type="text"
          class="subject"
          placeholder="Subject"
          v-model="subject"
          name="subject"
        />
        <textarea
          v-model="txtArea"
          @blur="checkWebLinks(txtArea)"
          placeholder="message..."
          name="message"
        ></textarea>
        <p v-if="txtAreaError" id="errorInfo">Cant Include weblinks</p>
        <div class="sub">
          <button
            type="submit"
            :disabled="
              emailError ||
              txtAreaError ||
              email.length === 0 ||
              txtArea.length === 0
            "
            @click="resetFields"
          >
            SEND
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
// import { getOrderIdFromRazorPay } from "@/helper-functions/razorpay-payment";
export default {
  name: "contact-us",

  setup() {
    const email = ref("");
    const txtArea = ref("");
    const txtAreaError = ref(false);
    const emailError = ref(false);

    const senderName = ref("");
    const subject = ref("");

    function emailValidator(email) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!pattern.test(email.trim())) {
        emailError.value = true;

        return false;
      }
      emailError.value = false;
    }

    function checkWebLinks(txtAreaVal) {
      const pattern =
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;

      if (pattern.test(txtAreaVal.trim())) {
        txtAreaError.value = true;

        console.log(txtAreaError.value);
        return false;
      }
      txtAreaError.value = false;
    }

    //function to send email from contact us form
    function resetFields() {
      email.value === "";
      txtArea.value === "";
      senderName.value === "";
      subject.value === "";
    }

    return {
      senderName,
      subject,
      emailValidator,
      resetFields,
      email,
      txtAreaError,
      txtArea,
      checkWebLinks,
      emailError,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  width: 100%;
  background-color: #172640;
  padding: 30px;

  .contact-details {
    height: 320px;
    width: 50%;
    margin: 50px;
    -webkit-box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.75);
    background: whitesmoke;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);

    padding: 15px;

    @media (max-width: 976px) {
      width: 85%;
    }

    @media (max-width: 450px) {
      width: 100%;
      margin: 2px 2px 40px 2px;
    }

    h5 {
      margin-left: 5px;
      margin-top: 15px;
      text-shadow: 4px 4px 2px rgba(150, 150, 150, 1);
    }

    .address {
      height: 50%;
      width: auto;
      border-bottom: 1px solid rgba(255, 255, 255, 0.18);
      margin: 10px;

      h6 {
        font-size: 14px;
        font-weight: 600;
        text-decoration: underline;
        padding-top: 20px;
      }

      p {
        font-size: 13.5px;
        font-weight: 500;
        padding-top: 10px;
      }
    }

    .email-address {
      text-align: center;

      h6 {
        font-size: 14px;
        font-weight: 600;
      }

      p {
        font-size: 13px;
        text-decoration: underline;
        letter-spacing: 0.15px;
        font-weight: 500;
      }
    }
  }

  .contact-form {
    background: transparent;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    height: auto;
    width: 50%;
    margin: 100px 50px 50px 50px;
    z-index: 5;

    padding: 10px;
    display: flex;
    flex-direction: column;

    #errorInfo {
      color: red;
      padding-left: 20px;
    }

    @media (max-width: 976px) {
      width: 85%;
    }

    @media (max-width: 450px) {
      width: 100%;
      margin: 2px;
    }

    h5 {
      text-align: center;
      color: white;
      padding: 25px;
      font-size: 16.5px;
      letter-spacing: 0.12px;
      text-shadow: 4px 4px 2px rgba(150, 150, 150, 1);
    }

    form {
      .name,
      .email,
      .subject {
        margin: 20px auto;
        border-radius: 20px;
        height: 40px;
        width: 100%;
        border: none;
        outline: none;
        text-align: center;
        font-size: 13px;

        &::placeholder {
          text-align: center;
          font-size: 12px;
          color: black;
        }
      }

      textarea {
        height: 100px;
        border-radius: 15px;
        margin: 20px auto;
        display: flex;
        width: 100%;

        justify-content: center;
        align-items: center;
        border: none;
        outline: none;

        text-align: center;
        font-size: 13px;

        &::placeholder {
          text-align: center;
          font-size: 12px;
          color: black;
        }
      }

      .sub {
        width: 100%;
        text-align: center;
        button {
          margin: 35px auto;
          width: 220px;
          font-size: 11px;
          font-weight: 600;
          padding: 5px 20px;
          border-radius: 15px;
          border: none;
          outline: none;

          &:hover {
            background-color: #ffd700;
            color: black;
            transform: skewY(4deg);
            transition: all 300ms ease-in;
          }
          a {
            color: black;
          }
        }
      }
    }
  }
}
</style>
