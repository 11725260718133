<template>
    <div>
        <homePageMainBanner></homePageMainBanner>
        <homePageCarousel></homePageCarousel>
        <read-latest></read-latest>
        <uploaded-posts></uploaded-posts>
    </div>
</template>

<script>
import homePageMainBanner from '@/components/home-page-main-banner.vue'

import homePageCarousel from '../components/home-page-carousel.vue'
import readLatest from '../components/read-latest.vue'
import uploadedPosts from '../components/uploaded-posts.vue'
export default {

    name:'home-page',

    components: {
        homePageMainBanner,
        uploadedPosts,
        homePageCarousel,
        readLatest
    },

    setup() {

        

        return {}
    }
}
</script>

<style lang="scss" scoped>
</style>