import { addDoc, collection, getDocs } from "firebase/firestore";
import { defineStore } from "pinia";
import { v4 as uid } from "uuid";
import { useToast } from "vue-toastification";
import { db } from "@/firebase/firebase-init.js";
import { trackChangesInTheDatabase } from "@/stores/track-changed-collections-by-admin";

const toast = useToast();

export const uploadBooks = defineStore("bookssale", {
  state: () => ({
    uploadedBooks: [],
    LastSnapshotUpdateTime: [],
    extractedBooks: [],
    selectedId: "",
  }),
  getters: {},
  actions: {
    //function to create a new book
    async newBook(book) {
      var bookObject = {
        id: uid(),
        ...book,
      };

      this.uploadedBooks.push(book);
      // Add a new document in collection "allAddresses"
      await addDoc(collection(db, "allBooksForSale"), bookObject).then(() => {
        toast.success("New book added");
      });

      var timeObj = new Date();
      var LastSnapshotUpdateObject = {
        date: timeObj.getDate(),
        time: timeObj.getTime(),
        month: timeObj.getMonth(),
        year: timeObj.getFullYear(),
      };
      await trackChangesInTheDatabase().addNewDatabaseChange(
        "book",
        LastSnapshotUpdateObject
      );
    },

    //extract all posts from firebase
    async extractAllPostsFromFirebase() {
      if (this.uploadedBooks.length === 0) {
        const querySnapshot = await getDocs(collection(db, "allBooksForSale"));
        // this.downloadedQuerySnapshot = querySnapshot;
        this.LastSnapshotUpdateTime = [];
        var timeObj = new Date();
        var LastSnapshotUpdateObject = {
          date: timeObj.getDate(),
          time: timeObj.getTime(),
          month: timeObj.getMonth(),
          year: timeObj.getFullYear(),
        };

        this.LastSnapshotUpdateTime.push(LastSnapshotUpdateObject);
        this.extractedBooks = [];
        querySnapshot.forEach((doc) => {
          this.extractedBooks.push(doc.data());
        });
        if (this.extractedBooks.length !== this.uploadedBooks.length) {
          this.uploadedBooks = [];
          this.extractedBooks.forEach((book) => {
            this.uploadedBooks.push(book);
          });
        }
      } else {
        var hasChange = false;
        await trackChangesInTheDatabase()
          .checkChangeInDatabase("book")
          .then(() => {
            var changeObj = trackChangesInTheDatabase().bookChangeObject;

            if (changeObj === null) {
              return;
            }

            if (
              JSON.stringify(changeObj) ===
              JSON.stringify(this.LastSnapshotUpdateTime[0])
            ) {
              return;
            } else {
              if (changeObj.date < this.LastSnapshotUpdateTime[0].date) {
                hasChange = false;
              } else if (changeObj.year < this.LastSnapshotUpdateTime[0].year) {
                hasChange = false;
              } else if (changeObj.time < this.LastSnapshotUpdateTime[0].time) {
                hasChange = false;
              } else if (
                changeObj.month < this.LastSnapshotUpdateTime[0].month
              ) {
                hasChange = false;
              } else {
                hasChange = true;
              }
            }
          });

        //run this is there is a chnage
        if (hasChange) {
          const querySnapshot = await getDocs(
            collection(db, "allBooksForSale")
          );

          this.LastSnapshotUpdateTime = [];
          var timeObj2 = new Date();
          var LastSnapshotUpdateObject2 = {
            date: timeObj2.getDate(),
            time: timeObj2.getTime(),
            month: timeObj2.getMonth(),
            year: timeObj2.getFullYear(),
          };

          this.LastSnapshotUpdateTime.push(LastSnapshotUpdateObject2);

          this.extractedBooks = [];
          querySnapshot.forEach((doc) => {
            this.extractedBooks.push(doc.data());
          });
        }
      }
    },

    //this should be implemented with the Id rather than setting the indx
    setSelectedId(id) {
      this.selectedId = id;
    },

    //search item via id
    async getBookFromId(givenId) {
      var filteredItem;
      await this.extractAllPostsFromFirebase().then(() => {
        filteredItem = this.uploadedBooks.filter((item) => item.id === givenId);
      });
      if (filteredItem !== null) {
        return filteredItem;
      } else {
        return null;
      }
    },
  },
  persist: true,
});
