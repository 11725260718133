<template>
  <div class="wrapper">
    <div class="main-section">
      <button @click="orderCompleteProcess">
        <p>Proceed to Pay</p>
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
// import { bookOrderCollection } from "@/stores/bookorder-collection";

//import the shopping cart store
// import { shoppingCartStore } from "@/stores/shopping-cart-store";
import router from "@/router";
export default {
  name: "payment-for-order",
  setup() {
    //Order collection Instance
    // const orderCollection = bookOrderCollection();

    //shopping cart instance
    // const shoppingCart = shoppingCartStore();

    //payment status
    const paymentStatus = ref(false);

    function orderCompleteProcess() {
      // paymentStatus.value = true;
      // if (paymentStatus.value) {
      //   const orderCompletionStatus = orderCollection.createCompleteOrder();

      //   if (orderCompletionStatus) {
      //     shoppingCart.$reset();
      //     // router.push({
      //     //   name: "current-orders",
      //     // });

      //     shoppingCart.deleteIdCollectionNshoppingCartItems();
      //   }
      // }
      router.push({
            name: "payment-error",
          });
    }

    return {
      paymentStatus,
      orderCompleteProcess,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to right top,
    #051937,
    #003951,
    #08595d,
    #4b7862,
    #88936d
  );
  padding: 30px 40px;

  .main-section {
    height: 70vh;
    width: 100%;
    display: grid;
    place-content: center;

    button {
      border: none;
      outline: none;
      border-radius: 7px;
      p {
        font-size: 13.5px;
        padding: 1.5px 30px;
        font-weight: 600;

        @media (max-width: 287px) {
          font-size: 12.6px;
        }
      }
    }
  }
}
</style>