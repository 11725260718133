<template>
  <div class="wrapper">
    <div class="upload-area">
      <h6><b>Fill-Up Book Details</b></h6>
      <div class="name-field">
        <label>Book Name</label>
        <textarea
          name="name"
          type="text"
          placeholder="Enter Book Name"
          required
          v-model="bookName"
        ></textarea>
      </div>
      <div class="author-name">
        <label>Author Name</label>
        <input
          type="text"
          placeholder="Enter Author Name"
          v-model="authorName"
        />
      </div>

      <div class="author-description">
        <label>Author Details</label>
        <textarea
          placeholder="Author Details(if any!)"
          v-model="authorDetails"
        ></textarea>
      </div>

      <div class="prices">
        <div class="MRP">
          <label>MRP</label>
          <input
            type="text"
            @keyup="onlyNumberAllowd('mrp')"
            maxlength="10"
            placeholder="Enter MRP"
            v-model="mrp"
          />
        </div>
        <div class="discount">
          <label>Discount</label>
          <input
            type="text"
            @keyup="onlyNumberAllowd('discount')"
            maxlength="2"
            placeholder="Discount rate"
            v-model="discount"
            @blur="calculateCurrentPrice"
          />
        </div>
        <div class="current-price">
          <label>Current Price</label>
          <input
            type="text"
            placeholder="Current price"
            disabled
            v-model="currentPrice"
          />
        </div>
      </div>

      <div class="book-image">
        <div class="image-area" v-if="imageUrl.length !== 0">
          <div v-for="(v, i) in imageUrl" :key="i" class="img">
            <img :src="v" alt="" />
          </div>
        </div>

        <div class="uploadBtns">
          <label> Select Book Image</label>
          <input
            type="file"
            id="file-uploader"
            accept="image/gif, image/jpeg, image/png"
            @click="getPhotoUrl"
          />
        </div>
      </div>
      <div class="description">
        <label> Book Description</label>
        <textarea
          placeholder="Enter Book Description"
          required
          v-model="bookDescription"
        ></textarea>
      </div>
      <div class="other-details">
        <div class="publication-year">
          <label>Publication Year</label>
          <input
            type="text"
            @keyup="onlyNumberAllowd('pubyear')"
            v-model="pubyear"
            maxlength="4"
            placeholder="Publication year"
          />
        </div>
        <div class="language">
          <label>Language</label>
          <div class="padding-wrap" v-if="languageList.length != 0">
            <div class="languages">
              <button
                v-for="(value, index) in languageList"
                :key="index"
                @click="removeLang(index)"
              >
                {{ value }}
                <span>
                  <i class="fa-solid fa-xmark icn"></i>
                </span>
              </button>
            </div>
          </div>
          <input
            type="text"
            placeholder="Language"
            v-model="languageVal"
            @blur="addLang(languageVal)"
          />
        </div>
        <div class="binding">
          <label>Binding</label>
          <input type="text" placeholder="Binding" v-model="bindingType" />
        </div>
        <div class="publisher">
          <label>Publisher</label>
          <input type="text" placeholder="Publisher" v-model="publisher" />
        </div>
        <div class="genre">
          <label>Genre</label>
          <input type="text" placeholder="Genre" v-model="genre" />
        </div>
        <div class="ISBN">
          <label>ISBN</label>
          <input type="text" placeholder="ISBN" v-model="isbn" maxlength="13" />
        </div>
        <div class="pages">
          <label>Pages</label>
          <input
            type="text"
            placeholder="Pages"
            @keyup="onlyNumberAllowd('noOfPages')"
            v-model="noOfPages"
          />
        </div>
        <div class="seller">
          <label>Seller</label>
          <input type="text" placeholder="Seller" v-model="sellerName" />
        </div>
      </div>

      <div class="submit">
        <!-- <button @click="togglePreview">Preview</button> -->
        <button @click="getTheBookObject">Preview</button>
        <!-- <button @click="resetStore">Preview</button> -->
      </div>
    </div>
    <!-- trying to pass props here,but unsuccessful -->
    <previewModal
      v-if="showPreview"
      :showPreview="togglePreview"
      :book="book"
      :createBookForUpload="createBookForUpload"
    ></previewModal>
    <!-- //need to use the suspense feature of vue  -->
    <div class="loading" v-if="isUploading">
      <p>Uploading....</p>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";

import previewModal from "@/components/preview-modal.vue";

import { uploadBooks } from "@/stores/upload-book";

import { uploadFiles } from "@/stores/file-upload";

import { unncessaryFileUrlCollection } from "@/stores/unnecessary-file-upload-tracker";

import { allUploadedFilesStorageRefCollection } from "@/stores/uploaded-files-storageRef-collection";

export default {
  name: "upload-book-for-sale",

  components: {
    previewModal,
  },

  setup() {
    //fiileStorageRefCollection instance
    const flstrgRefCollection = allUploadedFilesStorageRefCollection();

    //file uplaod instace
    const uploadFilesToFirebase = uploadFiles();

    //unnecessarily uploaded file tracker store
    const unnecessaryUpload = unncessaryFileUrlCollection();

    //upload book store instance
    const uplaodBook = uploadBooks();

    //reset the store
    function resetStore() {
      uplaodBook.$reset();
      console.log("reset done");
    }

    const bookName = ref("");
    const authorName = ref("");
    const authorDetails = ref("");
    const bookDescription = ref("");
    const bindingType = ref("");
    const publisher = ref("");
    const genre = ref("");
    const isbn = ref("");
    const sellerName = ref("");
    const noOfPages = ref("");
    const pubyear = ref("");
    const imageUrl = ref([]);
    const showPreview = ref(false);

    const languageVal = ref("");
    //content holders
    const languageList = ref([]);

    //remove language
    function removeLang(index) {
      languageList.value.splice(index, 1);
    }

    // add language
    function addLang(language) {
      if (language) {
        if (languageList.value.includes(language)) {
          return;
        }

        languageList.value.push(language.trim());
        languageVal.value = "";
      }
    }

    //adjust textfield height with content
    function adjustTextFieldHeight() {
      // document.querySelectorAll('textarea')
      const selectTextArea = document.querySelector("textarea");
      selectTextArea.addEventListener("keyup", (e) => {
        selectTextArea.style.height = "auto";
        const scrollHeight = e.target.scrollHeight;
        selectTextArea.style.height = `${scrollHeight}px`;
      });
    }

    //to check whether upload is going on
    var isUploading = ref(false);

    //get the selected image url for preview
    function getPhotoUrl() {
      const uploadBtn = document.getElementById("file-uploader");
      uploadBtn.addEventListener("change", function () {
        if (uploadBtn.value) {
          // imageUrl.value = url;
          const files = uploadBtn.files;
          isUploading.value = true;

          for (let index = 0; index < files.length; index++) {
            if (files[index].type.match("image")) {
              uploadFilesToFirebase.uploadSingleImageAndGetDownloadUrl(
                files[index]
              );
              imageUrl.value = uploadFilesToFirebase.uploadedSingleImage;
            }

            setTimeout(() => {
              isUploading.value = false;
              uploadFilesToFirebase.uploadedSingleImage;
              unnecessaryUpload.addNewFileUrl(
                uploadFilesToFirebase.uploadedSingleImage[0]
              );
            }, 9000);

            continue;
          }
        }
      });
    }

    onMounted(() => {
      adjustTextFieldHeight();
      // unnecessaryUpload.$reset();
      // console.log(unnecessaryUpload.fileUrlCollection);
      unnecessaryUpload.fileUrlCollection.forEach((v) => {
        if (v !== null) {
          var strf = flstrgRefCollection.findFileStorageRef(v);
          if (strf !== null) {
            flstrgRefCollection.deleteFileFromFirebase(strf).then(() => {
              unnecessaryUpload.deleteUrl(v);
            });
          }
        }
      });
    });

    //show or hide preview
    function togglePreview() {
      showPreview.value = !showPreview.value;
    }

    const mrp = ref("");
    const discount = ref("");
    const currentPrice = ref("");

    //calculating the current price automatically
    function calculateCurrentPrice() {
      if (!mrp.value || !discount.value) {
        return;
      }
      const currentrate = 100 - Number(discount.value);
      currentPrice.value = (Number(mrp.value) * currentrate) / 100;
    }

    //restricting certain input fields to accept only numbers
    function onlyNumberAllowd(input) {
      var restrictRegex = /[^0-9]/gi;
      this[input] = this[input].replace(restrictRegex, "");
    }

    //book object
    var book = ref();

    //getting the book object
    function getTheBookObject() {
      if (
        !bookName.value ||
        !authorName.value ||
        !bookDescription.value ||
        !bindingType.value ||
        !publisher.value ||
        !genre.value ||
        !isbn.value ||
        !sellerName.value ||
        !noOfPages.value ||
        !pubyear.value ||
        imageUrl.value.length === 0 ||
        !languageList.value ||
        !mrp.value ||
        !discount.value ||
        !currentPrice.value
      ) {
        alert("please Fillup all the details");
        return;
      }
      book.value = {
        bookName: bookName.value,
        authorName: authorName.value,
        authorDetails: authorDetails.value,
        bookDescription: bookDescription.value,
        bindingType: bindingType.value,
        publisher: publisher.value,
        genre: genre.value,
        isbn: isbn.value,
        sellerName: sellerName.value,
        noOfPages: noOfPages.value,
        pubYear: pubyear.value,
        imageUrl: imageUrl.value,
        languageList: languageList.value,
        mrp: mrp.value,
        discount: discount.value,
        currentPrice: currentPrice.value,
      };
      togglePreview();
    }

    function resetFields() {
      bookName.value = "";
      authorName.value = "";
      authorDetails.value = "";
      bookDescription.value = "";
      bindingType.value = "";
      publisher.value = "";
      genre.value = "";
      isbn.value = "";
      sellerName.value = "";
      noOfPages.value = "";
      pubyear.value = "";
      imageUrl.value = [];
      languageList.value = "";
      mrp.value = "";
      discount.value = "";
      currentPrice.value = "";
    }

    //create book for upload
    function createBookForUpload() {
      uplaodBook.newBook(book.value);
      togglePreview();
      resetFields();
      unnecessaryUpload.resetCollection();
      // alert("Book creation completed");
    }

    return {
      isUploading,
      book,
      currentPrice,
      onlyNumberAllowd,
      discount,
      mrp,
      adjustTextFieldHeight,
      calculateCurrentPrice,
      removeLang,
      addLang,
      languageVal,
      languageList,
      getTheBookObject,
      imageUrl,
      getPhotoUrl,
      showPreview,
      togglePreview,
      pubyear,
      noOfPages,
      bookName,
      authorName,
      authorDetails,
      bookDescription,
      bindingType,
      publisher,
      genre,
      isbn,
      sellerName,
      createBookForUpload,
      resetStore,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin makeFlex {
  display: flex;
}

@mixin labelColor {
  color: white;
}

@mixin noBorderOutline {
  border: none;
  outline: none;
}

@mixin otherDetalsCommonStyle {
  @include makeFlex();
  flex-direction: column;
  margin: 25px 18px;

  label {
    color: white;
  }

  input {
    @include noBorderOutline();
    width: 35%;
    min-width: 150px;
    border-radius: 15px;
    height: 30px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 5px;

    @media (max-width: 400px) {
      width: auto;
    }
  }
}

.wrapper {
  height: 100%;
  width: 100%;
  background-color: #1f272b;
  @include makeFlex();
  justify-content: center;
  align-items: center;

  .upload-area {
    height: 80%;
    width: 80%;
    background: linear-gradient(#4671ea, #ac34e7);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding-bottom: 45px;
    margin-top: 55px;

    @media (max-width: 400px) {
      width: 95%;
    }

    h6 {
      color: white;
      text-align: center;
      padding: 25px 0;
      text-decoration: underline;
      font-weight: 500;
      font-size: 17px;
    }

    .name-field {
      display: flex;
      flex-direction: column;
      padding: 15px 20px;

      @include labelColor();

      textarea {
        @include noBorderOutline();
        border-radius: 15px;
        min-height: auto;
        max-height: 200px;
        margin: 10px 0;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        padding-top: 5px;
        padding-bottom: 5px;
        resize: none;
        scrollbar-width: none;

        &::placeholder {
          display: grid;
          place-items: center;
        }
      }
    }

    .author-name {
      @include makeFlex();
      @include labelColor();
      flex-direction: column;
      padding: 15px 20px;

      input {
        margin: 15px 0px;
        border-radius: 15px;
        height: 40px;
        text-align: center;
        font-size: 12px;
        @include noBorderOutline();

        &::placeholder {
          font-weight: 600;
          font-size: 12px;
        }
      }
    }

    .author-description {
      padding: 25px 15px;
      display: flex;
      flex-direction: column;

      label {
        color: white;
      }

      textarea {
        margin: 25px 0px;
        min-height: 100px;
        resize: none;
        text-align: center;
        padding: 5%;
        @include noBorderOutline();
        font-size: 12px;
        font-weight: 600;
        border-radius: 15px;
      }
    }

    .prices {
      padding: 15px 20px;

      display: flex;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .current-price,
      .discount {
        @include labelColor();

        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          margin-top: 30px;
        }

        label {
          padding-right: 10px;
        }

        input {
          text-align: center;
          font-size: 12px;
          padding: 5px auto;
          height: 30px;
          @include noBorderOutline();
          border-radius: 15px;
          margin-right: 10px;
        }
      }

      .MRP {
        @include labelColor();

        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          padding-left: 0px;

          margin-top: 30px;
        }

        label {
          padding-right: 10px;
        }

        input {
          text-align: center;
          font-size: 12px;
          padding: 5px auto;
          height: 30px;
          @include noBorderOutline();
          border-radius: 15px;
          margin-right: 10px;
        }
      }
    }

    .book-image {
      display: flex;
      padding: 25px 20px;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }

      .image-area {
        height: 35%;
        width: 50%;
        object-fit: cover;

        .img {
          img {
            height: auto;
            max-height: 250px;
            width: 100%;
            margin: 9px;
            border-radius: 5px;
          }
        }
      }

      .uploadBtns {
        padding: 20px;

        input {
          height: 20px;
          // border-radius: 15px;
          font-size: 11px;
          color: black;
          @include noBorderOutline();
        }

        label {
          color: white;
          padding-right: 15px;
        }
      }
    }

    .description {
      padding: 25px 17px;

      display: flex;
      flex-direction: column;

      @include labelColor();

      textarea {
        margin: 15px 0;
        // min-height: 200px;
        resize: none;
        @include noBorderOutline();
        font-size: 12px;
        text-align: center;
        padding: 10%;
        border-radius: 15px;

        &::placeholder {
          color: black;
        }
      }
    }

    .other-details {
      // height: 300px;
      width: 100%;
      // background-color: yellow;

      .publication-year {
        @include otherDetalsCommonStyle();
      }

      .binding {
        @include otherDetalsCommonStyle();
      }

      .publisher {
        @include otherDetalsCommonStyle();
      }

      .genre {
        @include otherDetalsCommonStyle();
      }

      .ISBN {
        @include otherDetalsCommonStyle();
      }

      .pages {
        @include otherDetalsCommonStyle();
      }

      .seller {
        @include otherDetalsCommonStyle();
      }

      .language {
        @include otherDetalsCommonStyle();
        overflow: hidden;

        .padding-wrap {
          padding: 20px 0px;

          .languages {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: minmax(150px, 0.5fr);
            gap: 8px;
            overflow: auto;
            overscroll-behavior-inline: contain;
            // margin: 15px auto;
            overflow-x: contain;

            button {
              margin: 5px 10px;
              color: black;
              @include noBorderOutline();
              padding: 2px 15px;
              border-radius: 15px;
              font-size: 11px;
              text-overflow: ellipsis;
              white-space: nowrap;

              .icn {
                color: white;
                background-color: red;
                border-radius: 15px;
                padding: 1px;
              }
            }
          }
        }
      }
    }

    .submit {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        height: 27px;
        width: 120px;
        margin: 20px auto;
        font-size: 11.5px;
        color: white;
        background-color: black;
        @include noBorderOutline();
        border-radius: 15px;
      }
    }
  }
}
.loading {
  position: fixed;
  inset: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 6000;

  display: grid;
  place-content: center;

  p {
    color: white;
    font-size: 15px;
  }
}
</style>
